const LearnerReviewCard = ({ learner }) => {
  return (
    <div className="flex flex-col mx-2 md:mx-5">

        <div
        className="flex flex-col border-2 shadow-md rounded-lg w-[280px] md:w-[650px] lg:w-[745px] xl:w-[1100px] xl:h-[400px] lg:h-[450px] md:h-[485px] h-[600px] mx-3"
        >
        <div className="p-6 flex flex-col justify-end mt-2">
          <div className="flex flex-col md:flex-row justify-start items-center">
            <div className="h-32 w-32 rounded-[5%] border-2 shadow-md">
              <img src={learner.img} alt="xyz" className="w-full h-full object-cover rounded-[5%]" />
            </div>
            <div className="flex flex-col justify-evenly items-center px-0 md:px-10">
              <div className="font-inter text-base md:text-2xl font-bold text-black text-center">
              {learner.name}
            </div>
              <div className="font-inter text-xs md:text-base text-black text-center">
              {learner.batch}
              </div>
              <div className="flex flex-row justify-center py-2">
                <a href={learner.linkedin} rel="noreferrer" target="_blank" className="rounded-md text-sm p-2 px-2 text-white bg-primary font-bold text-center">
                  Connect
                </a>
              </div>
            </div>
            <div className="hidden md:block border-r-2 border-gray-200 h-full"></div>
            <div className="hidden md:block md:ml-5 md:mt-0 md:w-40 md:h-24">
              <img src={learner.company} alt="xyz" className="w-full h-full object-contain" />
            </div>
          </div>

          <div className="flex flex-col justify-center items-center py-4 h-full md:h-[80%]">
            <div className="font-inter text-xs md:text-lg text-[#7A7A7A] text-center pt-0 md:pt-2">
              <q>{learner.review}</q>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnerReviewCard;

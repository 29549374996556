import homepagedsaiselfpacedcov from "../../assets/main/homepagedsaiselfpacedcov.png";
import TextBolder from "../../components/ui/extras/TextBolder";
import DiscountCoverTagSvg from "../../assets/svgs/DiscountCoverTagSvg";
import Button from "../../components/ui/Button";
import { Link } from "react-router-dom";

const SelfPacedProgramSlide = () => {
    return(
        <div className="flex flex-row items-center justify-center">
        <div className="xl:flex xl:items-start xl:justify-start max-w-[1640px] w-full xl:flex-row-reverse hidden">
          <div className="pr-0 w-2/3">
            <img src={homepagedsaiselfpacedcov} alt="cover-vector" className="w-full h-full object-cover" />
          </div>
          <div className="w-full lg:w-2/3 p-5 lg:p-0 lg:ml-[-50px] pt-0 lg:pt-20">
            <div>
              <h1 className="font-inter py-5 lg:text-5xl md:text-3xl text-2xl font-bold text-primary text-center lg:text-left">
                Learn at your own pace with our <span className="text-secondary">Pre recorded lectures </span>
              </h1>
              <div className="py-7 pt-10 text-center lg:text-left hidden lg:block">
                <ul className="list-disc list-inside">
                  <li>Learn from <TextBolder>anywhere</TextBolder> and <TextBolder>anytime</TextBolder></li>
                  <li><TextBolder>Pre-recorded</TextBolder> lectures from industry experts</li>
                  <li><TextBolder>Unlimited</TextBolder> access to course files, pdf’s and code files</li>
                  <li>Upskill your knowledge with <TextBolder>industry level</TextBolder> projects</li>
                  <li>Achieve a <TextBolder>certificate</TextBolder> after course completion</li>
                  <li><TextBolder> 50% discount</TextBolder> using referral code (limited time offer)</li>
                </ul>
              </div>
            </div>
            <div className="flex flex-row py-5 items-center justify-center lg:justify-start">
              <Link to="/course/Data-science-and-AI-self-paced-program">
                <Button active>View Course</Button>
              </Link>
            </div>
          </div>
          <div className="lg:top-[-50px]">
          <DiscountCoverTagSvg />
          </div>
        </div>
      </div>
    )
}
export default SelfPacedProgramSlide;
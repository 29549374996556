import FeatureCardArrow1Svg from "../../../assets/svgs/FeatureCardArrow1Svg";
import FeatureCardArrow1SvgMobile from "../../../assets/svgs/FeatureCardArrow1SvgMobile";
import FeatureCardArrow2Svg from "../../../assets/svgs/FeatureCardArrow2Svg";
import FeatureCardArrow2SvgMobile from "../../../assets/svgs/FeatureCardArrow2SvgMobile";
import TextRed from "../extras/TextRed";
import FloatingDiv from "../extras/FloatingDiv";

const FeatureCard = ({ feature }) => {

    const splitTitleBydot = (title) => {
        return title.split(".");
    }

    return (
        <div className="w-full mb-20">
            {feature && feature.id % 2 === 0 ? (
                <div className="flex flex-row justify-between">
                    <div className="relative w-[300px] lg:w-auto">
                        <FloatingDiv position="left-top" >{feature.description_points[0]}</FloatingDiv>
                        <FloatingDiv position="right-center" >{feature.description_points[1]}</FloatingDiv>
                        <FloatingDiv position="center-bottom" >{feature.description_points[2]}</FloatingDiv>
                        <img src={feature.image} alt={feature.title} />
                    </div>
                    <div className="flex flex-col text-right max-w-sm mr-0 lg:mr-11 leading-0 lg:leading-8">
                        <div className="font-inter py-0 lg:py-5 text-sm lg:text-3xl md:text-3xl font-bold text-primary" >{splitTitleBydot(feature.title)[0]} <TextRed>{splitTitleBydot(feature.title)[1]}</TextRed> {splitTitleBydot(feature.title)[2]}</div>
                        <div className="mt-3 hidden lg:flex">
                            <FeatureCardArrow1Svg />
                        </div>
                        <div className="mt-0 flex flex-row justify-center lg:hidden">
                            <FeatureCardArrow1SvgMobile />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col text-left max-w-xs mr-0 lg:mr-11 leading-8">
                        <div className="font-inter py-0 lg:py-5 lg:text-3xl md:text-3xl text-sm font-bold text-primary">{splitTitleBydot(feature.title)[0]} <TextRed>{splitTitleBydot(feature.title)[1]}</TextRed> {splitTitleBydot(feature.title)[2]}</div>
                        <div className=" ml-52 right-0 hidden lg:flex">
                            <FeatureCardArrow2Svg />
                        </div>
                        <div className="ml-0 flex flex-row justify-center lg:hidden">
                            <FeatureCardArrow2SvgMobile />
                        </div>
                    </div>
                    <div className="relative w-[300px] lg:w-auto">
                        <FloatingDiv position="right-top" >{feature.description_points[0]}</FloatingDiv>
                        <FloatingDiv position="left-center" >{feature.description_points[1]}</FloatingDiv>
                        <FloatingDiv position="right-bottom" >{feature.description_points[2]}</FloatingDiv>
                    <img src={feature.image} alt={feature.title} />
                    </div>
                </div>
            )}
        </div>
    );
}
export default FeatureCard;
import HomePage from "./pages/HomePage";
import HireWithUs from "./pages/HireWithUs";
import CoursePage from "./pages/CoursePage";
import FreeCoursePage from "./pages/courses/FreeCoursePage";
import AboutUs from "./pages/company/AboutUs";
import ContactUs from "./pages/company/ContactUs";
import Pricing from "./pages/company/Pricing";
import TermsAndConditions from "./pages/company/TermsAndConditions";
import Testimonials from "./pages/company/Testimonials";
import HelpCenter from "./pages/support/HelpCenter";
import PrivacyPolicy from "./pages/support/PrivacyPolicy";
import Status from "./pages/support/Status";
import TermsOfService from "./pages/support/TermsOfService";
import Legal from "./pages/support/Legal";
import NotFound from "./pages/NotFound";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from 'react';
import HeadWrapper from "./components/HeadWrapper";
import ScrollToTop from "./components/ScrollToTop";
  
function App() {

  const [isLogin, setIsLogin] = useState(false);

   useEffect(()=>{
    const token = localStorage.getItem('token');
    if (token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
         
   }, []);

  const routes = [
    {
      path: '/',
      Component: () => <HomePage isLogin={isLogin} setIsLogin={setIsLogin} />,
      title: "Surge Classes - Learn Data Science, Machine Learning, and AI",
      description: "Surge Classes offers a variety of programs to help you learn data science, machine learning, AI, and more. Explore our pricing options and find the right fit for you.",
    },
    {
      path: '/hire',
      Component: () => <HireWithUs />,
      title: "Hire With Us | Surge Classes",
      description: "Hire our services for your data science and AI needs. Our expert team is ready to assist you.",
    },
    {
      path: '/about',
      Component: () => <AboutUs isLogin={isLogin} />,
      title: "About Us | Surge Classes",
      description: "Learn more about Surge Classes, our mission, and our commitment to education and technology.",
    },
    {
      path: '/course/:course_code',
      Component: () => <CoursePage isLogin={isLogin} setIsLogin={setIsLogin} />,
      title: "Course Page | Surge Classes",
      description: "Explore the details of our courses, including curriculum, instructors, and enrollment information.",
    },
    {
      path: '/free-course/:course_code',
      Component : () => <FreeCoursePage />,
      title: "Free Course | Surge Classes",
      description: "Explore the details of our courses, including curriculum, instructors, and enrollment information.",
    },
    {
      path: '/contact',
      Component: () => <ContactUs />,
      title: "Contact Us | Surge Classes",
      description: "Get in touch with our team. We're here to answer your questions and provide assistance.",
    },
    {
      path: '/pricing',
      Component: () => <Pricing />,
      title: "Pricing | Surge Classes",
      description: "Discover our pricing options and choose the right plan for your educational journey.",
    },
    {
      path: '/terms',
      Component: () => <TermsAndConditions />,
      title: "Terms and Conditions | Surge Classes",
      description: "Read our terms and conditions to understand the rules and policies governing our services.",
    },
    {
      path: '/testimonials',
      Component: () => <Testimonials />,
      title: "Testimonials | Surge Classes",
      description: "Read what our students and clients have to say about their experiences with Surge Classes.",
    },
    {
      path: '/help',
      Component: () => <HelpCenter />,
      title: "Help Center | Surge Classes",
      description: "Find answers to common questions and get support through our help center.",
    },
    {
      path: '/privacy',
      Component: () => <PrivacyPolicy />,
      title: "Privacy Policy | Surge Classes",
      description: "Review our privacy policy to understand how we collect and protect your personal information.",
    },
    {
      path: '/status',
      Component: () => <Status />,
      title: "Status | Surge Classes",
      description: "Check the status of our services and any updates or maintenance notifications.",
    },
    {
      path: '/terms-of-service',
      Component: () => <TermsOfService />,
      title: "Terms of Service | Surge Classes",
      description: "Read our terms of service to understand the legal agreements when using our platform.",
    },
    {
      path: '/legal',
      Component: () => <Legal />,
      title: "Legal | Surge Classes",
      description: "Explore our legal information and policies related to using Surge Classes.",
    },
    {
      path: '*',
      Component: () => <NotFound />,
      title: "404 - Not Found | Surge Classes",
      description: "Page not found. Sorry, the page you're looking for does not exist.",
    },
  ]

  return (
    <div className="flex flex-col min-h-screen bg-white max-w-full overflow-hidden">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar isLogin={isLogin} setIsLogin={setIsLogin}/>
        <main>
          <Routes>
            {routes.map(({ path, Component, title, description }) => (
              <Route key={path} path={path} element={<><HeadWrapper title={title} description={description}></HeadWrapper><Component /></>} />
            ))}
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

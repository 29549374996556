import { useState } from "react";
import backwardarrow from "../../../assets/main/backwardarrow.svg";
import forwardarrow from "../../../assets/main/forwardarrow.svg";
import ProjectCard from "../cards/ProjectCard";

const Project = ({ projectDetails }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative flex flex-row justify-center lg:justify-between my-3 px-2 lg:px-10 lg:max-w-[1420px] w-[100%]">
      <div className="hidden lg:flex lg:flex-col lg:justify-center lg:cursor-pointer">
        <img src={backwardarrow} alt="xyz" className="h-10 w-10" onClick={prevSlide} />
      </div>
      <div className="overflow-scroll no-scrollbar w-[90%]">
        <div
          className={`grid grid-rows-2 grid-flow-col gap-4 lg:gap-0 transition-transform duration-300`}
          style={{
            transform: `translateX(-${activeIndex * 100}%)`,
          }}
        >
          {projectDetails.map((project, i) => (
            <ProjectCard key={i} project={project} />
          ))}
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-col lg:justify-center lg:cursor-pointer">
        <img src={forwardarrow} alt="xyz" className="h-10 w-10" onClick={nextSlide} />
      </div>
    </div>


  );
};

export default Project;


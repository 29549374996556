import StarComponent from "./../rating/StarComponent";
import { Link } from "react-router-dom";

const ProgramCard = ({ programCardDetail, rating = false }) => {
  return (
    <div
      key={programCardDetail.id}
      className="flex flex-col border-2 shadow-md rounded-[5%] w-[250px] mx-3 relative" // Added 'relative' class
    >
      <div className="flex">
        <div className="bg-primary text-white w-fit px-4 z-1 absolute top-0 left-0 rounded-tl-lg"> {/* Added 'top-0 left-0' classes */}
          {programCardDetail.tags}
        </div>
        <div className="w-full mt-0">
          <img src={programCardDetail.img} alt="xyz" className="h-full w-full object-cover" />
        </div>
      </div>
      <div className="p-5 flex flex-col justify-end">
        <div className="font-inter text-xl font-bold text-primary">
          {programCardDetail.name}
        </div>
        <div className="font-inter text-base text-[#606060] py-2">
          Duration: {programCardDetail.duration}
        </div>
        {rating && programCardDetail.rating && (
          <div className="flex flex-row">
            <StarComponent rating={programCardDetail.rating.star} />
            <p className="p-2 text-[#868686] text-sm text-inter">
              ({programCardDetail.rating.people})
            </p>
          </div>
        )}
        <div className="flex flex-row justify-center">
          <Link to={"course/" + programCardDetail.code} className="flex flex-row justify-center rounded-md text-xs p-2 px-4 w-[50%] text-white bg-secondary font-bold hover:bg-[#AB1200]">
            View
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProgramCard;

import { useState } from "react";

const faqs = [
    {
        question: "How do I enroll in a course?",
        answer: "To enroll in a course, log in to your account, browse our course catalog, and click the 'Enroll' button on the course you're interested in."
    },
    {
        question: "How can I reset my password?",
        answer: "If you've forgotten your password, you can reset it by clicking on the 'Forgot Password' link on the login page. Follow the instructions to reset your password."
    },
    {
        question: "Do you offer refunds for courses?",
        answer: "We offer a refund policy for courses. If you're not satisfied within the first 30 days of enrollment, you can request a full refund."
    },
];

const HelpCenter = () => {
    const [expandedFaq, setExpandedFaq] = useState(null);
    const [expandedTopic, setExpandedTopic] = useState(null);
    const [expandedTechnical, setExpandedTechnical] = useState(null);

    return (
        <div className="bg-white py-12">
            <div className="container mx-auto px-4 max-w-[1040px]">
                <div className="text-center mb-8">
                    <h1 className="font-inter py-3 md:py-5 text-3xl md:text-4xl font-bold text-primary text-center">
                        Help Center</h1>
                    <p className="mt-2 text-gray-600 text-sm md:text-base">Welcome to our Help Center. Find answers to your questions here.</p>
                </div>

                <div className="flex flex-col justify-center bg-[#F9FAFB] p-3 md:p-6 md:mx-10 rounded-lg shadow-md mb-8">
                    <div className="cursor-pointer" onClick={() => setExpandedTopic(expandedTopic === 'all' ? null : 'all')}>
                        <div className="flex flex-row items-center justify-between text-base md:text-xl font-semibold text-primary " >
                            Frequently Asked Questions
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-5 w-5 ${expandedTopic === 'all' ? 'rotate-0' : '-rotate-180'} transition-transform duration-300`}
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    </div>
                    {expandedTopic && (
                        <div className="space-y-4 mt-4">
                            {faqs.map((faq, index) => (
                                <div key={index}>
                                    <button
                                        className="flex items-center justify-between w-full text-left font-semibold focus:outline-none"
                                        onClick={() => setExpandedFaq(index === expandedFaq ? null : index)}
                                    >
                                        <div className="text-sm md:text-base font-semibold">{faq.question}</div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={`h-5 w-5 transform ${index === expandedFaq ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                    {expandedFaq === index && (
                                        <p className="text-gray-600 text-sm md:text-base mt-2">{faq.answer}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>


                <div className="flex flex-col justify-center bg-[#F9FAFB] p-3 md:p-6 rounded-lg shadow-md mb-8 md:mx-10">
                    <div className="cursor-pointer" onClick={() => setExpandedTechnical(expandedTechnical === 'technical' ? null : 'technical')}
                    >
                        <div className="flex items-center justify-between text-base md:text-xl font-semibold text-primary " >
                            Technical Support
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-5 w-5 transform ${expandedTechnical === 'technical' ? 'rotate-0' : '-rotate-180'} transition-transform duration-300`}
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        {expandedTechnical === 'technical' && (
                            <div className="space-y-4 mt-4">

                                <p className="text-gray-600 text-sm md:text-base">
                                    If you encounter technical issues, please reach out to our technical support team via email at <span className="cursor-pointer text-primary font-semibold">connect@surgeclasses.com</span> or through contact us page info. Our team will be happy to assist you in resolving any technical challenges you may be facing.
                                </p>
                            </div>
                        )}

                    </div>

                </div>


            </div>
        </div >
    );
};

export default HelpCenter;

import { useNavigate } from 'react-router-dom';
import * as data from "../../data/SurgeCourses";

const Pricing = () => {
    const navigate = useNavigate();

    const gotoCoursesHandler = (data) => {
        const coursePath = data.code;
        navigate(`/course/${coursePath}`);
    }

    return (
        <div className="bg-white py-12">
            <div className="flex flex-col justify-center py-0 md:py-5 px-3 md:px-10">
                <h1 className="font-inter py-5 text-4xl font-bold text-primary text-center">Pricing</h1>
                <div className="container mx-auto px-4 py-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {data.SurgePrograms.filter(program => program.type !== "Campus Program").map((program) => (
                            <div className="bg-[#F9FAFB] p-6 rounded-lg shadow-md" key={program.id}>
                                <h2 className="text-2xl lg:h-14 font-semibold mb-4 text-primary">{program.title}</h2>
                                <span className={`${program.type === "Pro Program" ? "bg-orange-500" :
                                        program.type === "Live Batches" ? "bg-red-500" :
                                            program.type === "Self Paced Program" ? "bg-yellow-500" :
                                                ""
                                    } text-white text-xs px-2 py-1 rounded-full mr-2`}>
                                    {program.type}
                                </span>
                                <p className="text-lg font-semibold mb-2 pt-2">{program.fees}</p>
                                <button className="bg-primary text-white mt-4 py-2 px-4 rounded-md" onClick={() => gotoCoursesHandler(program)}>Get Started</button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricing;

const TermsAndConditions = () => {
    return (
        <div className="bg-white py-5">
            <div className=" flex flex-col justify-center py-5 px-5 md:px-10">
                <h1 className="font-inter py-5 xl:text-5xl md:text-3xl text-2xl font-bold text-primary text-center">
                    Terms and Conditions</h1>
                <div className="container mx-auto px-0 md:px-4 py:2 md:py-10">
                    <div className="bg-[#F9FAFB] p-6 rounded-lg shadow-md">

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">Terms and Conditions</h2>
                            <p className="mb-4 text-xs md:text-base">
                                Please read these Terms and Conditions ("Terms") carefully before using the website <span className="cursor-pointer text-primary font-semibold"> https://www.surgeclasses.com</span> (hereinafter referred to as the "Website") operated by Surge Classes, a division of Jalla Labs Pvt. Ltd. (hereinafter referred to as the "Company"). By accessing or using the services provided on the Website, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using the Website.
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">1. Formation of Contract</h2>
                            <p className="text-xs md:text-base">A contract between Surge Classes ("Us" or "We") and the user ("You" or "User") will be established when You make the initial payment for booking Our services.</p>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">2. Scope of Services</h2>
                            <p className="text-xs md:text-base">Surge Classes provides a learning environment optimized for developers, offering industry-relevant Micro-Experiences. Our services include Learning Content, Work-like infrastructure, Git account and Code stubs, Provisioned Virtual machines, Test suite and assessment service, Slack workspace, Mentoring, and career services.</p>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">3. Registration</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">Users must register on the Website to access our services.</li>
                                <li className="text-xs md:text-base">Registration can be facilitated through linking Facebook or Google Plus Accounts.</li>
                                <li className="text-xs md:text-base">During a trial period of 7 days, users can access limited content and functions using an Access Code provided by Us.</li>
                                <li className="text-xs md:text-base">Individuals under 18 years of age must use the Website under the supervision of a parent or legal guardian.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">4. Payment</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">Registration is free of cost.</li>
                                <li className="text-xs md:text-base">Payment is required to access our full services, and access will be granted upon receiving full payment.</li>
                                <li className="text-xs md:text-base">Payment methods include credit/debit card, digital wallets, internet banking, or NBFC loans.</li>
                                <li className="text-xs md:text-base">Third-party payment gateways are used, governed by their respective Terms of Use and Privacy Policy.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">5. Cancellation/Refund Policy</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">Subscription plans cannot be changed or canceled after payment, and no refunds will be processed.</li>
                                <li className="text-xs md:text-base">No refunds for interruptions due to insufficient system requirements or technical issues.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">6. Warranties and Liabilities</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">We do not guarantee the accuracy or quality of content on the Website.</li>
                                <li className="text-xs md:text-base">We are not liable for third-party payment gateway services, events beyond our control, interruptions of service, or loss of data during transmission.</li>
                                <li className="text-xs md:text-base">We are not responsible for third-party website content linked through ads or links on the Website.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">7. Obligations and Conduct</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">Users agree not to provide false information or engage in harmful activities on the Website.</li>
                                <li className="text-xs md:text-base">Unauthorized use, interference with security features, and improper conduct are prohibited.</li>
                                <li className="text-xs md:text-base">Users must follow guidelines and policies provided by the Company.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">8. Termination of Contract</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">Users can discontinue services by opting out or notifying Us via email.</li>
                                <li className="text-xs md:text-base">We reserve the right to terminate access without notice for breach of terms.</li>
                                <li className="text-xs md:text-base">Users remain bound by these Terms until expiration.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">9. Indemnity</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">Users agree to indemnify and hold Us harmless from losses arising from breach of these Terms or improper conduct.</li>
                                <li className="text-xs md:text-base">We are not liable for damages, including loss of use, data, or profits.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">10. Intellectual Property Rights</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">Users are prohibited from using the Company's intellectual property without permission.</li>
                                <li className="text-xs md:text-base">Copyrighted material
                                    on the Website is protected by law.</li>
                                <li className="text-xs md:text-base">Unauthorized copying or reverse engineering of the Website's software is prohibited.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">11. Dispute Resolution</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">Disputes shall be resolved through Alternate Dispute Resolution and governed by Indian laws.</li>
                                <li className="text-xs md:text-base">The exclusive jurisdiction of the courts in Bangalore, India, is recognized.</li>
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">12. Notices</h2>
                            <p className="text-xs md:text-base">Communication regarding disputes or grievances can be sent to  <a href="mailto:connect@surgeclasses.com" className="cursor-pointer text-primary">connect@surgeclasses.com</a>.</p>
                        </div>

                        <div className="mb-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">13. Miscellaneous Provisions</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-xs md:text-base">These Terms constitute the complete contract between the User and the Company.</li>
                                <li className="text-xs md:text-base">Failure to enforce provisions does not waive rights.</li>
                                <li className="text-xs md:text-base">If any provision is invalid, the remaining provisions remain in effect.</li>
                            </ul>
                        </div>



                        <p className="mt-6 text-xs md:text-base">
                            By using Surge Classes' services, you agree to abide by these Terms and Conditions. If you have any questions, please contact us at <a href="mailto:connect@surgeclasses.com" className="cursor-pointer text-primary">connect@surgeclasses.com</a>.
                        </p>

                        <div className="mb-6 mt-6">
                            <h2 className="md:text-xl text-lg font-semibold mb-2">Company Details</h2>
                            <ul className="list-disc list-inside">
                                <li className="text-sm md:text-lg"><span className="font-bold">Website:</span> <a href="https://www.surgeclasses.com">https://www.surgeclasses.com</a></li>
                                <li className="text-sm md:text-lg"><span className="font-bold">Address:</span> No. 15, 25th A Main, Mariswamy Layout, Agara HSR Layout Sector 1, Bengaluru, Bangalore, KA 560102</li>
                                <li className="text-sm md:text-lg"><span className="font-bold">Phone:</span> +919390249772</li>
                                <li className="text-sm md:text-lg"><span className="font-bold">Email:</span> <a href="mailto:connect@surgeclasses.com" className="cursor-pointer text-primary">connect@surgeclasses.com</a></li>
                                <li className="text-sm md:text-lg"><span className="font-bold">Operating Hours:</span> Monday - Saturday, 9 AM to 6 PM</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;

const Popup = ({ close, children }) => {
  return (
    <div
      onClick={close}
      className="fixed z-10 top-0 left-0 w-full h-full flex items-center bg-[#7c7c7c7c] justify-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white py-6 px-4 rounded-md max-w-[90%] md:max-w-[70%] lg:max-w-[50%] xl:max-w-[40%] 2xl:max-w-[30%]"
      >
        <div className="flex flex-col px-4 md:px-10">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;

import covervector from "../../assets/main/maincover.svg";
import { surgeClassesFeatures } from "../Main";
import SignUp from "../../components/auth/SignUp";
import Button from "../../components/ui/Button";
const HomepageMainSlide = ({
    isLogin,
    setIsLogin,
    showPopup,
    setShowPopup,
    togglePopup,
    scrollToSection
}) => {
    return (
        <div className="flex flex-row items-center justify-center">
        <div className="flex flex-col items-center justify-center max-w-[1640px] p-5 w-full lg:flex-row-reverse lg:p-10">
          <div className="pr-0 lg:pt-4 lg:pr-20">
            <img src={covervector} alt="cover-vector" className="w-full h-full object-cover" />
          </div>
          <div className="w-full lg:w-2/3 p-5 lg:p-0 lg:pl-10">
          <div>
              <h1 className="font-inter py-5 lg:text-5xl md:text-3xl text-2xl font-bold text-primary text-center lg:text-left">
                Championing <span className="text-secondary">High Growth Career</span> with our proven programs
              </h1>
              <div className="py-10 text-center lg:text-left hidden lg:block">
              {surgeClassesFeatures.map((feature, i) => (
                <div key={i} className="font-inter text-xl py-1">
                  <span className="text-primary font-semibold">
                    {feature.higlights}
                  </span>{" "}
                  {feature.description}
                </div>
              ))}
              </div>
          </div>
            <div className="flex flex-row py-5 items-center justify-center lg:justify-start">
            {isLogin? ""
            :
            (<>
              <Button active onClick={togglePopup}>Register</Button>
                  {showPopup && (
                    <SignUp showSignupPopup={showPopup} setShowSignupPopup={setShowPopup} setIsLogin={setIsLogin} />
              )}
            </>)
            }
            {isLogin? (<Button active onClick={()=>scrollToSection()}>View Courses</Button>):
            (<Button outline onClick={()=>scrollToSection()}>View Courses</Button>)
            }
          </div>
          </div>
        </div>
      </div>
    );
}
export default HomepageMainSlide;
import linkedin from "../../../assets/companylogos/linkedin.png";
const AlumniCard = ({ alumniDetail }) => {
  return (
    <div className="flex flex-col h-full">
      <div
        key={alumniDetail.id}
        className="flex flex-col py-3 w-[250px] mx-5 cursor-pointer"
      >
        <div className="flex flex-col justify-start items-center border-2 shadow-md rounded-[5%] hover:scale-105 transform transition-transform duration-300">
          <div className="w-40 h-40 overflow-hidden rounded-3xl mt-2">
          <img
            src={alumniDetail.img}
            alt="xyz"
              className="w-full h-full object-cover"
          />
          </div>



          <div className="p-5 flex flex-col justify-start mt-1">
            <div className="font-inter text-base font-bold text-black text-center truncate">
              {alumniDetail.name}
            </div>
            <div className="font-inter text-xs text-black text-center">
              {alumniDetail.batch}
            </div>
            <div className="flex flex-row justify-evenly my-2">
              <div className="h-8 w-20">
                <img
                  srcSet={`${alumniDetail.company} 480w, ${alumniDetail.company} 800w, ${alumniDetail.company} 1200w`}
                  sizes="(max-width: 600px) 480px, (max-width: 1024px) 800px, 1200px"
                  src={alumniDetail.companyMedium}
                  alt="xyz"
                  className="w-full h-full object-contain"
                />
            </div>

            </div>
            <div className="flex flex-row justify-center">
              <button className="rounded-md bg-primary text-white p-1 w-[90%] border-2">
                {alumniDetail.package}
              </button>
            </div>
            <div className="flex flex-row justify-center py-4">
              <button className="rounded-[10px] px-2 p-1  border-2 w-[90%] flex flex-row justify-center hover:bg-blue-100">
                <a
                  href={alumniDetail.linkedin}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="h-6">
                    <img src={linkedin} alt="linkedin" className="h-full w-full object-contain" />
                  </div>
                </a>
              </button>
            </div>
          </div>
        </div>
        {/* <div className="font-inter text-xs text-[#7A7A7A] p-3">
          {alumniDetail.description}
        </div> */}
      </div>
    </div>
  );
};

export default AlumniCard;

import { useState } from "react";
const ProjectCard = ({ project }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div key={project.id} className="w-full lg:w-auto bg-[#C4EAFC66] rounded-[5%] mx-0 mb-0 lg:mx-5 lg:mb-10 border-2 shadow-md">
      <div className="flex flex-col items-center w-[170px] h-[190px] md:w-[360px] md:h-[330px] cursor-pointer" onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
        {isActive ? (
          <div className="flex flex-col justify-center items-center h-full px-1 lg:px-5">
            <div className="font-inter text-xs md:text-lg font-bold text-[#303030] text-center">
              {project.description}
            </div>
          </div>
        ) : (
          <>
            <div className="p-2">
              <img src={project.img} alt="xyz" className="w-full h-full object-cover rounded-sm" />
            </div>
            <div className="font-inter text-sm lg:text-2xl font-bold text-primary text-center my-1 md:my-3">
              {project.name}
            </div>
            <div className="mt-2 mb-4 lg:mt-4 lg:mb-8 bg-[#A6E1FFE5] p-2 w-[80%] rounded-md">
              <div className="font-inter text-xs lg:text-lg font-bold text-primary text-center">
                {project.category}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;

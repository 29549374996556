const LinkedinSvg = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17" cy="17" r="17" fill="#004C73" />
            <path d="M12.073 10.3748C12.0727 11.0873 11.6404 11.7285 10.98 11.996C10.3196 12.2635 9.56301 12.1039 9.06691 11.5925C8.57081 11.081 8.43433 10.3199 8.72181 9.66794C9.0093 9.01599 9.66334 8.60344 10.3755 8.62482C11.3215 8.65321 12.0735 9.42845 12.073 10.3748ZM12.1255 13.4198H8.62554V24.3748H12.1255V13.4198ZM17.6556 13.4198H14.173V24.3748H17.6206V18.626C17.6206 15.4235 21.7943 15.126 21.7943 18.626V24.3748H25.2506V17.436C25.2506 12.0373 19.0731 12.2386 17.6206 14.8898L17.6556 13.4198Z" fill="white" />
        </svg>

    );
}

export default LinkedinSvg;
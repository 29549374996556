import forward from "../../../assets/programsicon/forward.svg"
import { useState } from "react";

const Collapsible = ({ faq }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(!visible);
  };

  return (
    <div className="flex flex-col">
      <div onClick={toggleVisible} className="flex flex-row justify-start border-[#8B8B8B] border py-3 md:py-6 mt-5 w-full cursor-pointer">
        <div className="flex flex-col justify-center px-5 md:px-10">
          <div className="md:w-4 md:h-4 w-2 h-2">
            <img
              className={`w-full h-full hover:rotate-90 ${visible ? "rotate-90" : ""}`}
            src={forward}
            alt="forward"
            />
          </div>
        </div>
        <div className="text-xs text-left md:text-lg px-0 md:px-4">{faq.title}</div>
      </div>
      {visible && (
        <div className="flex flex-row justify-start text-xs md:text-base border-[#8B8B8B] border-x border-b bg-[#ebf4f7] py-4 w-full px-2 md:px-5 md:pr-40">
          {faq.description}
        </div>
      )}
    </div>
  );
};

export default Collapsible;


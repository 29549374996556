import { useState } from "react";
import backwardarrow from "../../../assets/main/backwardarrow.svg";
import forwardarrow from "../../../assets/main/forwardarrow.svg";
import ReviewCard from "../cards/ReviewCard";

const ReviewSlider = ({ reviewDetails }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dataLen = reviewDetails.length;

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % dataLen);
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + dataLen) % dataLen);
  };

  const duplicatedReviewDetails = [...reviewDetails, ...reviewDetails, ...reviewDetails];

  return (
    <div className="relative flex flex-row xl:justify-between xl:my-3 xl:px-6 xl:max-w-[1223px] w-[100%]">
      <div className="hidden xl:flex xl:items-center xl:cursor-pointer">
        <img
          src={backwardarrow}
          alt="xyz"
          className="h-10 w-10"
          onClick={prevSlide}
        />
      </div>
      <div className="xl:overflow-hidden overflow-x-scroll no-scrollbar px-3 xl:w-[90%] w-full">
        <div
          className="flex flex-row justify-start transition-transform duration-300"
          style={{
            transform: `translateX(-${activeIndex * 33.3}%)`,
          }}
        >
          {duplicatedReviewDetails.map((reviewDetail, i) => (
            <ReviewCard
              key={i}
              reviewDetail={reviewDetail}
              activeIndex={activeIndex}
              dataLen={dataLen}
              cardIndex={i % dataLen}
            />
          ))}
        </div>
      </div>
      <div className="hidden xl:flex xl:items-center xl:cursor-pointer">
        <img
          src={forwardarrow}
          alt="xyz"
          className="h-10 w-10"
          onClick={nextSlide}
        />
      </div>
    </div>
  );
};

export default ReviewSlider;

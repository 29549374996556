import downarrow from "../../../assets/main/downarrow.svg";
import { useState, useEffect, useRef } from 'react';

const SyllabusDropdown = ({
    syllabus,
    setSelectedTopic,
    setSelectedSubTopic,
    selectedTopic
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectedTopic = (topic) => {
    setSelectedTopic(topic);
    setSelectedSubTopic(topic.topics[0]);
    setIsOpen(false);
  }

  return (
    <div ref={dropdownRef} className="relative">
      <div className="bg-primary rounded-[10px] w-[250px] cursor-pointer" onClick={toggleDropdown}>
        <div className="flex flex-row justify-center px-3">
          <div className="text-base py-2.5 pr-2 text-white">{selectedTopic.title}</div>
          <div className="w-3 pt-5">
            <img src={downarrow} alt="down-arrow" />
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="absolute flex flex-row">
          <div><ul className="shadow-md mt-2 w-[250px] bg-white rounded">
            {syllabus.map((topic,i) => (
              <li
                key={i} 
                className={`py-2 px-4 cursor-pointer text-sm hover:bg-[#DAF2FF] hover:rounded-l-md hover:font-bold shadow-sm ${selectedTopic.id === topic.id ? " bg-[#DAF2FF] font-bold" : ""}`}
                onClick={() => handleSelectedTopic(topic)}
              >
                <div className="flex flex-row justify-between">
                {topic.title}
                </div>
              </li>
            ))}
          </ul></div>
        </div>
      )}
    </div>
  );
};

export default SyllabusDropdown;

import logo from "../../assets/main/logo.svg";
import { useState, useEffect } from "react";
import Popup from "../ui/popup/Popup";
import { useHttp } from "../../hooks/useHttp";
import Loader from "../ui/Loader";

const SignUp = ({ setIsLogin, showSignupPopup, setShowSignupPopup }) => {
 	const [name, setName] = useState('');
 	const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
	const [nameError, setNameError] = useState("");
	const [mobileError, setMobileError] = useState("");
    const [emailError, setEmailError] = useState("");
  	const [passwordError, setPasswordError] = useState("");
	const { isLoading, error, response, apiCall } = useHttp();

  	const validateEmail = (email) => {
	    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	    return emailRegex.test(email);
	};

	const togglePopup = () => {
		setShowSignupPopup(!showSignupPopup);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const fields = [
			{ value: email, setter: setEmailError, message: "This is a required field" },
			{ value: password, setter: setPasswordError, message: "This is a required field" },
			{ value: name, setter: setNameError, message: "This is a required field" },
			{ value: mobile, setter: setMobileError, message: "This is a required field" },
		];

		for (const field of fields) {
			if (!field.value.trim()) {
				field.setter(field.message);
			}
		}

		if (!validateEmail(email)) {
			setEmailError("Invalid email format");
			return;
		}

        const signupData = {
        	name: name,
        	mobile: mobile,
            email: email,
            password: password,
        };

		apiCall({
			path: "/firebase/signup",
			method: "POST",
			data: signupData
		});
    };

	useEffect(() => {
		if (response && response.user_info) {
			const userDataString = JSON.stringify(response.user_info);
			localStorage.setItem("token", response.user_info.token);
			localStorage.setItem("user", userDataString);
			setIsLogin(true);
			setShowSignupPopup(false);
		}
	}, [response]);

	useEffect(() => {
		const clearErrors = () => {
			if (email) setEmailError("");
			if (password) setPasswordError("");
			if (mobile) setMobileError("");
			if (name) setNameError("");
		};

		clearErrors();
	}, [email, password, mobile, name]);



	return(
		<>
			{showSignupPopup && (
				<Popup close={togglePopup}>
			<div className="flex flex-row justify-center pb-2">
				<img src={logo} alt="Surge Classes" className="w-[70%]" />
			</div>
			<div className="font-inter text-2xl text-[#101828] font-semibold text-center py-2">
				Sign Up
			</div>
			<div className="font-inter text-sm  text-center text-[#667085]">
				Please enter your details.
			</div>
			<form className="flex flex-col py-4">
				<label htmlFor="name" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Name
				</label>
						<input className={`hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4" ${nameError ? "border-red-500" : ""
							}`}
			    	placeholder="Enter your full name" 
			    	type="text" 
			    	required 
			    	name="name"
			    	onChange={(e) => setName(e.target.value)}  
						/>				
			    <label htmlFor="mobile" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Mobile
				</label>
						<input className={`hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4"  ${mobileError ? "border-red-500" : ""
							}`}
			    	placeholder="Enter your mobile no." 
			    	type="tel" 
			    	required 
			    	name="mobile"
			    	onChange={(e) => setMobile(e.target.value)}  
			    />				
				<label htmlFor="email" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Email
				</label>
						<input className={`hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4"  ${emailError ? "border-red-500" : ""
							}`}
			    	type="email"
			    	name="email"
                    label="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}  
                    required                                    
			    	placeholder="Enter your email"			    
			    />
						{email && emailError === "Invalid email format" ? (
							<p className="text-red-500 text-sm">
								{emailError}
							</p>
						) : null}
						<label htmlFor="password" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Password
				</label>
						<input className={`hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4"  ${passwordError ? "border-red-500" : ""
							}`}
			    	id="password"
                    name="password"
                    type="password" 
                    value={password}
                    autoComplete="current-password"                                   
                    required                                                                                
			    	onChange={(e) => setPassword(e.target.value)}  
                    placeholder="Enter your password"
						/>                                          			
			</form>
					{nameError || mobileError || emailError || passwordError ? (
						<p className="text-red-500 text-sm">
							**Please fill all the fields correctly**
						</p>
					) : null}
					{isLoading ? <div className="py-2"><Loader /></div> :

			<div className="flex flex-col w-full pb-4">
            <button className="my-2 bg-primary text-white rounded-md py-2 font-bold cursor-pointer"
            	type="submit" 
                onClick={onSubmit}
            > Sign up</button>
            </div>
					}
				</Popup>
			)}
		</>
	);
}

export default SignUp;

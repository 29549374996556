const Status = () => {
    return (
        <div className="bg-white py-3 md:py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="font-inter py-5 md:py-5 text-3xl md:text-4xl font-bold text-primary text-center">
                    Support Status</h1>
                <div className="bg-gray-100 rounded-lg shadow-md p-6">
                    <h2 className="text-base md:text-xl font-semibold mb-4 ">Current Status: Operational</h2>
                    <div className="flex items-center mb-2">
                        <div className="w-4 h-4 bg-green-500 rounded-full mr-2"></div>
                        <p className="text-green-600 text-sm md:text-base">All Systems Operational</p>
                    </div>
                    <div className="flex items-center mb-2">
                        <div className="w-4 h-4 bg-green-500 rounded-full mr-2"></div>
                        <p className="text-green-600 text-sm md:text-base">Customer Support Available</p>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 bg-green-500 rounded-full mr-2"></div>
                        <p className="text-green-600 text-sm md:text-base">Email Support Active</p>
                    </div>
                    <p className="mt-4 text-gray-700 text-sm md:text-base">
                        We are here to help you! If you experience any issues, please don't hesitate to contact our support team.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Status;

const Button = ({
  active = false,
  children,
  outline = false,
  width = "w-btn",
  onClick,
  disabled = false,
}) => {
  let className =
    width +
    " rounded-md text-base p-2 px-4 border-2 border-secondary mr-5 whitespace-nowrap text-xs ";

  if (active) {
    className +=
      "font-semibold bg-secondary text-white hover:bg-white hover:text-[#262626] hover:font-normal";
  } else {
    className += "hover:bg-secondary hover:text-white ";
  }
  if (outline) {
    className += "hover:bg-secondary hover:text-white text-[#262626] ";
  }
  if (!active && !outline) {
    className += "hover:bg-secondary hover:text-white ";
  }

  if (disabled) {
    className += "opacity-50 cursor-not-allowed"
  }

  return (
    <div onClick={onClick}>
      <button disabled={disabled} className={className}>{children}</button>
    </div>
  );
};

export default Button;

const ProgramDescriptionCard = ({feature}) => {
  return (
    <div className="flex flex-col">
      <div className="relative py-2">
        <div className="flex flex-row justify-center z-1 mb-[-40px]">
          <div className= "flex flex-row justify-center rounded-[50%] bg-primary p-5 w-[100px] h-[100px]">
          	<img src={feature.icon} alt="xyz" className="w-fit" />
          </div>
        </div>
        <div
          className="flex flex-col border-2 shadow-md rounded-[5%] w-[250px] mx-5"
        >
          <div className="p-5 flex flex-col justify-end mt-10">
            <div className="font-inter text-xl font-bold text-primary text-center">
              {feature.title}            
            </div>
            <div className="font-inter text-base text-black text-center px-0.5">
              {feature.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramDescriptionCard;

import logo from "../../assets/main/logo.svg";
import { useState, useEffect } from "react";
import { useHttp } from "../../hooks/useHttp";
import Loader from "../ui/Loader";

const CourseEnroll = ({close,course_id}) => { 
 	const [name, setName] = useState('');
 	const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const { isLoading, error, response, apiCall } = useHttp();

    const onSubmit = (e) => {
        e.preventDefault();
        
        const studentData = {
        	name: name,
        	mobile: mobile,
            email: email,
            course_id: course_id,
        };

        apiCall({
		  path: "/enroll/course",
		  method: "POST",
		  data: studentData
		});
    };

    useEffect(()=>{
    	if(response){
    		console.log(response);
	        close();
    	}
    },[response])


	return(
		<>
			<div className="flex flex-row justify-center pb-2">
				<img src={logo} alt="Surge Classes" className="w-[70%]" />
			</div>
			<div className="font-inter text-2xl text-[#101828] font-semibold text-center py-2">
				Enroll
			</div>
			<div className="font-inter text-sm  text-center text-[#667085]">
				Please enter your details.
			</div>
			<form className="flex flex-col py-4">
				<label htmlFor="name" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Name
				</label>
			    <input className="hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4" 
			    	placeholder="Enter your full name" 
			    	type="text" 
			    	required 
			    	name="name"
			    	onChange={(e) => setName(e.target.value)}  
			    />				
			    <label htmlFor="mobile" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Mobile
				</label>
			    <input className="hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4" 
			    	placeholder="Enter your mobile no." 
			    	type="tel" 
			    	required 
			    	name="mobile"
			    	onChange={(e) => setMobile(e.target.value)}  
			    />				
				<label htmlFor="email" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Email
				</label>
			    <input className="hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4" 
			    	type="email"
                    label="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}  
                    required                                    
			    	placeholder="Enter your email"			    
			    />
			</form>
			<div className="flex flex-col w-full pb-4">
				{isLoading ? <Loader /> : (
            <button className="my-2 bg-primary text-white rounded-md py-2 font-bold cursor-pointer"
            	type="submit" 
                onClick={onSubmit}
					> Enroll</button>)}
            </div>
		</>
	);
}

export default CourseEnroll;

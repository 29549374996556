const FeatureCardArrow2SvgMobile = () => {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1390_2864)">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.30871 13.5148C2.14078 13.8315 1.95875 14.5103 2.00426 18.2206C2.03459 20.9053 2.21659 22.5342 2.51995 22.7303C3.248 23.1828 4.53734 22.4286 4.47666 21.2824C4.40083 20.0154 4.24912 17.2855 4.21878 16.2599C4.08227 13.5299 4.0216 13.3187 3.30871 13.5148ZM2.91432 26.0032C2.04976 26.8629 2.58062 32.6094 3.5817 33.0619C4.87097 33.6501 5.46255 32.4134 5.05302 30.0454C4.79516 28.5824 4.68902 27.4964 4.62834 26.6367C4.56767 25.777 3.52104 25.3999 2.91432 26.0032ZM4.34009 37.0588C3.49069 37.6471 4.03673 40.8144 5.62937 44.4192C7.00964 47.5413 8.92082 46.6062 7.76806 43.3634C7.20684 41.7948 6.38781 39.2911 5.97827 37.813C5.59908 36.5611 5.26534 36.4103 4.34009 37.0588ZM39.6814 38.8386C39.2111 39.0196 39.2415 39.4871 39.8027 41.7345C40.0606 42.745 40.2122 43.6198 40.1364 43.6651C39.9999 43.7857 35.7074 45.2337 34.8276 45.475C34.0389 45.6711 34.2209 47.843 35.3737 47.662C36.2837 47.5262 39.0291 46.6213 39.9999 46.1235C40.8948 45.6711 40.7734 45.5806 41.2285 47.1341C41.7745 49.0043 41.8807 49.2306 42.3054 49.3965C43.3823 49.8037 44.6868 49.3362 45.4907 48.2653C47.2501 45.9425 49.0855 44.3287 52.7106 41.9305C54.7583 40.5731 54.4094 40.3469 50.2686 40.3469C46.6434 40.3469 44.3076 40.0452 42.1386 39.276C40.3791 38.6425 40.2122 38.6124 39.6814 38.8386ZM29.7767 48.024C29.5644 48.0843 28.5026 48.3859 27.4257 48.6876C20.7973 50.5126 21.6619 52.1114 24.7561 51.493C29.2155 50.6031 30.9901 49.7886 30.9901 48.6122C30.9901 47.9938 30.5503 47.7826 29.7767 48.024ZM9.54273 49.4266C8.45064 50.9651 11.9696 52.8957 16.3076 53.1219C20.5698 53.3481 20.9338 50.9952 17.5817 50.8595C15.17 50.7539 13.7139 50.4674 11.7724 49.6831C10.0129 48.9892 9.86126 48.9742 9.54273 49.4266Z" fill="#A6E1FF" />
            </g>
            <defs>
                <clipPath id="clip0_1390_2864">
                    <rect width="53.0877" height="52.7894" fill="white" transform="translate(0.859375 0.263184)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default FeatureCardArrow2SvgMobile;

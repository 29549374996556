import logo from "../../assets/main/logo.svg";
import { useState, useEffect } from "react";
import { useHttp } from "../../hooks/useHttp";
import Loader from "../ui/Loader";
import Popup from "../ui/popup/Popup";

const ForgotPassword = ({ active, setShowForgotPasswordPopup }) => {
 
    const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState("");
    const { isLoading, error, response, apiCall } = useHttp();

    const validateEmail = (email) => {
	    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	    return emailRegex.test(email);
	};

	const toggleForgotPasswordPopup = () => {
		setShowForgotPasswordPopup(!active);
	};

    const handleForgotPassword = () => {

		if (!email.trim()) {
			setEmailError("This is a required field");
			return;
		}

		if (!validateEmail(email)) {
			setEmailError("Invalid email format");
			return;
		}

    	const forgotPasswordData = {
            email: email,
        };

        apiCall({
		  path: "/firebase/forgot_password",
		  method: "POST",
		  data: forgotPasswordData
		});
    }

    useEffect(()=>{
    	if(response){
    		console.log(response);
			setShowForgotPasswordPopup(false);
    	}
    },[response])

	useEffect(() => {
		if (email) {
			setEmailError("");
		}
	}, [email]);

	return(
		<>
			{active && (
				<Popup close={toggleForgotPasswordPopup}>
			<div className="flex flex-row justify-center pb-2">
				<img src={logo} alt="Surge Classes" className="w-[70%]" />
			</div>
			<div className="font-inter text-2xl text-[#101828] font-semibold text-center py-2">
				Forgot Password
			</div>
			<div className="font-inter text-sm  text-center text-[#667085]">
				Please enter your email, to generate new password link.
			</div>
			<form className="flex flex-col py-2">
				<label htmlFor="email" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Email
				</label>
			    <input className="hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4" 
			    	id="email-address"
                    name="email"
                    type="email"
                    value={email}
                    autoComplete ="email"                                    
                    required                                                                                
			    	onChange={(e) => setEmail(e.target.value)}  
         			placeholder="Enter your email" 
						/>
						{emailError && (
							<p className="text-red-500 text-sm">{emailError}</p>
						)}				
			</form>
					{isLoading ? <div className="py-2"><Loader /></div> :
            <button className="my-2 bg-primary text-white rounded-md py-2 font-bold" onClick={handleForgotPassword}> Submit </button>
					}
				</Popup>
			)}
		</>
	);
}

export default ForgotPassword;

import downarrow from "../../../assets/main/downarrow.svg";
import forward from "../../../assets/programsicon/forward.svg"
import { useState, useEffect, useRef } from 'react';
import * as data from "../../../data/SurgeCourses.jsx";
import { useNavigate } from 'react-router-dom';

const ExploreDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);


  const options = [
    { value: data.SurgeProgramsAll.selfpacedprogram, label: 'Self Paced Program', id: 2 },
    { value: data.SurgeProgramsAll.campusprogram, label: 'Campus Program', id:0 },
    { value: data.SurgeProgramsAll.proprogram, label: 'Pro Program', id: 1 },
    { value: data.SurgeProgramsAll.livebatches, label: 'Live Batches', id: 3 }
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedData(option.value);
    setSelectedOption(option.id);
    setIsSelected(true);
  };

 const handleCourseSelect = (data) => {
    const coursePath = data.code;
    navigate(`/course/${coursePath}`);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className="relative">
      <div className="bg-primary rounded-[10px] width-[11rem] h-9 cursor-pointer" onClick={toggleDropdown}>
        <div className="flex flex-row px-3">
          <div className="text-xs py-2.5 pr-2 text-white">Explore</div>
          <div className="w-3 pt-4">
            <img src={downarrow} alt="down-arrow" />
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="absolute flex flex-row">
          <div><ul className="shadow-md mt-2 w-48 bg-white rounded">
            {options.map((option,i) => (
              <li
                key={i} 
                className={`py-2 px-4 cursor-pointer text-sm hover:bg-[#DAF2FF] hover:rounded-l-md hover:font-bold shadow-sm ${option.id === selectedOption ? "border-l-8 border-primary rounded-l-md bg-[#DAF2FF] font-bold" : ""}`}
                onClick={() => handleOptionSelect(option)}
              >
                <div className="flex flex-row justify-between">
                {option.label}
                <div className="w-1.5 flex flex-col justify-center">
                  <img src={forward} alt="forward-arrow" />
                </div>
                </div>
              </li>
            ))}
          </ul></div>
          {isOpen && isSelected && (
            <div className="py-2 px-4 bg-white cursor-pointer text-sm rounded-md shadow-md w-96">
              <div className="flex flex-row justify-between font-bold py-2">
                {selectedData[0].type} {selectedData[0].tag ? ('- ' + selectedData[0].tag) : null}
              </div>
              <ul className="flex flex-col mt-1">
              {selectedData.map((data,i) => (
                <li key={i} className="py-1 cursor-pointer text-sm my-0.5" onClick={() => handleCourseSelect(data)}>
                  <div className="p-1 rounded-md shadow-sm">
                    {data.title}
                  </div>
                </li>
              ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExploreDropdown;

import { Link } from 'react-router-dom';
import logo from '../../../assets/main/logo.svg';
import ExploreMobile from './ExploreMobile';
const MobileMenu = ({ isOpen, onClose, navLinks, isLogin, togglePopup }) => {

    const handleClose = () => {
        onClose();
    };

    return (
        <div
            className={`fixed top-0 left-0 w-full h-full ${isOpen ? 'block' : 'hidden'
                }`}
        >
            <div
                className={`bg-white opacity-100 w-80 h-full p-4 transform transition-transform ease-in-out duration-300 ${isOpen ? 'translate-x-0' : '-translate-x-full'
                    }`}
            >
                <div className="flex justify-between py-5">
                    <div className="w-25 h-7">
                        <img src={logo} alt="xyz" className="h-full w-full object-cover" />
                    </div>
                    <button onClick={handleClose} className="close-button">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                            />
                        </svg>
                    </button>
                </div>
                <div className='flex flex-col space-y-4 pl-3'>
                    <div className="flex">
                        <ExploreMobile onClose={onClose} />
                    </div>
                    <div className="flex flex-col my-4">
                        <div className="font-inter text-base font-bold text-primary pb-3 ">Pages</div>
                        <div className="flex flex-col justify-start space-y-4">
                            <ul className="flex flex-col w-3/4 space-y-1.5">
                                {navLinks.map((link, i) => (
                                    <Link key={i} to={link.path} onClick={handleClose}>
                                        <li
                                            className={`relative inline-block ${link.active ? ' text-primary' : ''
                                                }`}
                                        >
                                            <span className="group relative">
                                                <div className='hover:pb-0.5 text-primary'>
                                                    {link.title}
                                                </div>
                                                <span className={`${link.active ? 'scale-x-100 h-[2.5px]' : 'group-hover:scale-x-100 h-[1px]'
                                                    } absolute inset-x-0 bottom-0 bg-primary transform scale-x-0 origin-left  transition-transform`}></span>
                                            </span>
                                        </li>

                                    </Link>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default MobileMenu;




import React from "react";
import linkedin from "../../../assets/companylogos/linkedin.png";

const ReviewCard = ({ reviewDetail, activeIndex, dataLen, cardIndex }) => {
  const center = (activeIndex + 1) % dataLen;
  const isCenter = cardIndex === center;

  const isLargeScreen = window.innerWidth >= 1280;

  const cardClasses = isLargeScreen
    ? `transform ${isCenter ? "scale-125" : "scale-100"} ${isCenter ? "z-10" : "z-1"
    } ${isCenter ? "opacity-100" : "opacity-30"}`
    : ""; 


  return (
    <div className="flex flex-col justify-center py-10 h-[520px] md:h-[570px]">
      <div
        key={reviewDetail.id}
        className={`flex flex-col border-2 shadow-md rounded-[5%] mx-3 w-[280px] md:w-[320px] h-[460px] md:h-[400px] bg-white ${cardClasses}`}
      >
        <div className="flex flex-col justify-start">
          <div className="flex flex-row justify-between">
            <div className="w-32 h-32 md:w-32 md:h-32 overflow-hidden">
              <img
                src={reviewDetail.img}
                alt={reviewDetail.name}
                className="w-full h-full object-cover rounded-tl-2xl rounded-br-2xl"
              />
            </div>

            <div className="p-3 sm:p-1 flex flex-col justify-start mt-1 w-[50%] md:w-[70%] items-center">
              <div className="font-inter text-sm font-bold text-black text-center xl:text-sm overflow-hidden whitespace-nowrap w-32 md:w-40">
                {reviewDetail.name}
              </div>
              <div className="font-inter text-xs text-black text-center xl:text-sm py-1">
                {reviewDetail.batch}
              </div>
              <button className="rounded-[10px] p-1 px-2  border-2 w-[80%] sm:w-[70%] flex flex-row justify-center hover:bg-blue-200 mt-2">
                <a href={reviewDetail.linkedin} rel="noreferrer" target="_blank">
                  <div className="w-15 h-6 flex flex-row justify-center items-center">
                    <img src={linkedin} alt="xyz" className="w-full h-full object-cover" />
                  </div>
                </a>
              </button>
            </div>
          </div>
          <div className="border-b py-1 border-gray-300"></div>
          <div className="py-1 px-4 flex flex-row justify-center items-center text-center bg-white h-[320px] md:h-[250px]">
            <q className="font-inter text-xs text-[#7A7A7A] py-2 px-1 overflow-y-hidden">
              {reviewDetail.review}
            </q>
          </div>

        </div>
      </div>
    </div>

  );
};

export default ReviewCard;

import { useState , useEffect, useCallback } from "react";
import TickMarkSvg from "../../assets/svgs/TickMarkSvg";
import PaymentTickSvg from "../../assets/svgs/PaymentTickSvg";
import SuccessSvg from "../../assets/svgs/SuccessSvg";
import ErrorWarningSvg from "../../assets/svgs/ErrorWarningSvg";
import { useHttp } from "../../hooks/useHttp";
import Loader from "../ui/Loader";

const PaymentInfoForm = ({ formData, setFormData, handlePayment, isLogin, close, referral_code, payment_page_info }) => {
    const { apiCall, response, isLoading } = useHttp();
    const referralCodeStatusText = [
        `50% Discount`,
        `Congrats! your referral code is applied successfully!`,
        "Error , Code is not valid"
    ]
    const [isReferralCodeApplied, setIsReferralCodeApplied] = useState(false);
    const [referralCode, setReferralCode] = useState(referral_code || "");
    const [isReferralCodeValid, setIsReferralCodeValid] = useState(true);

    const applyReferralCode = useCallback((referralCode) => {
        apiCall({
            path: '/referral_code/validate',
            method: 'POST',
            data: {
                referral_code: referralCode,
            },
        });
    }, [apiCall]);

    useEffect(() => {
        if (referral_code) {
            setReferralCode(referral_code);
            applyReferralCode(referral_code);
        }
    }, [referral_code, applyReferralCode]);

    useEffect(() => {
        if (response) {
            if (response.valid) {
                setIsReferralCodeApplied(true);
                setIsReferralCodeValid(true);
            } else {
                setIsReferralCodeApplied(false);
                setIsReferralCodeValid(false);
            }
        }
    }
    , [response]);

    const onInputChange = (name, value) => {
        setIsReferralCodeApplied(false);
        setIsReferralCodeValid(true);
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === "referralCode") {
            setReferralCode(value);
        }
    };


    const onSubmit = (e) => {
        e.preventDefault();
        handlePayment(isLogin);
        close();
    };

    return (
        <div className="lg:w-[80%] w-[100%] h-full">
            <div className="font-inter text-base lg:text-2xl text-primary font-bold text-center py-2">
                {payment_page_info && payment_page_info.course_name && payment_page_info.course_name}
            </div>
            <div className="font-inter lg:text-base text-xs text-center text-[#667085] pb-1 lg:pb-4">
                {payment_page_info && payment_page_info.course_description && payment_page_info.course_description}
            </div>

            <div className="font-inter text-sm lg:text-lg text-black font-bold text-left py-2">
                This Specialization includes:
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 py-0 lg:py-1 lg:gap-1">
                {payment_page_info && payment_page_info.course_key_points && payment_page_info.course_key_points.map((point, index) => (
                    <div key={index} className="flex flex-row justify-start font-semibold text-xs lg:text-base text-primary text-left">
                        <div className="hidden lg:block mr-0 lg:mr-2">
                            <TickMarkSvg />
                        </div>
                        <div className="mr-1 lg:hidden text-primary text-sm font-bold">
                            &#8226;
                        </div>
                        {point}
                    </div>
                ))}
            </div>

            <div className="flex flex-row justify-between py-2 lg:py-4 lg:mt-2">
                <div className="font-inter text-sm lg:text-lg text-black font-bold text-left w-[50%] lg:w-auto">
                    Course Pricing
                </div>
                <div className="flex flex-row justify-start font-inter text-xs lg:text-base text-right text-[#667085]">
                    <div className="mr-0 lg:mr-1 lg:p-0.5 w-auto">
                        {isReferralCodeApplied ? <SuccessSvg /> : (isReferralCodeValid ? "" : <ErrorWarningSvg />)}
                    </div>
                    {isReferralCodeApplied ? referralCodeStatusText[1] : (isReferralCodeValid ? referralCodeStatusText[0] : referralCodeStatusText[2])}
                </div>
            </div>

            <div className="flex flex-col lg:flex-row justify-between py-1 lg:py-2 px-2 lg:px-4 border-2 rounded-md border-primary">
                <div className="flex flex-row justify-start">
                    <div className="hidden lg:block m-1 mr-3 ">
                        <PaymentTickSvg />
                    </div>
                    <div className="flex flex-col items-start text-left">
                        <div className="font-inter text-sm lg:text-lg text-black font-bold">
                            {payment_page_info && payment_page_info.course_name}
                        </div>
                        <div className="font-inter text-xs lg:text-base text-[#667085]">
                            {payment_page_info && payment_page_info.about_course[0]}
                        </div>
                        <div className="font-inter text-xs lg:text-base text-[#667085]">
                            {payment_page_info && payment_page_info.about_course[1]}
                        </div>
                    </div>
                </div>
                <div className="font-inter text-sm text-left lg:text-2xl text-primary font-bold lg:text-right py-2 pr-2">
                    {isReferralCodeApplied ? "₹" + (payment_page_info && payment_page_info.course_fee) : "₹" + payment_page_info.course_fee}
                </div>
            </div>

            <div className="font-inter text-sm lg:text-lg text-black font-bold text-left py-2">
                Have a referral code?
            </div>
            <div className="flex flex-row justify-between">
                <input
                    className={`hover:border-gray-400 placeholder-text-slate-400 border h-7 lg:h-10 rounded-sm px-2 w-[70%] ${isReferralCodeValid ? "border-[#D0D5DD]" : "border-red-500"} `}
                    placeholder="Enter referral code"
                    type="text"
                    name="referralCode"
                    value={referralCode}
                    onChange={(e) => onInputChange("referralCode", e.target.value)}
                />
                {isLoading ? <Loader /> : (
                <div
                    className={`font-inter text-xs lg:text-lg font-bold text-center p-1 cursor-pointer border-2 rounded-md  w-[20%] h-7 lg:h-10 ${isReferralCodeValid ? "text-primary border-primary" : "text-red-500 border-red-500"}`}
                    onClick={() => applyReferralCode(referralCode)}
                >
                    {isReferralCodeApplied ? "Applied" : (isReferralCodeValid ? "Apply" : "Invalid")}
                </div>
                )}
            </div>
            <div className="flex flex-row justify-center font-inter text-base py-0 lg:py-4 text-[#344054]">
                <button
                    className={`bg-[#F05454] text-white font-inter text-sm lg:text-base font-semibold py-1.5 lg:py-2 rounded-sm w-[80%] mt-4 cursor-pointer`}
                    type="submit"
                    onClick={(e) => onSubmit(e)}
                >
                    PAY NOW
                </button>
            </div>

        </div>
    )
}

export default PaymentInfoForm;
const FloatingDiv = ({ children, position }) => {
    let positionClasses = '';
    switch (position) {
        case 'left-top':
            positionClasses = 'top-[-15px] lg:top-5 left-2 lg:left-0';
            break;
        case 'right-center':
            positionClasses = 'top-1/2 lg:top-1/2 right-[-40px] lg:right-[-80px] transform -translate-y-1/2';
            break;
        case 'center-bottom':
            positionClasses = 'bottom-[-30px] lg:bottom-[-25px] left-1/2 transform -translate-x-1/2';
            break;
        case 'right-top':
            positionClasses = 'top-[-40px] lg:top-[-25px] right-0';
            break;
        case 'left-center':
            positionClasses = 'top-1/2 left-[-30px] lg:left-[-50px] transform -translate-y-1/2';
            break;
        case 'right-bottom':
            positionClasses = 'bottom-[-25px] right-5';
            break;
        default:
            break;
    }
    return (
        <div className={`absolute bg-[#A6E1FFE5] text-primary text-xs lg:text-lg font-semibold text-center w-[140px] lg:w-[290px] p-1 lg:p-4 rounded-xl ${positionClasses}`}>
            {children}
        </div>
    );
}
export default FloatingDiv;
import campusprogramicon from "../assets/programsicon/campusprogramicon.svg";
import proprogramicon from "../assets/programsicon/proprogramicon.svg";
import selfpacedprogramicon from "../assets/programsicon/selfpacedprogramicon.svg";
import amazon from "../assets/alumnicompanies/amazon.png";
import google from "../assets/alumnicompanies/google.png";
import iitb from "../assets/alumnicompanies/iitb.png";
import iitd from "../assets/alumnicompanies/iitd.png";
import iitm from "../assets/alumnicompanies/iitmadras.png";
import microsoft from "../assets/alumnicompanies/microsoft.png";
import stanford from "../assets/alumnicompanies/stanford.png";
import mernstackimg from "../assets/programcardimages/mernstackimg.png";
import dsa from "../assets/programcardimages/dsa.png";
import datasci from "../assets/programcardimages/datasci.png";
import proprogram from "../assets/animations/proprogram.mp4";
import campusprogram from "../assets/animations/campusprogram.mp4";
import selfpacedprogram from "../assets/animations/selfpacedprogram.mp4";
import krishna from "../assets/instructors/krishna/profileimg.png";
import shubham from "../assets/instructors/shubham/profileimg.png";
import srikanth from "../assets/instructors/srikanth/profileimg.png";
import abhishek from "../assets/instructors/abhishek/profileimg.png";
import akash from "../assets/instructors/akash/profileimg.webp";
import priyank from "../assets/instructors/priyank/profileimg.webp";
import vikas from "../assets/instructors/vikas/profileimg.webp";
import debjit from "../assets/instructors/debjit/profileimg.webp";
import cgi from "../assets/companylogos/cgi.png";
import cigna from "../assets/companylogos/cigna.png";
import siemens from "../assets/companylogos/siemens.png";
import amex from "../assets/companylogos/amex.webp";
import exl from "../assets/companylogos/exl.webp";
import wilistowerwatson from "../assets/companylogos/wilistowerwatson.png";
import accenture from "../assets/hiringcompanies/accenture.png";
import amazonx from "../assets/hiringcompanies/amazon.png";
import americanexpress from "../assets/hiringcompanies/americanexpress.png";
import axisbank from "../assets/hiringcompanies/axisbank.png";
import bottomline from "../assets/hiringcompanies/bottomline.png";
import capgemini from "../assets/hiringcompanies/capgemini.png";
import cognizant from "../assets/hiringcompanies/cognizant.png";
import deloitte from "../assets/hiringcompanies/deloitte.png";
import diageo from "../assets/hiringcompanies/diageo.png";
import dotgo from "../assets/hiringcompanies/dotgo.png";
import hcl from "../assets/hiringcompanies/hcl.png";
import paloalto from "../assets/hiringcompanies/paloalto.png";
import phenom from "../assets/hiringcompanies/phenom.png";
import tcs from "../assets/hiringcompanies/tcs.png";
import infosys from "../assets/hiringcompanies/infosys.png";
import sureify from "../assets/hiringcompanies/sureify.png";
import policyboss from "../assets/companylogos/policyboss.webp";
import skillgigs from "../assets/companylogos/skillgigs.webp";
import geotech from "../assets/companylogos/geotech.webp";
import acolite from "../assets/companylogos/acolite.webp";
import flipkart from "../assets/companylogos/flipkart.webp";
import gayathree from "../assets/surgealumni/Gayathree/gayathree.webp";
import likhith from "../assets/surgealumni/Likhith/likhith.webp";
import sriram from "../assets/surgealumni/Sriram/sriram.webp";
import surekha from "../assets/surgealumni/Surekha/surekha.webp";
import sravya from "../assets/surgealumni/Sravya/sravya.webp";
import lavanya from "../assets/surgealumni/Lavanya/lavanya.webp";
import jagdeesh from "../assets/surgealumni/Jagdeesh/jagdeesh.webp";

export const surgeClassesFeatures = [
  {
    higlights: "Launch",
    description: "your career in Data Science, AI & Software Engineering "
  },
  {
    higlights: "Achieve",
    description: "upskilling targets with development hands-on"
  },
  {
    higlights: "Fulfill",
    description: "your career goals with our industry standard curriculum"
  }
];

export const programs = [
  {
    id: 0,
    title: "Campus Program",
    icon: campusprogramicon,
    key: "campusprogram"
  },
  {
    id: 1,
    title: "Pro Program",
    icon: proprogramicon,
    key: "proprogram"
  },
  {
    id: 2,
    title: "Self Paced Program",
    icon: selfpacedprogramicon,
    key: "selfpacedprogram"
  }
];

export const aboutPrograms = [
  {
    title: "Campus Program",
    description:
      "Acquire new-age Professional skills with our comprehensive project-based learning program. Upskill yourself in Python, SQL, Tableau etc through industry relevant structured curriculum, industry-standard projects , hands-on activities, and immersive workshops.",
    covervideo: campusprogram,
    tags: [
      {
        about: "7 LPA",
        description: "70% freshers"
      },
      {
        about: "47 LPA",
        description: "Highest package"
      }
    ]
  },
  {
    title: "Pro Program",
    description:
      "Succeed as a new-age Professional with our comprehensive project-based learning program. Upskill yourself in Python, SQL, Tableau etc through industry relevant structured curriculum, industry-standard projects , hands-on activities, and immersive workshops.",
    covervideo: proprogram,
    tags: [
      {
        about: "85%",
        description: " Experienced Candidates"
      },
      {
        about: "250%",
        description: "Highest hike for experienced candidates"
      }
    ]
  },
  {
    title: "Self Paced Program",
    description:
      "Learn at your own pace and time. Pre-recorded professional courses to upskill your career with industry recognized certifications after completion.",
    covervideo: selfpacedprogram,
    tags: [
      {
        about: "100%",
        description: "Pre-recorded courses"
      },
      {
        about: "100%",
        description: "Certificate on completion"
      }
    ]
  }
];

export const alumniCompanyLogos = [
  {
    name: "iitm",
    logo: iitm
  },
  {
    name: "amazon",
    logo: amazon
  },
  {
    name: "google",
    logo: google
  },
  {
    name: "iitb",
    logo: iitb
  },
  {
    name: "iitd",
    logo: iitd
  },
  {
    name: "microsoft",
    logo: microsoft
  }
];

export const programCardDetails = [
  {
    id: 0,
    name: "Data science and AI program",
    code: "Data-science-and-AI-program",
    duration: "4 Months",
    tags: "100% Online",
    img: datasci,
    category: [
      {
        title: "Online Courses"
      },
      {
        title: "Student Courses"
      },
    ],
    rating: {
      star: 4.5,
      people: 12345
    },
    type: "campusprogram"
  },
  {
    id: 1,
    name: "Data structure & Algorithm",
    code: "Data-structure-and-algorithm",
    duration: "4 Months",
    tags: "100% Online",
    img: dsa,
    category: [
      {
        title: "Online Courses"
      },
      {
        title: "Student Courses"
      },
      {
        title: "Certificate Courses"
      }
    ],
    rating: {
      star: 5,
      people: 12345
    },
    type: "campusprogram"
  },
  {
    id: 2,
    name: "MERN Full Stack Development",
    code: "MERN-Full-Stack-Development",
    duration: "3 Months",
    tags: "100% Online",
    img: mernstackimg,
    category: [
      {
        title: "Online Courses"
      },
      {
        title: "Student Courses"
      },
      {
        title: "Certificate Courses"
      }
    ],
    rating: {
      star: 2.5,
      people: 12345
    },
    type: "campusprogram"
  },

  {
    id: 3,
    name: "Data science and AI Pro program",
    code: "Data-science-and-AI-Pro-program",
    duration: "4 Months",
    tags: "100% Online",
    img: datasci,
    category: [
      {
        title: "Online Courses"
      },
      {
        title: "Professional Courses"
      },
      {
        title: "Certificate Courses"
      }
    ],
    rating: {
      star: 3.5,
      people: 12345
    },
    type: "proprogram"
  },
  {
    id: 4,
    name: "MERN Full Stack Pro program",
    code: "MERN-Full-Stack-Pro-Program",
    duration: "3 Months",
    tags: "100% Online",
    img: mernstackimg,
    category: [
      {
        title: "Online Courses"
      },
      {
        title: "Professional Courses"
      },
      {
        title: "Certificate Courses"
      }
    ],
    rating: {
      star: 4.5,
      people: 12345
    },
    type: "proprogram"
  },
  {
    id: 5,
    name: "Data Science and AI Self Paced Program",
    code: "Data-science-and-AI-self-paced-program",
    duration: "4 Months",
    tags: "100% Online",
    img: datasci,
    category: [
      {
        title: "Student Courses"
      },
      {
        title: "Professional Courses"
      },
      {
        title: "Certificate Courses"
      }
    ],
    rating: {
      star: 4.5,
      people: 12345
    },
    type: "selfpacedprogram"
  },
  {
    id: 6,
    name: "MERN Full Stack Self Paced Program",
    code: "MERN-Full-Stack-self-paced-program",
    duration: "3 Months",
    tags: "100% Online",
    img: mernstackimg,
    category: [
      {
        title: "Student Courses"
      },
      {
        title: "Professional Courses"
      },
      {
        title: "Certificate Courses"
      }
    ],
    rating: {
      star: 4.5,
      people: 12345
    },
    type: "selfpacedprogram"
  },
  {
    id: 7,
    name: "DS & Algo Self Paced Program",
    code: "Data-structure-and-algorithm-self-paced-program",
    duration: "4 Months",
    tags: "100% Online",
    img: dsa,
    category: [
      {
        title: "Student Courses"
      },
      {
        title: "Professional Courses"
      },
      {
        title: "Certificate Courses"
      }
    ],
    rating: {
      star: 4.5,
      people: 12345
    },
    type: "selfpacedprogram"
  }
];

export const eventCardDetails = [
  {
    id: 0,
    name: " MERN Full Stack Event",
    participants: "130",
    tags: "Monday, 20 September, 6pm",
    img: mernstackimg
  },
  {
    id: 1,
    name: "Data Science and AI Event",
    participants: "150",
    tags: "Monday, 8 October, 6pm",
    img: mernstackimg
  }
];

export const instructorDetails = [
  {
    id: 0,
    name: "Krishna Swamy Jalla",
    designation: "Co-Founder, Surge Classes",
    excompanies: {
      siemens: siemens,
      cigna: cigna
    },
    img: krishna,
    organisation: "IIT BOMBAY",
    linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
  },
  {
    id: 1,
    name: "Srikanth Annibathina",
    designation: "Analytics Manager, Cognizant",
    excompanies: {
      cgi: cgi,
      cigna: cigna
    },
    img: srikanth,
    organisation: "VTU, KARNATAKA",
    linkedinurl: "https://www.linkedin.com/in/srikanth-annabathina-7842a9188/"
  },
  {
    id: 2,
    name: "Shubham Jaiswal",
    designation: "Analytics Analyst, McKinsey",
    excompanies: {
      wilistowerwatson: wilistowerwatson
    },
    img: shubham,
    organisation: "IIT BOMBAY",
    linkedinurl: "https://www.linkedin.com/in/shubham-jaiswal-0aa5a65b/"
  },
  {
    id: 3,
    name: "Akash Pandey",
    designation: "Manager, Times Internet",
    excompanies: {
      amex: amex,
      exl: exl
    },
    img: akash,
    organisation: "IIT KANPUR",
    linkedinurl: "https://www.linkedin.com/in/akash-pandey-676307108/"
  },
  {
    id: 4,
    name: "Abhishek Potnis",
    designation: "R&D Associate in ML, Oak Ridge",
    excompanies: {
      wilistowerwatson: wilistowerwatson
    },
    img: abhishek,
    organisation: "IIT BOMBAY",
    linkedinurl: "https://www.linkedin.com/in/abhishekpotnis/"
  },
  {
    id: 5,
    name: "Priyank Srivastav",
    designation: "MERN instructor, Surge Classes",
    excompanies: {
      skillgigs: skillgigs,
      policyboss: policyboss
    },
    img: priyank,
    organisation: "IIT ROORKEE",
    linkedinurl: "https://www.linkedin.com/in/thepriyank/"
  },
  {
    id: 6,
    name: "Vikas Srivastava",
    designation: "MERN instructor, Surge Classes",
    excompanies: {
      flipkart: flipkart,
      acolite: acolite,
    },
    img: vikas,
    organisation: "IIT DELHI",
    linkedinurl: "https://www.linkedin.com/in/vikasjk/"
  },

  {
    id: 7,
    name: "Debjit Sinha",
    designation: "MERN instructor, Surge Classes",
    excompanies: {
      acolite: acolite,
      geotech: geotech
    },
    img: debjit,
    organisation: "SENIOR SDE, VIACOM18",
    linkedinurl: "https://www.linkedin.com/in/frontend-ninja/"
  },

];

export const hiringCompanies = [
  {
    accenture: accenture,
    amazon: amazonx,
    americanexpress: americanexpress,
    axisbank: axisbank,
    bottomline: bottomline,
    capgemini: capgemini,
    cognizant: cognizant,
    deloitte: deloitte,
    diageo: diageo,
    dotgo: dotgo,
    hcl: hcl,
    paloalto: paloalto,
    phenom: phenom,
    tcs: tcs,
    infosys: infosys,
    sureify: sureify
  }
];

export const alumniDetails = [
  {
    id: 0,
    name: "Gayathree Dulam",
    batch: "ECE",
    company: amazon,
    img: gayathree,
    package: "44 LPA",
    linkedin: "https://www.linkedin.com/in/gayathree-dulam-b69102198/",
    description:
      "It was really a great learning experience during the program . We learnt a lot of concepts related to data science and data structures from Instructors who are working in Microsoft, Amazon, Flipkart, Walmart, Siemens, Cigna and other MNCs through live sessions which made us well equipped for the industry. The Instructors were always more than happy to solve the doubts we had during sessions. Instructors have a lot of patience and depth in concepts with huge industry experience. The quality instructor led live sessions is the best thing happened for me to program and entire ecosystem helped me to get into Amazon. I would recommend Surge Classes on any day."
  },

  {
    id: 1,
    name: "Jagadeesh Medapati",
    batch: "Electrical",
    company: infosys,
    img: jagdeesh,
    package: "9.5 LPA",
    linkedin: "https://www.linkedin.com/in/jagadeesh-medapati/",
    description: "",
  },
  {
    id: 2,
    name: "Manthina Sriram Varma",
    batch: "CSE",
    company: amazon,
    img: sriram,
    package: "44 LPA",
    linkedin: "https://www.linkedin.com/in/manthina-sriram-varma-804632169/",
    description:
      "I have enrolled to the program for aiming high package jobs. Overall it was a very good program with many modules which were related to good placements. The teaching was good, we were given opportunity to learn thoroughly in every concept through live classes as well as good doubt clarification sessions. The best thing happened to me in the program is mock interviews by industry experts from working in Goldmansachs, Amazon, American Express, Wells Fargo and Siemens which played significant role in getting offer from Amazon at very high package.  Surge Classes is very good choice which I would recommend who want to make big."
  },
  {
    id: 3,
    name: "Surekha Dasari",
    batch: "ECE",
    company: infosys,
    img: surekha,
    package: "6.5 LPA",
    linkedin: "https://www.linkedin.com/in/surekha-dasari-5b9366204/",
    description: "",
  },

  {
    id: 4,
    name: "Likhith Sai kumar",
    batch: "Mech",
    company: tcs,
    img: likhith,
    package: "7 LPA",
    linkedin: "https://www.linkedin.com/in/likhith-sai-kumar-730294190/",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
  },

  {
    id: 5,
    name: "Lavanya Narayanam",
    batch: "IT",
    company: axisbank,
    img: lavanya,
    package: "9.7 LPA",
    linkedin: "https://www.linkedin.com/in/narayanam-loka-lavanya/",
    description:
      "It was a pretty good program where I learned machine learning from scratch. The course structure and the hands-on projects are really beneficial to start a career in data science field and make big. The way of explanation from the trainers is easy to understand and they clarify our doubts well. This program helped me to build a profile with ML, NLP and Deep Learning projects on various domains. This quaility Portfolio built in the program helped me to get into Axis Bank in Data Science role. "
  },
  {
    id: 6,
    name: "Sravya Deepika Kommisetti",
    batch: "CSE",
    company: sureify,
    img: sravya,
    package: "9.2 LPA",
    linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
    description: "",
  }
];

export const reviewDetails = [
  {
    id: 0,
    name: "Gayathree Dulam",
    batch: "ECE",
    img: gayathree,
    linkedin: "https://www.linkedin.com/in/gayathree-dulam-b69102198/",
    review:
      "It was really a great learning experience during the program . We learnt a lot of concepts related to data science and data structures from Instructors who are working in Microsoft, Amazon, Flipkart, Walmart, Siemens, Cigna and other MNCs through live sessions which made us well equipped for the industry. The Instructors were always more than happy to solve the doubts we had during sessions. Instructors have a lot of patience and depth in concepts with huge industry experience. The quality instructor led live sessions is the best thing happened for me to program and entire ecosystem helped me to get into Amazon. I would recommend Surge Classes on any day."
  },
  {
    id: 1,
    name: "Manthina Sriram Varma",
    batch: "CSE",
    img: sriram,
    linkedin: "https://www.linkedin.com/in/manthina-sriram-varma-804632169/",
    review:
      "I have enrolled to the program for aiming high package jobs. Overall it was a very good program with many modules which were related to good placements. The teaching was good, we were given opportunity to learn thoroughly in every concept through live classes as well as good doubt clarification sessions. The best thing happened to me in the program is mock interviews by industry experts from working in Goldmansachs, Amazon, American Express, Wells Fargo and Siemens which played significant role in getting offer from Amazon at very high package.  Surge Classes is very good choice which I would recommend who want to make big."
  },
  {
    id: 2,
    name: "Surekha Dasari",
    batch: "ECE",
    img: surekha,
    linkedin: "https://www.linkedin.com/in/surekha-dasari-5b9366204/",
    review:
      "Overall learning experience was good and the set of mentors who had great industrial knowledge is the top aspect of surge classes. Thanks for teaching us the data science and coding things patiently and motivating us to work hard and grow in life. They have guided us specific to certain company interviews even on odd times and on short notice."
  },
  {
    id: 3,
    name: "Likhith Sai kumar",
    batch: "Mech",
    img: likhith,
    linkedin: "https://www.linkedin.com/in/likhith-sai-kumar-730294190/",
    review:
      "The experience was amazing and the design of program was very good. we had done hands-on projects on every data science and coding module which gave me better understanding of Data science eco systems. The mentors were great they made us understand every concept they gave in depth knowledge of concepts and often clarified our doubts in detailed way. This program helped me to get the offers from TCS Digital and Gain Sight in Campus Placement. Being a Mechanical Engineer this is quite accomplishing. "
  },
  {
    id: 4,
    name: "Jagadeesh Medapati",
    batch: "Electrical",
    img: jagdeesh,
    linkedin: "https://www.linkedin.com/in/jagadeesh-medapati/",
    review: "I was completely satisfied with the duration of the programme, Instructors, course lectures as well as projects we have built on various modules. I'd be recommending Surge Classes as anyone can learn the Data science concepts easily from scratch with that mentorship and Live classes."
  },
  {
    id: 5,
    name: "Lavanya Narayanam",
    batch: "IT",
    img: lavanya,
    linkedin: "https://www.linkedin.com/in/narayanam-loka-lavanya/",
    review:
      "It was a pretty good program where I learned machine learning from scratch. The course structure and the hands-on projects are really beneficial to start a career in data science field and make big. The way of explanation from the trainers is easy to understand and they clarify our doubts well. This program helped me to build a profile with ML, NLP and Deep Learning projects on various domains. This quaility Portfolio built in the program helped me to get into Axis Bank in Data Science role. "
  },
  {
    id: 6,
    name: "Sravya Deepika Kommisetti",
    batch: "CSE",
    img: sravya,
    linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
    review:
      "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
  }
];

export const filterData = [
  {
    title: "Online Courses"
  },
  {
    title: "Free Courses"
  },
  {
    title: "Certificate Courses"
  },
  {
    title: "Student Courses"
  },
  {
    title: "Professional Courses"
  }
];

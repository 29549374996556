import Button from "../components/ui/Button";
import Heading from "../components/ui/Heading";
import hirewithusimage from "../assets/main/hirewithus.png";
import hirewithuscov from "../assets/main/hirewithuscov.svg";
import CompanyTags from "../components/ui/tags/CompanyTags";
import Hire from "../components/hire/Hire";
import Popup from "../components/ui/popup/Popup";
import HiringProcessTag from "../components/ui/tags/HiringProcessTag";
import HiringCompanyCard from "../components/ui/cards/HiringCompanyCard";
import { hiringCompanies } from "../data/Main";
import * as data from "../data/HireWithUs";
import { useState } from "react";

const HireWithUs = () => {

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="bg-white">
      <div className="flex flex-col justify-center items-center">
        <div className="xl:ml-10 flex xl:flex-row flex-col text-center xl:text-start xl:justify-start py-5 px-5 xl:px-10 xl:max-w-[1640px]">
          <div className="xl:w-[50%] md:w-full lg:p-5 p-0 pl-0">
            <h1 className="font-inter py-5 xl:text-5xl md:text-3xl text-2xl font-bold text-primary text-center xl:text-left">
              Hire the best candidates from us
            </h1>
            <div className="font-inter py-1 xl:py-3 w-full xl:w-[70%] xl:text-xl text-xs">
              Are you looking for talented individuals to help enhance your business?
              Look no further than our students! Our students are highly trained and
              experienced professionals who can help take your business to the next
              level.
          </div>
            <div className="flex justify-center items-center flex-row xl:justify-start py-3 xl:pt-8">
              <div className="ml-5 xl:ml-0">
                <Button active onClick={togglePopup}>
                  Hire With Us
                </Button>
              </div>
              {showPopup && (
                <Popup close={togglePopup}>
                  <Hire close={togglePopup} />
                </Popup>
              )}
          </div>
        </div>
          <div className="flex xl:flex-col justify-center pr-0 pt-4">
            <div className="xl:w-full xl:h-full h-25">
              <img
                src={hirewithuscov}
                alt="cover-vector"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>


      <div className="bg-[#F9FAFB] flex flex-row justify-center items-center">
        <div className="flex flex-row justify-center lg:justify-start py-5 xl:px-5 xl:max-w-[1640px] ">
          <div className="hidden lg:flex flex-col justify-center lg:p-5 lg:pl-10 lg:w-[50%]">
          <div>
              <div className="flex felx-col justify-center px-10">
                <div className="w-full h-full">
                  <img src={hirewithusimage} alt="hire-with-us-vector" className="w-full h-full object-cover" />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:p-5 lg:pr-10 lg:w-[50%] w-full ">
            <div className="flex flex-col justify-between">
              <div className="hidden lg:flex lg:flex-col justify-center">
                <div className="font-inter pt-5 text-sm xl:text-xl font-bold text-primary">
                  We’ve helped hundreds of Students
                </div>
                <div className="font-inter py-5 text-3xl xl:text-4xl font-bold text-[#101828]">
                  Get industry level trained candidates from surge classes
                </div>
              </div>
              <div className="flex flex-col justify-center  lg:my-4">
                <div className="grid grid-rows-2 grid-flow-col ">
                  <div className="flex flex-col justify-center my-4  w-[150px] md:w-[200px]">
                    <div className="font-inter pt-5 pb-4 text-3xl md:text-6xl font-bold text-primary">
                      100 +
                    </div>
                    <div className="text-xs md:text-base">  Placements completed</div>
                  </div>
                  <div className="flex flex-col justify-center my-4 w-[150px] md:w-[200px]">
                    <div className="font-inter pt-5 pb-4 text-3xl md:text-6xl font-bold text-primary">
                      80 %
                    </div>
                    <div className="text-xs md:text-base"> Placement</div>
                  </div>
                  <div className="flex flex-col justify-center my-4 w-[150px] md:w-[200px]">
                    <div className="font-inter pt-5 pb-4 text-3xl md:text-6xl font-bold text-primary">
                      50 +
                    </div>
                    <div className="text-xs md:text-base"> Professional teachers</div>
                  </div>
                  <div className="flex flex-col justify-center my-4 w-[150px] md:w-[200px]">
                    <div className="font-inter pt-5 pb-4 text-3xl md:text-6xl font-bold text-primary">
                      100 +
                    </div>
                    <div className="text-xs md:text-base"> Success stories</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-center items-center">
        <div className="flex flex-col items-center text-center mt-10  xl:max-w-[1640px]">
        <div className="flex flex-row justify-center">
          <Heading>Hiring Process</Heading>
        </div>
        <div className="mb-1 mt-5 mx-[10%]">
          {data.hiringProcess.map((step, i) => (
              <div key={i} className="flex flex-col items-center py-5">
                <HiringProcessTag step={step} />
              </div>
          ))}
        </div>
        <div className="flex flex-row justify-center">
            <Button active onClick={togglePopup}>Hire With Us</Button>
            {showPopup && (
              <Popup close={togglePopup}>
                <Hire close={togglePopup}/>
              </Popup>
            )}
        </div>
      </div>
      </div>
      <div className="flex flex-col items-center text-center mt-10">
        <div className="flex flex-row justify-center px-5">
          <Heading>150+ companies have hired Surge classes learners</Heading>
        </div>
        <div className="mb-1 mt-5">
          <CompanyTags hiringCompanies={hiringCompanies} />
        </div>
      </div>
      <div className="flex flex-col items-center text-center mt-10">
        <div className="flex flex-row justify-center px-5">
          <Heading>Why companies are hiring with Surge classes?</Heading>
        </div>
        <div className="flex flex-row justify-center">
          <div className="flex flex-row my-10 w-[50%] md-[80%] xl:w-[100%]  xl:max-w-[1640px]">
            <div className="flex flex-row overflow-scroll no-scrollbar w-full">
            {data.hiringCompanyDetails.map((hiringCompanyDetail, i) => (
              <HiringCompanyCard
                key={i}
                hiringCompanyDetail={hiringCompanyDetail}
              />
            ))}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default HireWithUs;

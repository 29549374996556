const ComingSoon = () => {

    return (
        <div className="w-[200px] h-10 relative ">
            <div className="bg-secondary text-white p-2 rounded-lg shadow-lg text-center z-20 relative hover:bg-red-600 cursor-pointer">
                <h2 className="text-2xl font-semibold">Coming Soon</h2>
            </div>
            <div className="rounded-bl-lg bg-primary h-[25px] absolute left-0 top-[29px] transform skew-y-6 w-[40%]"></div>
            <div className="rounded-tr-lg bg-primary h-[25px] absolute right-0 top-[-6px] transform skew-y-6 w-[40%]"></div>
        </div>


    );
};

export default ComingSoon;

import profile from "../../../assets/main/profile.svg";
import downarrow from "../../../assets/main/downarrow.svg";
import { useState, useEffect, useRef } from 'react';

const Dropdown = ({ handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user,setUser] = useState({});

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const options = [
    { value: 'logout', label: 'Logout' }
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {

    if (option.value === 'logout') {
      handleLogout();
    }
    setIsOpen(false);
  };

  useEffect(()=>{
    const token = localStorage.getItem('token');

    const isJSONParsable = (str) => {
      try {
        JSON.parse(str);
        return true;
      } catch (error) {
        return false;
      }
    }

    if (token) {
      if (isJSONParsable(token)) {
        setUser(JSON.parse(token));
      } else {
        let loginUser = JSON.parse(atob(token.split('.')[1]));
        setUser(loginUser);
      }
    }
  },[])


  // useEffect(() => {
  //   if (user) {
  //     const fullName = user.name;
  //     // const splitOnSpace = fullName.split(" ");
  //     setIsDisplayName(fullName);
  //   }
  // }, [user]);  

  // const initials = user.name
  //   ? user.name
  //       .split(' ')
  //       .map(name => name.charAt(0))
  //       .join('')
  //       .toUpperCase()
  //   : '';

  const firstNameOfAName = (name) => {
    return name.split(' ')[0];
  }

  return (
    <div ref={dropdownRef} className="relative">
      <div className="w-fixed flex flex-row justify-evenly bg-primary text-white py-1 px-2 rounded focus:outline-none cursor-pointer" onClick={toggleDropdown}>
        <div className="flex flex-col justify-center p-1"><img src={profile} alt="avatar"/></div>
        <div className="text-sm px-1">
          {user.name ? firstNameOfAName(user.name) : 'User'}
        </div>
        <div className="flex flex-col justify-center p-1"><img src={downarrow} className="w-2" alt="avatar"/></div>
      </div>

      {isOpen && (
        <ul className="absolute bg-white shadow-md mt-2 w-32 rounded">
          {options.map((option) => (
            <li
              key={option.value}
              className="py-2 px-4 cursor-pointer text-sm hover:bg-[#DAF2FF] hover:border-l-8 hover:border-primary hover:rounded-l-md hover:font-bold shadow-sm"
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

import { useState } from "react";
import backwardarrow from "../../../assets/main/backwardarrow.svg";
import forwardarrow from "../../../assets/main/forwardarrow.svg";
import LearnerReviewCard from "../cards/LearnerReviewCard";

const LearnerReviewSlider = ({ learnerDetails }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === learnerDetails.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? learnerDetails.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative flex flex-row justify-evenly my-5 px-1 md:px-2 lg:px-10 w-full">
      <div className="hidden lg:flex lg:flex-col justify-center cursor-pointer">
        <img src={backwardarrow} alt="xyz" className="h-10 w-10" onClick={prevSlide}/>
      </div>
      <div className="lg:overflow-hidden overflow-x-scroll no-scrollbar w-[100%] lg:w-[85%]">
        <div className="flex flex-row transition-transform duration-300"
            style={{
              transform: `translateX(-${activeIndex * 101}%)`,
            }}>
          {learnerDetails.map((learnerDetail, i) => (
            <LearnerReviewCard key={i} learner={learnerDetail}  />
          ))}
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-col justify-center cursor-pointer">
        <img src={forwardarrow} alt="xyz" className="h-10 w-10" onClick={nextSlide} />
      </div>
    </div>
  );
};

export default LearnerReviewSlider;


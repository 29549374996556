import Heading from "../components/ui/Heading";
import ProgramCard from "../components/ui/cards/ProgramCard";
import EventCard from "../components/ui/cards/EventCard";
import AlumniCard from "../components/ui/cards/AlumniCard";
import ProgramTags from "../components/ui/tags/ProgramTags";
import ProgramSidebarElement from "../components/ui/sidebars/ProgramSidebarElement";
import AlumniCompanyTags from "../components/ui/tags/AlumniCompanyTags";
import CompanyTags from "../components/ui/tags/CompanyTags";
import Filter from "../components/ui/Filter";
import InstructorSlider from "../components/ui/sliders/InstructorSlider";
import ReviewSlider from "../components/ui/sliders/ReviewSlider";
import HomepageSlider from "../components/ui/sliders/HomepageSlider";
import * as data from "../data/Main";
import HomepageMainSlide from "../data/slides/HomepageMainSlide";
import { useState, useEffect, useRef } from "react";
import { useHttp } from "../hooks/useHttp";


const HomePage = ({ isLogin, setIsLogin }) => {
  const [selectedProgramId, setSelectedProgramId] = useState("campusprogram");
  const { response, apiCall } = useHttp();
  const [eventsEnrolled, setEventsEnrolled] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({
    key: "campusprogram"
  });
  const [activeProgramId, setActiveProgramId] = useState(0);
  const [activeProgram, setActiveProgram] = useState({
    id: -1
  });
  const [selectedAboutProgram, setSelectedAboutProgram] = useState(0);

  const videoRef = useRef(null);

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    const token = localStorage.getItem("token") || (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).token)
    if (token && isLogin) {
      apiCall({
        path: "/my-events",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
    } else {
      setEventsEnrolled([]);
    }
  }, [apiCall, isLogin]);

  const isEnrolled = (eventId) => {
    return eventsEnrolled.findIndex((event) => event.event_id === eventId) !== -1;
  };

  useEffect(() => {
    if (response && response.enrollments) {
      setEventsEnrolled(response.enrollments);
    }
  }, [response]);



  useEffect(() => {
    if (localStorage.getItem('scrollToCourses')) {
      scrollToSection();
      delete localStorage['scrollToCourses'];
    }
  }, [])

  useEffect(() => {
    let idx = data.programs.findIndex(
      (_program) => _program.key === selectedProgramId
    );
    if (idx !== -1) {
      setSelectedProgram(data.programs[idx]);
    }
  }, [selectedProgramId]);

  useEffect(() => {
    let idx = data.programs.findIndex(
      (_program) => _program.key === activeProgramId
    );
    if (idx !== -1) {
      setActiveProgram(data.programs[idx]);
    } else {
      setActiveProgram({
        key: -1
      });
    }
  }, [activeProgramId]);

  const handleVideoEnded = () => {
    videoRef.current.currentTime = 0;
    const nextSelected = (selectedAboutProgram + 1) % 3;
    const nextVideoSrc = data.aboutPrograms[nextSelected].covervideo;

    const playVideo = () => {
      videoRef.current.play();
      setSelectedAboutProgram(nextSelected);
    };

    videoRef.current.removeEventListener("ended", handleVideoEnded);
    videoRef.current.addEventListener("canplay", playVideo, { once: true });

    // Load the new video source before setting it
    videoRef.current.src = nextVideoSrc;

  };

  const [checked, setChecked] = useState(
    [0, 2, 3].map((i) => data.filterData[i].title)
  );

  const handleCheckbox = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((e) => e !== val));
    } else {
      setChecked([...checked, val]);
    }
  };


  const programFilter = (program) => {
    const titles = program.category.map((c) => c.title);
    return titles.filter((c) => checked.includes(c)).length;
  };

  const scrollToSection = () => {
    const element = document.getElementById('courses');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="home-page bg-white">
      <div className="lg:hidden">
        <HomepageMainSlide isLogin={isLogin} setIsLogin={setIsLogin} showPopup={showPopup} setShowPopup={setShowPopup} togglePopup={togglePopup} scrollToSection={scrollToSection} />
      </div>
      <div className="hidden lg:block">
        <HomepageSlider isLogin={isLogin} setIsLogin={setIsLogin} showPopup={showPopup} setShowPopup={setShowPopup} togglePopup={togglePopup} scrollToSection={scrollToSection} />
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-row justify-center w-[95%]  max-w-[1540px] bg-primary text-white font-bold py-6 lg:px-[100px] px-8 text-center sm:text-xs md:text-base lg:text-xl mt-3">
          Learn from Google, Microsoft, Amazon and IIT professionals
          <br />
          Earn Rs 5 - 50 LPA at leading companies
        </div>
      </div>

      <div className="flex flex-row items-center justify-center">
        <div className="relative max-w-[1540px]">
          <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
            <div className="flex flex-col pt-5 text-center mt-1 mb-2 shadow shadow-[#00000033] relative">
              <Heading>{data.aboutPrograms[selectedAboutProgram].title}</Heading>
              <div className="bg-bgcol/[0.2] p-4 md:px-[200px] lg:text-base text-xs">
                {data.aboutPrograms[selectedAboutProgram].description}
              </div>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="flex flex-col justify-center">
                  <div className="crop-container pt-5">
                    <video
                      className="slider-vid w-full aspect-rectangle"
                      autoPlay={true}
                      muted={true}
                      ref={videoRef}
                      onEnded={handleVideoEnded}
                    >
                      <source
                        src={data.aboutPrograms[selectedAboutProgram].covervideo}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className="hidden lg:flex lg:flex-col p-4 lg:p-20">
                  {data.aboutPrograms[selectedAboutProgram].tags.map((tag, i) => (
                    <ProgramTags tag={tag} key={i} />
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center text-center" id="courses">
        <div className="flex flex-row justify-center my-2">
          <Heading>Programs We Offer</Heading>
        </div>
        <div className="flex flex-col xl:flex-row xl:justify-start xl:max-w-[1440px] sm:justify-center md:justify-center w-full my-5 xl:mx-3">
          <ProgramSidebarElement
            setSelectedProgramId={setSelectedProgramId}
            selectedProgram={selectedProgram}
            setActiveProgramId={setActiveProgramId}
            activeProgram={activeProgram}
            programs={data.programs}
          />
          <div className="mx-5 xl:mx-0 grid grid-rows-1 grid-flow-col gap-0 xl:gap-4 max-w-full overflow-x-scroll no-scrollbar">
            {data.programCardDetails
              .filter((p) => p.type === selectedProgramId)
              .map((programCardDetail, i) => (
                <ProgramCard key={i} programCardDetail={programCardDetail} />
              ))}
          </div>
        </div>
      </div>


      <div className="flex flex-col">
        <div className="flex flex-row justify-center text-center mt-4">
          <Heading>Learn the best from the instructors of</Heading>
        </div>
        <div className="border-2 shadow shadow-[#00000033] mb-1 mt-5 ">
          <div className="flex flex-row justify-center">
            <AlumniCompanyTags companylogos={data.alumniCompanyLogos} />
          </div>
        </div>
        <div className="flex flex-row justify-center py-3">
          and many more...
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-row justify-center">
          <Heading>Popular Programs</Heading>
        </div>
        <div className="flex flex-col lg:flex-row justify-center lg:justify-start lg:max-w-[1340px] w-full my-5 lg:mx-3">
          <div className="hidden lg:flex lg:flex-col pl-7 pr-20">
            <Filter
              handleCheckbox={handleCheckbox}
              filterData={data.filterData}
              checked={checked}
            />
          </div>

          <div className="grid grid-rows-1 grid-flow-col gap-4 max-w-full overflow-x-scroll no-scrollbar">
            {data.programCardDetails
              .filter(programFilter)
              .slice(0, 3)
              .map((programCardDetail, i) => (
                <ProgramCard
                  rating={true}
                  key={i}
                  programCardDetail={programCardDetail}
                />
              ))}
          </div>
          {data.programCardDetails.filter(programFilter).slice(0, 3).length === 0 && (
            <div className="flex flex-col bg-bgcol/[0.2] justify-center w-full text-center pl-7 mr-20 ">
              <div className="font-inter py-5 text-3xl md:text-3xl sm:text-lg text-primary">
                Coming Soon
              </div>
            </div>
          )}

        </div>
      </div>

      <div className="flex flex-col items-center">
        <div className="flex flex-row justify-center">
          <Heading>Our Instructors</Heading>
        </div>
        <div className="flex flex-row justify-center py-3 p-6 lg:px-[150px] text-center lg:max-w-[1450px] lg:text-base text-sm">
          Introducing Surge Classes' visionary instructors – industry luminaries fueling our mission to cultivate top-tier talent in data science, AI, and more. With our immersive six-month Master Programmes led by tech experts, we empower learners with cutting-edge skills and real-world projects, setting them on a trajectory for success.
        </div>
        <InstructorSlider instructorDetails={data.instructorDetails} />
      </div>

      <div className="flex flex-col items-center">
        <div className="flex flex-row justify-center">
          <Heading>Surge Events</Heading>
        </div>
        <div className="flex flex-row justify-center  py-3 lg:text-base text-xs p-6 lg:px-[150px] text-center lg:max-w-[1340px]">
          Access events that will enable you to learn more about your preferred
          fields and advance to the next level. They are captured by
          professionals in their fields with extensive industry experience.
        </div>
        <div className="flex flex-col lg:flex-row justify-center lg:justify-start lg:max-w-[1340px] w-full my-5 lg:mx-3">
          <div className="hidden lg:flex lg:flex-row lg:justify-center text-center lg:px-3 lg:mx-10">
            <Heading>Upcoming events</Heading>
          </div>
          <div className="grid grid-rows-1 grid-flow-col sm:justify-center max-w-full overflow-x-scroll no-scrollbar">
            {data.eventCardDetails.map((eventCardDetail, i) => (
              <EventCard key={i} enrolled={isEnrolled(eventCardDetail.id)} eventCardDetail={eventCardDetail} isLogin={isLogin} />
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-10 items-center">
        <div className="flex flex-row justify-center text-center">
          <Heading>150+ companies have hired Surge classes learners</Heading>
        </div>
        <div className="flex flex-row justify-center mb-1 mt-5">
          <CompanyTags hiringCompanies={data.hiringCompanies} />
        </div>
      </div>

      <div className="flex flex-col mt-5 items-center">
        <div className="flex flex-row justify-center">
          <Heading>Our Surge Alumni</Heading>
        </div>
        <div className="flex flex-row justify-evenly my-5 w-full lg:max-w-[1540px]">
          <div className="flex flex-row overflow-scroll no-scrollbar snap-center">
            {data.alumniDetails.map((alumniDetail, i) => (
              <AlumniCard key={i} alumniDetail={alumniDetail} />
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col my-5 items-center">
        <div className="flex flex-row justify-center">
          <Heading>Reviews</Heading>
        </div>
        <ReviewSlider reviewDetails={data.reviewDetails} />
      </div>

    </div>
  );
};

export default HomePage;

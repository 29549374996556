import { useState } from "react";
import backwardarrow from "../../../assets/main/backwardarrow.svg";
import forwardarrow from "../../../assets/main/forwardarrow.svg";
import InstructorCard from "../cards/InstructorCard";

const InstructorSlider = ({ instructorDetails }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === instructorDetails.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? instructorDetails.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative flex flex-row lg:justify-between my-5 px-5 lg:px-10 lg:max-w-[1420px] w-[100%]">
      <div className="hidden lg:flex lg:flex-col lg:justify-center lg:cursor-pointer">
        <img src={backwardarrow} alt="xyz" className="h-10 w-10" onClick={prevSlide} />
      </div>
      <div className="overflow-scroll no-scrollbar w-full lg:px-20">
        <div
          className={`flex flex-row transition-transform duration-300`}
          style={{
            transform: `translateX(-${activeIndex * 33.3}%)`,
          }}
        >
          {instructorDetails.map((instructorDetail, i) => (
            <InstructorCard key={i} instructorDetail={instructorDetail} />
          ))}
        </div>
      </div>
      <div className="hidden lg:flex lg:flex-col lg:justify-center lg:cursor-pointer">
        <img src={forwardarrow} alt="xyz" className="h-10 w-10" onClick={nextSlide} />
      </div>
    </div>


  );
};

export default InstructorSlider;


import React from "react";

const StarComponent = ({ rating }) => {
  const stars = [];

  const halfStar = (
    <svg
      key={6}
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.025 14.7062L3.675 18.05L5.0125 12.0312L1 8.01875L6.35 7.35L9.025 2L11.7 7.35L17.05 8.01875L13.0375 12.0312L14.375 18.05L9.025 14.7062Z"
        fill="#EDC90E"
        stroke="#DAC34A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0625 12.25L15.1875 19H14.625L9 15.625V1L10.4062 3.8125L11.8125 6.625L18 7.75L14.0625 12.25Z"
        fill="white"
        stroke="#F8F8F8"
      />
    </svg>
  );

  for (let i = 0; i < Math.floor(rating); i++) {
    stars.push(
      <svg
        key={i}
        className="w-5 h-5 my-2 fill-current text-yellow-500"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.025 13.7063L3.675 17.05L5.0125 11.0312L1 7.01875L6.35 6.35L9.025 1L11.7 6.35L17.05 7.01875L13.0375 11.0312L14.375 17.05L9.025 13.7063Z"
          fill="#EDC90E"
          stroke="#DAC34A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (Math.floor(rating) !== Math.ceil(rating)) {
    stars.push(halfStar);
  }

  return <div className="flex items-center">{stars}</div>;
};

export default StarComponent;

import { Link } from "react-router-dom";
import logo from "./../assets/main/logo.svg";
// import Search from "./ui/Search";
import Button from "./ui/Button";
import ProfileDropdown from "./ui/dropdown/ProfileDropdown";
import ExploreDropdown from "./ui/dropdown/ExploreDropdown";
import MobileMenu from "./ui/popover/MobileMenu";
import Login from "./auth/Login";
import SignUp from "./auth/SignUp";
import ForgotPassword from "./auth/ForgotPassword";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import toastMixin from "../utils/toastMixin";

const links = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Hire With Us",
    path: "/hire",
  },
  {
    title: "About Us",
    path: "/about",
  }, 
  {
    title: "Blogs",
    path: "https://blog.surgeclasses.com/",
  },
];

const Navbar = ({ isLogin, setIsLogin }) => {
  const location = useLocation();
  const path = location.pathname;
  const [navLinks, setNavLinks] = useState(links);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const togglePopup = () => {
    setShowLoginPopup(!showLoginPopup);
  };

  const handleLogout = () => {
    try {
      delete localStorage["token"];
      setIsLogin(false);
      toastMixin({
        timeout: 1000
      }).fire({
        icon: "success",
        title: "You are successfully logged out."
      })
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  const handleOptionSelect = () => {
    handleLogout();
    setIsDropdownOpen(false);
  };

  const goToFreeCourse = () => {
    window.location.href = "/free-course/Machine-learning-fundamentals";
  };

  useEffect(() => {
    setNavLinks((prevNavLinks) =>
      prevNavLinks.map((link) => {
        if (link.path === path) {
          link.active = true;
        } else {
          link.active = false;
        }
        return link;
      })
    );
  }, [path]);


  useEffect(() => {
    if (isMobileMenuVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuVisible]);

  return (
    <nav className="shadow shadow-[#00000033] bg-white mb-1 p-5">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row justify-start w-1/2 px-3 lg:hidden">
          <div className="flex flex-row">
            <div
              className="px-2 py-1"
              onClick={toggleMobileMenu}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_527_25803)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0 3.75C0 3.55109 0.0790176 3.36032 0.21967 3.21967C0.360322 3.07902 0.551088 3 0.75 3H15.25C15.4489 3 15.6397 3.07902 15.7803 3.21967C15.921 3.36032 16 3.55109 16 3.75C16 3.94891 15.921 4.13968 15.7803 4.28033C15.6397 4.42098 15.4489 4.5 15.25 4.5H0.75C0.551088 4.5 0.360322 4.42098 0.21967 4.28033C0.0790176 4.13968 0 3.94891 0 3.75ZM0 8C0 7.80109 0.0790176 7.61032 0.21967 7.46967C0.360322 7.32902 0.551088 7.25 0.75 7.25H15.25C15.4489 7.25 15.6397 7.32902 15.7803 7.46967C15.921 7.61032 16 7.80109 16 8C16 8.19891 15.921 8.38968 15.7803 8.53033C15.6397 8.67098 15.4489 8.75 15.25 8.75H0.75C0.551088 8.75 0.360322 8.67098 0.21967 8.53033C0.0790176 8.38968 0 8.19891 0 8ZM0.75 11.5C0.551088 11.5 0.360322 11.579 0.21967 11.7197C0.0790176 11.8603 0 12.0511 0 12.25C0 12.4489 0.0790176 12.6397 0.21967 12.7803C0.360322 12.921 0.551088 13 0.75 13H15.25C15.4489 13 15.6397 12.921 15.7803 12.7803C15.921 12.6397 16 12.4489 16 12.25C16 12.0511 15.921 11.8603 15.7803 11.7197C15.6397 11.579 15.4489 11.5 15.25 11.5H0.75Z" fill="black" />
                </g>
                <defs>
                  <clipPath id="clip0_527_25803">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </div>
          </div>
        </div>
        <div className="flex flex-row lg:justify-start justify-center items-center px-3">
          <div className="sm:flex sm:flex-row sm:justify-center sm:items-center">
            <Link to="/">
            <div className="sm:w-48 px-1 sm:h-7 xl:w-[300px] xl:h-[50px] md:w-48 md:h-7 cursor-pointer">
              <img src={logo} alt="Surge Classes" className="w-full h-full object-cover" />
            </div>
            </Link>
          </div>
          <div className="hidden lg:flex lg:flex-row pl-5 xl:px-10">
            <ExploreDropdown />
          </div>
        </div>

        <div className="lg:hidden flex flex-row justify-end w-1/2 px-3 " >
          <div className="relative flex flex-col items-center justify-center">
            <div onClick={isLogin ? toggleDropdown : togglePopup}>
              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="35" height="35" rx="17.5" fill="#EEF9FF" />
                <path d="M23.3327 24.0625V22.6042C23.3327 21.8306 23.0254 21.0888 22.4784 20.5418C21.9314 19.9948 21.1896 19.6875 20.416 19.6875H14.5827C13.8091 19.6875 13.0673 19.9948 12.5203 20.5418C11.9733 21.0888 11.666 21.8306 11.666 22.6042V24.0625M20.416 13.8542C20.416 15.465 19.1102 16.7708 17.4993 16.7708C15.8885 16.7708 14.5827 15.465 14.5827 13.8542C14.5827 12.2433 15.8885 10.9375 17.4993 10.9375C19.1102 10.9375 20.416 12.2433 20.416 13.8542Z" stroke="#004C73" strokeWidth="1.45833" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            {showLoginPopup && (
              <Login
                showLoginPopup={showLoginPopup}
                setShowLoginPopup={setShowLoginPopup}
                setShowForgotPasswordPopup={setShowForgotPasswordPopup}
                setIsLogin={setIsLogin}
                setShowSignupPopup={setShowSignupPopup}
              />
            )}
            {showForgotPasswordPopup && (
              <ForgotPassword
                active={showForgotPasswordPopup}
                setShowForgotPasswordPopup={setShowForgotPasswordPopup}
              />
            )}
            {showSignupPopup && (
              <SignUp
                showSignupPopup={showSignupPopup}
                setShowSignupPopup={setShowSignupPopup}
                setIsLogin={setIsLogin}
              />
            )}
          </div>
        </div>
        {isDropdownOpen && (
          <ul className="absolute bg-white shadow-md mt-10 right-8 w-32 rounded">
            <li
              className="py-2 px-4 text-center cursor-pointer text-sm bg-[#DAF2FF] hover:rounded-md hover:font-bold shadow-sm"
              onClick={() => handleOptionSelect()}
            >
              Logout
            </li>
          </ul>
        )}

        <div className="hidden lg:flex lg:flex-row justify-between w-auto">
          <div className="flex flex-row px-5 pt-2">
            <button onClick={goToFreeCourse} className={`border-2 border-secondary text-secondary text-sm rounded-[5px] px-5 xl:h-10 mr-5 ${path === "/free-course/Machine-learning-fundamentals" ? "border-b-4" : ""}`}>
              Free Certification Course
            </button>
            <ul className="flex flex-row justify-between space-x-5">
              {navLinks.map((link, i) => (
                <Link key={i} to={link.path}>
                  <li
                    className={` ${
                      link.active
                        ? "bg-primary text-white rounded-[5px] px-2"
                        : ""
                    } p-1 hover:bg-primary hover:text-white hover:rounded-[5px] hover:px-2`}
                  >
                    {link.title}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="flex flex-col justify-center">
            {isLogin ? (
              <ProfileDropdown handleLogout={handleLogout} />
            ) : (
              <>
                  <Button active onClick={togglePopup}>
                    Login/SignUp
                  </Button>
                  {showLoginPopup && (
                    <Login
                      showLoginPopup={showLoginPopup}
                      setShowLoginPopup={setShowLoginPopup}
                      setShowForgotPasswordPopup={setShowForgotPasswordPopup}
                      setIsLogin={setIsLogin}
                      setShowSignupPopup={setShowSignupPopup}
                    />
                  )}
                  {showForgotPasswordPopup && (
                    <ForgotPassword
                      active={showForgotPasswordPopup}
                      setShowForgotPasswordPopup={setShowForgotPasswordPopup}
                    />
                  )}
                  {showSignupPopup && (
                    <SignUp
                      showSignupPopup={showSignupPopup}
                      setShowSignupPopup={setShowSignupPopup}
                      setIsLogin={setIsLogin}
                    />
                  )}
                </>
            )}
          </div>
        </div>
      </div>
      {isMobileMenuVisible && (
        <div className="lg:hidden">
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <MobileMenu isOpen={isMobileMenuVisible} onClose={toggleMobileMenu} navLinks={navLinks} isLogin={isLogin} togglePopup={togglePopup} setIsLogin={setIsLogin} />
          </div>
        </div>
      )}
    </nav>


  );
};

export default Navbar;

const AlumniCompanyTags = ({ companylogos }) => {
  return (
    <div className="flex flex-row justify-evenly lg:w-[2000px]">
      {companylogos.map((logo, i) => (
        <div key={i} className="flex flex-col justify-center py-5 m-2">
          <div>
            <img src={logo.logo} alt={logo.name} className="w-full h-full object-contain" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AlumniCompanyTags;

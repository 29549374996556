import Button from "../../components/ui/Button";
import Heading from "../../components/ui/Heading";
import ProgramDescriptionCard from "../../components/ui/cards/ProgramDescriptionCard";
import InstructorSlider from "../../components/ui/sliders/InstructorSlider";
import LearnerReviewSlider from "../../components/ui/sliders/LearnerReviewSlider";
import FeatureUI from "../../components/ui/extras/FeatureUI";
import CertificateSvg from "../../assets/svgs/CertificateSvg";
import SwirlArrowRightSvg from "../../assets/svgs/SwirlArrowRightSvg";
import Collapsible from "../../components/ui/collapsible/Collapsible";
import * as data2 from "../../data/SurgeCourses";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/ui/Loader";
import { Helmet } from "react-helmet-async";

const FreeCoursePage = () => {
    let { course_code } = useParams();
    const navigate = useNavigate();

    const [course, setCourse] = useState({
        id: null,
        title: null,
    });
    const [loading, setLoading] = useState(true);

    const getCourseByCode = (code) => {
        const data = data2.FreeProgram.find((program) => {
            return program.code === code;
        });
        return data;
    };

    useEffect(() => {
        const courseData = getCourseByCode(course_code);

        if (courseData) {
            setCourse(courseData);
            setLoading(false);
        } else {
            navigate("/course-not-found");
        }
    }, [course_code, navigate]);


    const goToSkillbox = () => {
        window.open("https://skillbox.surgeclasses.com/enroll", "_blank", "noopener,noreferrer");
    };

    return (
        <>
            {loading ? (
                <div className="flex flex-col justify-center items-center h-screen">
                    <Loader />
                </div>
            ) : (
                <>
                    {(!course || course.id == null) && <div>Not Found</div>}
                    {course && course.id !== null && (
                        <div className="home-page bg-white" key={course.id}>
                            <Helmet>
                                <title>{course.title} | Surge Classes</title>
                                <meta name="description" content={course.description} />
                            </Helmet>
                            <div className="flex flex-row justify-center">
                                <div className="flex flex-col items-center text-center lg:text-left lg:flex-row lg:justify-between p-5 w-full lg:max-w-[1460px]">
                                    <div className="p-3 lg:p-5 lg:pl-10 w-[100%] lg:w-[70%]">
                                        <div>
                                            <h1 className="font-inter py-3 md:py-5 lg:text-5xl md:text-4xl text-2xl font-bold text-primary text-center lg:text-left">
                                                {course.title}
                                            </h1>
                                            <div className="font-inter text-base md:text-xl text-black font-bold">
                                                {course.tag}
                                            </div>
                                            <div className="py-3">
                                                <div className="py-3">{course.description}</div>
                                            </div>
                                        </div>
                                        <div className="flex lg:flex-row items-center flex-col py-2 md:py-5">
                                            <div className="flex flex-row px-3 md:p-0 ml-5 mb-3 lg:mb-0 lg:ml-0">
                                                <Button
                                                    active
                                                    onClick={goToSkillbox}
                                                >
                                                    Enroll Now
                                                </Button>
                                            </div>
                                            <div className="flex flex-col justify-center">
                                                <div className="font-inter text-xs mb-3 md:mb-0 lg:text-base text-black font-bold">
                                                    Enroll and Start Learning for Free
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-end items-center w-[100%] lg:w-[43%] h-full pb-0 lg:pb-10 pr-0 xl:pr-20">
                                        <div className="flex flex-col justify-center items-center mt-3 border-2 rounded-md p-5 w-11/12">
                                            <div className="font-inter text-2xl text-primary font-bold text-center">
                                                Free course with certification
                                            </div>
                                            <div className="font-inter text-sm lg:text-base text-primary font-bold bg-[#A6E1FFE5] rounded-md p-5 w-3/4 text-left my-4">
                                                <ul className="list-disc list-outside pl-5">
                                                    <li>Learn about machine learning fundamentals with our free course.</li>
                                                    <li>Free certificate after course completion.</li>
                                                </ul>
                                            </div>
                                            <div className="ml-5">
                                                <Button
                                                    active
                                                    width="w-40"
                                                    onClick={goToSkillbox}
                                                >
                                                    Join Now
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row justify-center">
                                <div className="flex flex-col mb-4 mx-1 md:mx-14 w-full max-w-[1460px]">
                                    <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                                        <Heading>Overview</Heading>
                                    </div>
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="border-4 rounded-lg bg-[#EEF9FF] mb-1 mt-5 w-full">
                                            <div className="flex flex-row justify-evenly">
                                                {course.course_overview.map((overview, i) => (
                                                    <div
                                                        key={i}
                                                        className={`flex flex-col justify-center ${i === 1 ? "border-x-4" : ""
                                                            } text-center w-[33.3%] p-2 md:p-5 lg:p-10`}
                                                    >
                                                        <div className="font-inter text-sm md:text-base break-words lg:text-xl text-black font-bold hyphens-auto">
                                                            {overview.title}
                                                        </div>
                                                        <div className="font-inter text-xs lg:text-lg text-black">
                                                            {overview.description}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row justify-center">
                                <div className="flex flex-col my-4 lg:ml-24 xl:mx-14 w-full max-w-[1460px]">
                                    <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                                        <Heading>Program description</Heading>
                                    </div>
                                    <div className="font-inter text-base text-[#6F6F6F] w-[80%] hidden lg:block">
                                        {course.about_program.description}
                                    </div>
                                    <div className="mb-1 lg:mt-20 mt-5 w-[100%]">
                                        <div className="flex flex-row xl:justify-evenly justify-start xl:overflow-auto overflow-scroll no-scrollbar w-full lg:pr-[50px] px-0">
                                            {course.about_program.features.map((feature, i) => (
                                                <ProgramDescriptionCard feature={feature} key={i} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="flex flex-row justify-center">
                                <div className="flex flex-col my-2 md:my-10 lg:ml-12 xl:mx-14 w-full max-w-[1460px]">
                                    <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                                        <Heading>Syllabus</Heading>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="font-inter text-center lg:text-left text-base text-black w-[100%] lg:w-[80%]">
                                            {course.syllabus.description}
                                        </div>
                                        <div className="ml-2 lg:ml-0 mb-1 mt-10 w-[100%]">
                                            <div className="grid grid-cols-2 md:grid-cols-3 gap-5 lg:gap-10">
                                                {course.syllabus.content.map((topic, i) => (
                                                    <div key={i} className="flex flex-col w-[190px] md:w-full">
                                                        <div className="cursor-pointer flex flex-col justify-center text-center bg-primary p-4 font-inter text-sm md:text-md xl:text-lg  text-white font-bold">
                                                            {topic.title}
                                                        </div>
                                                        <div className="bg-[#EEF9FF] text-black text-sm lg:text-md text-center py-2 lg:py-5 px-2 lg:px-10 h-[110px]">
                                                            {topic.description}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col mt-10 p-2">
                                <div className="flex flex-col my-4 mx-5 md:mx-10 xl:mx-14 w-full max-w-[1460px]">
                                    <div className="flex flex-row justify-center text-center lg:text-left lg:justify-start">
                                        <Heading>Earn Certificate</Heading>
                                    </div>
                                    <div className="font-inter text-center lg:text-left text-base w-[75%]">
                                        On successful completion of program, you will receive a
                                        verified certificate from surge classes
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col mb-10 p-2 lg:p-10 lg:pb-0 bg-[#E1F5FF]">
                                <div className="flex flex-row justify-center">
                                    <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between justify-start mt-2 mx-1 lg:mx-14 w-full max-w-[1360px] ">
                                        {/* <div className="flex flex-col justify-center lg:items-start items-center lg:justify-start">
                                            <div className="flex flex-row justify-center text-center lg:text-left lg:justify-start">
                                                <Heading>Earn Certificate</Heading>
                                            </div>
                                            <div className="font-inter text-center lg:text-left text-base w-[75%]">
                                                On successful completion of program, you will receive a
                                                verified certificate from surge classes
                                            </div>
                                        </div> */}
                                        <div className="hidden lg:flex lg:flex-row gap-6 justify-center items-center">
                                            <CertificateSvg />
                                            <SwirlArrowRightSvg />
                                        </div>
                                        <img
                                            src={course.course_certificate}
                                            alt="certificate"
                                            className="w-[300px] lg:w-[42%] my-5 lg:my-0"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row justify-center">
                                <div className="flex flex-col my-4 mx-5 md:mx-10 xl:mx-14 w-full max-w-[1460px]">
                                    <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                                        <Heading>Program Features</Heading>
                                    </div>
                                    <div className="mb-1 mt-5 w-[100%]">
                                        <FeatureUI features={course.p_feature} />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col items-center mt-10">
                                <div className="flex flex-row justify-center">
                                    <Heading>Instructors for course</Heading>
                                </div>
                                <div className="flex flex-row justify-center py-3 p-6 lg:px-[150px] text-center lg:max-w-[1450px] lg:text-base text-sm">
                                    Learn from distinguished industry experts in the realm of{" "}
                                    {course.title}, comprising top organizations and renowned
                                    academicians in the technology domain.
                                </div>
                                <InstructorSlider instructorDetails={course.instructor} />
                            </div>

                            <div className="flex flex-row justify-center">
                                <div className="flex flex-col my-0 lg:my-10 w-full max-w-[1440px]">
                                    <div className="flex flex-row justify-center">
                                        <Heading>Learner Reviews</Heading>
                                    </div>
                                    <LearnerReviewSlider
                                        learnerDetails={course.learners_review}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-row justify-center">
                                <div className="flex flex-col text-center lg:text-start my-10 mx-2 md:mx-14 lg:mx-20 w-full max-w-[1460px]">
                                    <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                                        <Heading>Frequently Asked Questions</Heading>
                                    </div>
                                    <div className="mb-1 mt-5">
                                        <div className="flex flex-col justify-start">
                                            {course.faqs.map((faq, i) => (
                                                <Collapsible faq={faq} key={i} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default FreeCoursePage;

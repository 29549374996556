import { useEffect, useState } from "react";

const CompanyTags = ({ hiringCompanies }) => {
  const [rows, setRows] = useState([]);



  useEffect(() => {
    const updateRows = () => {
      let itemsPerRow;
      if (window.innerWidth <= 768) { // Adjust for tablet and phone screens
        itemsPerRow = Math.ceil(window.innerWidth / 120);
      } else {
        itemsPerRow = Math.ceil(window.innerWidth / 270);
      }
      const rows = [];
      const hiringCompaniesArray = Object.values(hiringCompanies[0]);

      for (let i = 0; i < hiringCompaniesArray.length; i += itemsPerRow) {
        rows.push(hiringCompaniesArray.slice(i, i + itemsPerRow));
      }

      setRows(rows);
    }

    window.addEventListener("resize", () => {
      updateRows();
    });

    updateRows();

    return () => {
      window.removeEventListener("resize", () => { });
    }
  }, [hiringCompanies]);

  return (
    <div className="flex flex-row justify-center ">
      <div className="flex flex-col justify-center ">
        {rows.map((row, rowIndex) => (
          <div className="flex gap-4" key={rowIndex}>
            {row.map((hiringCompany, columnIndex) => (
              <div
                key={columnIndex}
                className="flex flex-col items-center bg-[#E5F6FF] rounded-[10px] justify-center lg:h-[100px] lg:w-[200px]  my-3"
              >
                <div className="py-3 px-4 h-10 w-20 lg:w-40 lg:h-28">
                  <picture>
                    <source srcSet={hiringCompany} media="(min-width: 1024px)" />
                    <img src={hiringCompany} alt="company" className="w-full h-full object-contain" />
                  </picture>
                </div>

              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyTags;

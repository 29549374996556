import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="bg-white py-6 sm:py-10">
            <div className="container mx-auto px-4">
                <div className="bg-gray-100 rounded-lg shadow-md p-4 sm:p-8 max-w-md mx-auto">
                    <h1 className="text-2xl sm:text-3xl font-bold text-primary text-center mb-4">
                        Privacy Policy
                    </h1>
                    <p className="text-gray-700 text-base sm:text-lg mb-4">
                        Your privacy is important to us. We respect your privacy regarding any information we collect from you on our website.
                    </p>
                    <p className="text-gray-700 text-base sm:text-lg mb-4">
                        We collect only the necessary personal information needed to provide our services. Your information is kept secure and is never shared with third parties.
                    </p>
                    <p className="text-gray-700 text-base sm:text-lg mb-4">
                        By using our website, you agree to our privacy practices. You can request access to or deletion of your data at any time.
                    </p>
                    <p className="text-gray-700 text-base sm:text-lg">
                        If you have any questions about our Privacy Policy, please contact us.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;

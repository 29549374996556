import logo from "../assets/main/logo.svg";
import Button from "../components/ui/Button";
import Heading from "../components/ui/Heading";
import ProgramDescriptionCard from "../components/ui/cards/ProgramDescriptionCard";
import PlacedStudentCard from "../components/ui/cards/PlacedStudentCard";
import InstructorSlider from "../components/ui/sliders/InstructorSlider";
import ProjectSlider from "../components/ui/sliders/ProjectSlider";
import LearnerReviewSlider from "../components/ui/sliders/LearnerReviewSlider";
import AlumniCompanyTags from "../components/ui/tags/AlumniCompanyTags";
import FeatureUI from "../components/ui/extras/FeatureUI";
import Collapsible from "../components/ui/collapsible/Collapsible";
import * as data2 from "../data/SurgeCourses";
import * as data from "../data/Main";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import SignUp from "../components/auth/SignUp";
import CourseEnroll from "../components/enroll/CourseEnroll";
import Popup from "../components/ui/popup/Popup";
import RazorpayPaymentModule from "../components/payment/RazorpayPaymentModule";
import { useNavigate } from "react-router-dom";
import toastMixin from "../utils/toastMixin";
import { useHttp } from "../hooks/useHttp";
import Loader from "../components/ui/Loader";
import ComingSoon from "../components/ComingSoon";
import { Helmet } from "react-helmet-async";
import DiscountSvg from "../assets/svgs/DiscountSvg";
import PaymentSuccessSvg from "../assets/svgs/PaymentSuccessSvg";
import PaymentFailedSvg from "../assets/svgs/PaymentFailedSvg";
import SyllabusDropdown from "../components/ui/dropdown/SyllabusDropdown";

const CoursePage = ({ isLogin, setIsLogin }) => {
  const { response, apiCall } = useHttp();
  let { course_code } = useParams();
  const { search } = useLocation();
  const referral_code = new URLSearchParams(search).get("referral_code");
  const navigate = useNavigate();

  const [course, setCourse] = useState({
    id: null,
    title: null,
  });
  const [loading, setLoading] = useState(true);
  const [reloadCourseState, setReloadCourseState] = useState(false);

  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isPaidEnrolled, setIsPaidEnrolled] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);

  const paymentStatus = {
    captured:
      "Your Payment was successful ✓. Please also check your email for the payment receipt and skillbox login credentials, or you can click on the button below to login to your skillbox account directly and access the course. If you find any issues, please contact us at +919390249772",
    failed:
      "Your last payment was failed. If you have already paid, please contact us at +919390249772 , else you can try again by clicking on the button below.",
  };

  const getCourseByCode = (code) => {
    const data = data2.SurgePrograms.find((program) => {
      return program.code === code;
    });
    return data;
  };

  const calculateDeadline = (isMonthOnly) => {
    const today = new Date();
    const deadline = new Date(today);

    if (isMonthOnly) {
      deadline.setMonth(today.getMonth() + 3);
      const options = { month: "short", year: "numeric" };
      return deadline.toLocaleDateString(undefined, options);
    } else {
      deadline.setDate(today.getDate() + 15);
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      return deadline.toLocaleDateString(undefined, options);
    }
  };

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isLogin) {
      const getMyCourses = async () =>
        await apiCall({
          path: "/my-courses",
          method: "GET",
          body: null,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      getMyCourses();
    } else {
      setIsEnrolled(false);
      setIsPaidEnrolled(false);
    }
  }, [apiCall, isLogin, reloadCourseState]);

  useEffect(() => {
    if (response && response.courses) {
      const enrolledCourses = response.courses.unpaid_courses || [];
      const isEnrolled = enrolledCourses.some((_course) => {
        return _course.course_id === course.id;
      });
      setIsEnrolled(isEnrolled);

      const paidCourses = response.courses.paid_courses || [];
      const enrolledPaidCourses = paidCourses.filter(
        (course) => course.course_payment_status === "captured"
      );
      const notEnrolledPaidCourses = paidCourses.filter(
        (course) => course.course_payment_status === "failed"
      );
      const isPaidEnrolled = enrolledPaidCourses.some((_course) => {
        return _course.course_id === course.id;
      });
      setIsPaidEnrolled(isPaidEnrolled);

      const isPaymentFailed = notEnrolledPaidCourses.some((_course) => {
        return _course.course_id === course.id;
      }
      );
      setIsPaymentFailed(isPaymentFailed);
    }
  }, [course.id, response, reloadCourseState]);

  useEffect(() => {
    const courseData = getCourseByCode(course_code);

    if (courseData) {
      setCourse(courseData);
      setSelectedTopic(courseData.syllabus[0]);
      setSelectedSubTopic(courseData.syllabus[0].topics[0]);
      setLoading(false);
    } else {
      navigate("/course-not-found");
    }
  }, [course_code, navigate]);

  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedSubTopic, setSelectedSubTopic] = useState();
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [showEnrollPopup, setShowEnrollPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);

  const handleSelectedTopic = (topic) => {
    setSelectedTopic(topic);
    setSelectedSubTopic(topic.topics[0]);
  };

  const handleSelectedSubTopic = (subtopic) => {
    setSelectedSubTopic(subtopic);
  };

  const toggleSignUpPopup = () => {
    setShowSignUpPopup(!showSignUpPopup);
    toastMixin({}).fire({
      icon: "info",
      title: "Sign up or Login first to download syllabus",
    });
  };

  const toggleEnrollPopup = () => {
    setShowEnrollPopup(!showEnrollPopup);
  };

  const handleChatWithUs = () => {
    navigate("/contact");
  };

  const handleEnrollClick = () => {
    if (isLogin) {
      const id_token = localStorage.getItem("token");
      const enrollData = {
        course_id: course.id,
      };
      apiCall({
        path: "/auth-enroll/course",
        method: "POST",
        data: enrollData,
        headers: {
          Authorization: `Bearer ${id_token}`,
        },
      });
      setIsEnrolled(true);
    } else {
      toggleEnrollPopup();
    }
  };

  const handlePayentClick = () => {
    setShowPaymentPopup(!showPaymentPopup);
  };

  const gotoCoursesHandler = () => {
    localStorage.setItem("scrollToCourses", true);
    navigate("/");
  };

  const splitDescriptionByDot = (description) => {
    const descriptionArray = description.split(".");
    return descriptionArray;
  };

  function getFirstWord(sentence) {
    const match = sentence.match(/\S+/);

    if (match) {
      return match[0];
    } else {
      return sentence;
    }
  }

  function convertSlashNToBr(str) {
    return str.split("\n").map((item, i) => <p key={i}>{item}</p>);
  }

  return (
    <>
      {loading ? (
        <div className="flex flex-col justify-center items-center h-screen">
          <Loader />
        </div>
      ) : (
        <>
          {(!course || course.id == null) && <div>Not Found</div>}
          {course && course.id !== null && (
            <div className="home-page bg-white" key={course.id}>
              <Helmet>
                <title>{course.title} | Surge Classes</title>
                <meta name="description" content={course.description} />
              </Helmet>
              <div className="flex flex-row justify-center">
                <div className="flex flex-col items-center text-center lg:text-left lg:flex-row lg:justify-between p-5 w-full lg:max-w-[1460px]">
                  <div className="p-3 lg:p-5 lg:pl-10 w-[100%] lg:w-[80%]">
                    <div>
                      <h1 className="font-inter py-3 md:py-5 lg:text-5xl md:text-4xl text-2xl font-bold text-primary text-center lg:text-left">
                        {convertSlashNToBr(course.title)}
                      </h1>
                      {course.discount && !isPaidEnrolled ? (
                        <div className="flex flex-row justify-center lg:justify-start lg:max-w-[150px]">
                          <DiscountSvg />
                        </div>
                      ) : (
                        <div className="font-inter text-base md:text-xl text-black font-bold">
                          {course.tag}
                        </div>
                      )}
                      {course.type === "Campus Program" ? (
                        <div className="py-3">
                          {splitDescriptionByDot(course.description).map(
                            (description, i) => {
                              const firstWord = getFirstWord(description);
                              const restOfDescription = description
                                .substring(firstWord.length + 1)
                                .trim();

                              return (
                                <div key={i} className="py-1.5 md:py-2">
                                  <span className="font-inter text-sm md:text-base text-primary font-bold">
                                    {firstWord}{" "}
                                  </span>
                                  <span className="text-sm md:text-base">
                                    {restOfDescription}
                                  </span>
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div className="py-3">
                          {course.id === 5 ? (
                            <ul
                              className="marker:text-primary list-disc list-inside lg:list-outside ml-3"
                              dangerouslySetInnerHTML={{
                                __html: course.description,
                              }}
                            />
                          ) : (
                            <div className="py-3">{course.description}</div>
                          )}
                        </div>
                      )}
                    </div>
                    {course.type === "Self Paced Program" ? (
                      <div className="flex flex-row justify-center lg:justify-start py-2 lg:py-5">
                        {course.id === 5 || course.id === 7 ? (
                          <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start">
                            {isPaidEnrolled && (
                              <div className="bg-[#C3FFDF] rounded-md p-2 mb-5 flex flex-row font-inter text-xs md:text-sm text-green-600 font-bold whitespace-pre-wrap">
                                <div className="mr-1">
                                  <PaymentSuccessSvg />
                                </div>
                                {paymentStatus.captured}
                              </div>
                            )}
                            {isPaymentFailed && (
                              <div className="bg-[#FFDAD5] rounded-md p-2 mb-5 flex flex-row font-inter text-xs md:text-sm text-[#FA1B00] font-bold whitespace-pre-wrap">
                                <div className="mr-0.5 lg:py-1 lg:mr-1">
                                  <PaymentFailedSvg />
                                </div>
                                {paymentStatus.failed}
                              </div>
                            )}
                            <RazorpayPaymentModule
                              isLogin={isLogin}
                              course_id={course.id}
                              setIsLogin={setIsLogin}
                              isPaidEnrolled={isPaidEnrolled}
                              setReloadCourseState={setReloadCourseState}
                              referral_code={referral_code}
                              payment_page_info={course.payment_page_info}
                              toggleSignUpPopup={toggleSignUpPopup}
                              syllabus_download_link={course.syllabus_download_link}
                            />
                          </div>
                        ) : (
                          <ComingSoon />
                        )}
                      </div>
                    ) : (
                      <div className="flex lg:flex-row items-center flex-col py-2 md:py-5">
                        <div className="flex flex-row px-3 md:p-0 ml-5 mb-3 lg:mb-0 lg:ml-0">
                          <Button
                            active
                            disabled={isEnrolled}
                            onClick={handleEnrollClick}
                          >
                            {isEnrolled ? "Registered" : "Register Now"}
                            {isEnrolled && <span className="ml-2">✓</span>}
                          </Button>
                          {course.code !== "Data-structure-and-algorithm" && (
                            <>
                              {isLogin ? (
                                <>
                                  <a
                                    href={course.syllabus_download_link}
                                    download
                                  >
                                    <Button outline width="w-40">
                                      Download Syllabus
                                    </Button>
                                  </a>
                                </>
                              ) : (
                                <>
                                  <Button
                                    outline
                                    width="w-40"
                                    onClick={toggleSignUpPopup}
                                  >
                                    Download Syllabus
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                          {showEnrollPopup && (
                            <Popup close={toggleEnrollPopup}>
                              <CourseEnroll
                                close={toggleEnrollPopup}
                                course_id={course.id}
                              />
                            </Popup>
                          )}
                          {showSignUpPopup && (
                            <SignUp
                              showSignupPopup={showSignUpPopup}
                              setShowSignupPopup={setShowSignUpPopup}
                              setIsLogin={setIsLogin}
                            />
                          )}
                        </div>
                        <div className="flex flex-col justify-center">
                          <div className="font-inter text-xs mb-3 md:mb-0 lg:text-base text-black font-bold">
                            The deadline to enroll is {calculateDeadline(false)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {course.cover && (
                    <div className="flex flex-col justify-center items-center w-[50%]">
                      <div className="pr-0 lg:pt-4 lg:pr-20">
                        <img src={course.cover} alt="cover-vector" />
                      </div>
                    </div>
                  )}
                  {(course.id === 5 || course.id === 7) && (
                    <div className="flex flex-col justify-end items-center w-[100%] lg:w-[43%] h-full pb-0 lg:pb-14 pr-0 xl:pr-20">
                      {!isPaidEnrolled && (
                        <div className="flex flex-col justify-center items-center mt-3 border-2 rounded-md p-5 w-11/12">
                          <div className="font-inter text-2xl text-primary font-bold">
                            Fee Structure
                          </div>
                          <div className="font-inter text-base lg:text-xl text-black my-4">
                            Rs. {course.fees} /- Including GST*
                          </div>
                          <div className="bg-primary text-base lg:text-xl text-white font-bold rounded-t-md p-3 w-3/4 text-center">
                            50% Discount
                          </div>
                          <div className="font-inter text-sm lg:text-base text-primary font-bold bg-[#A6E1FFE5] rounded-b-md p-2 w-3/4 text-center mb-2">
                            Special Price : Rs. {course.fees * (1 - course.discount / 100)}/- Including GST*
                          </div>
                          <div className="ml-5">
                            <RazorpayPaymentModule
                              isLogin={isLogin}
                              course_id={course.id}
                              setIsLogin={setIsLogin}
                              isPaidEnrolled={isPaidEnrolled}
                              setReloadCourseState={setReloadCourseState}
                              payment_page_info={course.payment_page_info}
                              referral_code={referral_code}
                              isExtra={false}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {course.type === "Campus Program" ? (
                <div className="flex flex-col items-center">
                  <div className="flex flex-row justify-center w-[95%]  max-w-[1540px] bg-primary text-white py-6 lg:px-[100px] px-8 text-center sm:text-xs md:text-base mt-3">
                    {course.description_second}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="flex flex-row justify-center">
                <div className="flex flex-col mb-4 mx-1 md:mx-14 w-full max-w-[1460px]">
                  <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                    <Heading>Overview</Heading>
                  </div>
                  <div className="flex flex-row justify-center items-center">
                    <div className="border-4 rounded-lg bg-[#EEF9FF] mb-1 mt-5 w-full">
                      <div className="flex flex-row justify-evenly">
                        {course.course_overview.map((overview, i) => (
                          <div
                            key={i}
                            className={`flex flex-col justify-center ${i === 1 ? "border-x-4" : ""
                              } text-center w-[33.3%] p-2 md:p-5 lg:p-10`}
                          >
                            <div className="font-inter text-sm md:text-base break-words lg:text-xl text-black font-bold hyphens-auto">
                              {overview.title}
                            </div>
                            <div className="font-inter text-xs lg:text-lg text-black">
                              {overview.description}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-center">
                <div className="flex flex-col my-4 lg:ml-24 xl:mx-14 w-full max-w-[1460px]">
                  <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                    <Heading>Program description</Heading>
                  </div>
                  <div className="font-inter text-base text-[#6F6F6F] w-[80%] hidden lg:block">
                    {course.about_program.description}
                  </div>
                  <div className="mb-1 lg:mt-20 mt-5 w-[100%]">
                    <div className="flex flex-row xl:justify-evenly justify-start xl:overflow-auto overflow-scroll no-scrollbar w-full lg:pr-[50px] px-0">
                      {course.about_program.features.map((feature, i) => (
                        <ProgramDescriptionCard feature={feature} key={i} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {course.id === 5 && (
                <div className="flex flex-row justify-center">
                  <div className="flex flex-col my-4 mx-5 md:mx-10 xl:mx-14 w-full max-w-[1460px]">
                    <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                      <Heading>Program Features</Heading>
                    </div>
                    <div className="mb-1 mt-5 w-[100%]">
                      <FeatureUI features={course.p_feature} />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-row justify-center">
                <div className="flex flex-col my-2 md:my-10 lg:mx-14 w-full max-w-[1460px]">
                  <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                    <Heading>Syllabus</Heading>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="hidden lg:block border-4 rounded-lg ml-2 lg:ml-0 mb-1 mt-5 w-[100%]">
                      <div className="flex flex-row justify-start xl:justify-evenly xl:overflow-auto overflow-x-scroll no-scrollbar">
                        {course.syllabus.map((topic, i) => (
                          <div
                            key={i}
                            className={`min-w-[200px] w-[200px] lg:w-full cursor-pointer flex flex-col justify-center text-center border-r-4 hover hover:bg-primary p-5 font-inter hover:text-white text-sm md:text-lg xl:text-xl  text-black font-bold ${selectedTopic.id === topic.id &&
                              " bg-primary text-white"
                              }`}
                            onClick={() => handleSelectedTopic(topic)}
                          >
                            {topic.title}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col items-center lg:hidden">
                      <SyllabusDropdown 
                        syllabus={course.syllabus}
                        setSelectedTopic={setSelectedTopic}
                        setSelectedSubTopic={setSelectedSubTopic}
                        selectedTopic={selectedTopic}
                      />
                    </div>
                    {selectedTopic && (
                      <div className="flex flex-row justify-center lg:justify-start px-3 lg:text-left text-center py-3 text-xs md:text-sm xl:text-base my-5 ">
                        {selectedTopic.description}
                      </div>
                    )}
                    <div className="flex flex-row justify-start px-2">
                      <div className="flex flex-col w-[40%] md:w-[30%]">
                        {selectedTopic &&
                          selectedTopic.topics.map((subtopic, i) => (
                            <div
                              key={i}
                              className={`cursor-pointer p-4 text-xs md:text-base lg:text-xl font-inter hover:text-[#004C73] hover:font-bold${selectedSubTopic.id === subtopic.id
                                ? "text-[#004C73] font-bold border-0 bg-[#EEF9FF]"
                                : "border-2 text-[#303030]"
                                }`}
                              onClick={() => handleSelectedSubTopic(subtopic)}
                            >
                              {subtopic.title}
                            </div>
                          ))}
                      </div>
                      {selectedSubTopic && (
                        <div className="flex flex-row text-xs md:text-base w-[60%] md:w-[70%] bg-[#EEF9FF] p-5">
                          {selectedSubTopic.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {course.id === 5 && (
                <>
                  <div className="flex flex-row justify-center">
                    <div className="flex flex-col mt-10 mb-10 lg:ml-24 xl:mx-14 w-full max-w-[1460px]">
                      <div className="flex flex-row justify-center lg:justify-start text-center lg:text-left ml-0 lg:ml-10">
                        <Heading>Explore our top projects</Heading>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center">
                    <ProjectSlider projectDetails={course.projects} />
                  </div>
                </>
              )}

              <div className="flex flex-col mt-4">
                <div className="flex flex-row justify-center mt-4">
                  <Heading>Skills you will gain</Heading>
                </div>
                <div className="border-2 shadow shadow-[#00000033] mb-1 mt-5 w-full">
                  <div className="flex flex-row justify-center">
                    <div className="flex flex-row justify-center w-full max-w-[1460px]">
                      <div className="grid grid-rows-3 grid-flow-col w-full place-items-center py-5">
                        {course.skills_learned.map((logo, i) => (
                          <div
                            key={i}
                            className="flex flex-col justify-centers items-center"
                          >
                            <div className="w-16 h-16 lg:w-32 lg:h-32 py-5">
                              <img
                                src={logo}
                                alt="skill"
                                className="w-full h-full object-contain"
                              />
                            </div>
                            <div className="font-inter text-xs lg:text-xl text-primary py-0 lg:py-2 font-bold">
                              {
                                logo
                                  .split("/")
                                [logo.split("/").length - 1].split(".")[0]
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center mt-10">
                <div className="flex flex-row justify-center">
                  <Heading>Instructors for course</Heading>
                </div>
                <div className="flex flex-row justify-center py-3 p-6 lg:px-[150px] text-center lg:max-w-[1450px] lg:text-base text-sm">
                  Learn from distinguished industry experts in the realm of{" "}
                  {course.title}, comprising top organizations and renowned
                  academicians in the technology domain.
                </div>
                <InstructorSlider instructorDetails={course.instructor} />
              </div>

              <div className="flex flex-col my-10 p-2 lg:p-10 bg-[#E1F5FF]">
                <div className="flex flex-row justify-center">
                  <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between justify-start mt-2 mx-1 lg:mx-14 w-full max-w-[1360px] ">
                    <div className="flex flex-col justify-center lg:items-start items-center lg:justify-start">
                      <div className="flex flex-row justify-center text-center lg:text-left lg:justify-start">
                        <Heading>Earn Certificate</Heading>
                      </div>
                      <div className="font-inter text-center lg:text-left text-base w-[75%]">
                        On successful completion of program, you will receive a
                        verified certificate from surge classes
                      </div>
                    </div>
                    {course.course_certificate ? (
                      <img
                        src={course.course_certificate}
                        alt="certificate"
                        className="w-[300px] lg:w-[42%] my-5 lg:my-0"
                      />
                    ) : (
                      <div className="flex flex-col justify-evenly bg-white rounded-md items-center my-5 xl:my-0 p-3 py-7 xl:p-10 w-[300px] lg:w-[50%]">
                        <div className="flex flex-row justify-center w-[50%]">
                          <img src={logo} alt="surge_logo" />
                        </div>
                        <div className="font-inter text-xs xl:text-base text-black py-2">
                          This certificate is awarded to
                        </div>
                        <div className="font-inter text-xs xl:text-base text-black italic font-thin py-2">
                          Name
                        </div>
                        <div className="font-inter text-xs xl:text-base text-black py-2">
                          for successfully completing
                        </div>
                        <div className="font-inter text-sm xl:text-xl text-primary text-center font-bold">
                          {course.title}
                        </div>
                        <div className="font-inter text-xs xl:text-base text-black italic font-thin py-2">
                          {calculateDeadline(true)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {course.id === 5 && (
                <div className="flex flex-row justify-center mx-0 lg:mx-24">
                  <div className="flex flex-col my-0 lg:my-10 w-full max-w-[1440px]">
                    <div className="flex flex-row justify-center text-center">
                      <Heading>Students Placed from Surgeclasses</Heading>
                    </div>
                    <div className="flex flex-row justify-center my-5 w-full lg:max-w-[1540px]">
                      <div className="grid grid-rows-2 grid-flow-col overflow-scroll no-scrollbar snap-center">
                        {data.alumniDetails.map((alumniDetail, i) => (
                          <PlacedStudentCard
                            key={i}
                            placedStudentDetails={alumniDetail}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-row justify-center">
                <div className="flex flex-col my-0 lg:my-10 w-full max-w-[1440px]">
                  <div className="flex flex-row justify-center">
                    <Heading>Learner Reviews</Heading>
                  </div>
                  <LearnerReviewSlider
                    learnerDetails={course.learners_review}
                  />
                </div>
              </div>

              {course.id === 5 && (
                <div className="flex flex-col">
                  <div className="flex flex-row justify-center text-center mt-4">
                    <Heading>Learn the best from the instructors of</Heading>
                  </div>
                  <div className="border-2 shadow shadow-[#00000033] mb-1 mt-5 ">
                    <div className="flex flex-row justify-center">
                      <AlumniCompanyTags
                        companylogos={data.alumniCompanyLogos}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-center py-3">
                    and many more...
                  </div>
                </div>
              )}

              <div className="flex flex-row justify-center">
                <div className="flex flex-col text-center lg:text-start my-10 mx-2 md:mx-14 lg:mx-20 w-full max-w-[1460px]">
                  <div className="flex flex-row justify-center lg:justify-start mt-2 ">
                    <Heading>Frequently Asked Questions</Heading>
                  </div>
                  <div className="mb-1 mt-5">
                    <div className="flex flex-col justify-start">
                      {course.faqs.map((faq, i) => (
                        <Collapsible faq={faq} key={i} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {(course.id === 5 || course.id === 7) ? (
                <div className="flex flex-col mt-10 p-10 shadow-inner">
                  <div className="flex flex-col lg:flex-row justify-center">
                    <div className="flex flex-col lg:flex-row lg:justify-between mt-2 mx-1 p-0 xl:px-20 w-full max-w-[1360px]">
                      <div className="flex flex-col items-start w-auto lg:w-1/2 border-2 rounded-xl p-5">
                        <div className="flex flex-col justify-start items-start mb-5">
                          <div className="flex flex-col md:flex-row justify-start w-full">
                            <div className="flex flex-col">
                              <div className="text-2xl md:text-4xl font-bold text-primary">
                                Fee Structure
                              </div>
                              <div className="font-inter text-lg md:text-xl py-2">
                                Rs. {course.fees} /-{" "}
                                {course.discount ? (
                                  <>Including GST*</>
                                ) : (
                                  <>+ GST*</>
                                )}
                              </div>
                            </div>
                            {course.discount && (
                              <div className="flex flex-row justify-center ml-0 md:ml-7">
                                <DiscountSvg />
                              </div>
                            )}
                          </div>
                          {course.discount && (
                            <div className="font-inter text-sm lg:text-lg p-3 px-5 text-primary font-bold mt-2 text-center bg-[#A6E1FFE5] rounded-lg w-auto">
                              Special Price : Rs.{" "}
                              {course.fees * (1 - course.discount / 100)} /-
                              Including GST*
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col items-center md:flex-row md:items-left justify-between w-full">
                          <div className="text-xl md:text-2xl text-center md:text-left font-bold text-primary my-2 md:my-0">
                            Still have queries? Contact Us
                          </div>
                          <Button
                            active
                            width="auto"
                            onClick={handleChatWithUs}
                          >
                            Chat with us
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-col justify-evenly rounded-xl items-center my-7 xl:my-0 p-3 py-7 xl:p-10 w-auto lg:w-[40%] w-2/11 border-2">
                        <div className="font-inter text-xs xl:text-base text-black font-bold">
                          Start learning at your own time
                        </div>
                        <div className="py-2 text-xs xl:text-base text-center lg:text-start">
                          Click on "Go to Courses" to see all of our popular
                          programs
                        </div>
                        <div className="flex flex-col items-center">
                          <div className="py-3">
                            <Button
                              active
                              disabled={isPaidEnrolled}
                              onClick={goToTop}
                            >
                              {isPaidEnrolled
                                ? "Enrolled"
                                : "Go To Pay Now"}
                              {isPaidEnrolled && (
                                <span className="ml-2">✓</span>
                              )}
                            </Button>
                          </div>
                          <div onClick={gotoCoursesHandler}>
                            <Button outline>Go to Courses</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col mt-10 p-10 bg-[#E1F5FF]">
                  <div className="flex flex-row justify-center">
                    <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between justify-start mt-2 mx-1 lg:mx-14 w-full max-w-[1360px] ">
                      <div className="flex flex-col justify-start">
                        {course.type !== "Campus Program" && (
                          <div className="flex flex-col justify-center lg:items-start items-center lg:justify-start mb-5">
                            <div className="flex flex-col lg:flex-row justify-center lg:justify-start">
                              <Heading>Fee Structure</Heading>
                              {course.discount && (
                                <div className="flex flex-row justify-center lg:justify-start lg:max-w-[150px] ml-0 lg:ml-5 lg:top-[-10px] top-0 my-3 lg:my-0">
                                  <DiscountSvg />
                                </div>
                              )}
                            </div>
                            <div className="font-inter text-xl">
                              Rs. {course.fees} /-{" "}
                              {course.discount ? (
                                <>Including GST*</>
                              ) : (
                                <>+ GST*</>
                              )}
                            </div>
                            {course.discount && (
                              <div className="font-inter text-sm lg:text-xl text-black font-bold mt-2 text-center lg:text-left">
                                Special Price : Rs.{" "}
                                {course.fees * (1 - course.discount / 100)} /-
                                Including GST* (Using referral code)
                              </div>
                            )}
                          </div>
                        )}
                        <div className="flex flex-col justify-center lg:items-start items-center lg:justify-start">
                          <div className="flex flex-row justify-start text-center lg:text-start">
                            <Heading>Still have queries? Contact Us</Heading>
                          </div>
                          <div>
                            <Button
                              active
                              width="w-60"
                              onClick={handleChatWithUs}
                            >
                              Chat with us
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-evenly bg-white rounded-md items-center my-7 xl:my-0 p-3 py-7 xl:p-10 w-[300px] lg:w-[40%]">
                        <div className="font-inter text-xs xl:text-base text-black font-bold">
                          Start learning at your own time
                        </div>
                        <div className="py-2 text-xs xl:text-base text-center lg:text-start">
                          Click on "Go to Courses" to see all of our popular
                          programs
                        </div>
                        <div className="flex flex-col items-center">
                          <div className="py-3">
                            {course.type === "Self Paced Program" ? (
                              <>
                                {course.id === 5 || course.id === 7 ? (
                                  <Button
                                    active
                                    disabled={isPaidEnrolled}
                                    onClick={goToTop}
                                  >
                                    {isPaidEnrolled
                                      ? "Enrolled"
                                      : "Go To Pay Now"}
                                    {isPaidEnrolled && (
                                      <span className="ml-2">✓</span>
                                    )}
                                  </Button>
                                ) : (
                                  <Button active disabled={true}>
                                    Coming Soon
                                  </Button>
                                )}
                              </>
                            ) : (
                              <Button
                                active
                                disabled={isEnrolled}
                                onClick={handleEnrollClick}
                              >
                                {isEnrolled ? "Registered" : "Register Now"}
                                {isEnrolled && <span className="ml-2">✓</span>}
                              </Button>
                            )}
                          </div>
                          <div onClick={gotoCoursesHandler}>
                            <Button outline>Go to Courses</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            </div>
          )}
        </>
      )}
    </>
  );
};

export default CoursePage;

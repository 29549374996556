import linkedin from "../../../assets/companylogos/linkedin.png";

const InstructorCard = ({ instructorDetail }) => {
  return (
    <div key={instructorDetail.id} className="flex flex-col">
      <div className="relative py-2">
        <div className="flex flex-row justify-center z-1 mb-[-36px] sm:mb-[-54px]">
          <div className="w-24 h-24 sm:w-32 sm:h-32 overflow-hidden rounded-full">
            <img src={instructorDetail.img} alt="xyz" className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="flex flex-col border-2 shadow-md rounded-[5%] w-[266px] md:w-[300px] lg:w-[350px] mx-3.5">
          <div className="p-3 sm:p-5 flex flex-col justify-end mt-5 sm:mt-10">
            <div className="font-inter text-lg sm:text-xl font-bold text-primary text-center">
              {instructorDetail.name}
            </div>
            <div className="font-inter text-sm sm:text-base text-black text-center">
              {instructorDetail.designation}
            </div>
            {instructorDetail.excompanies ? (
              <div className="flex flex-row justify-center py-3 sm:py-5">
                Ex.
                {Object.values(instructorDetail.excompanies).map((excompany, i) => (
                  <div key={i} className="max-h-6 sm:max-h-8 max-w-[15rem] sm:max-w-[20rem] px-2 sm:px-3">
                    <img
                      src={excompany}
                      alt="xyz"
                      className="w-full h-full object-contain"
                    />
                  </div>
                ))}
              </div>
            ) : ""}
            <div className="font-inter text-sm sm:text-lg font-bold text-black text-center">
              {instructorDetail.organisation}
            </div>
            <div className="flex flex-row justify-center py-2 sm:py-4">
              <button className="rounded-md p-1 px-2 sm:px-4 w-[50%] sm:w-[40%] border-2 hover:bg-blue-200">
                <a href={instructorDetail.linkedinurl} rel="noreferrer" target="_blank" className="flex flex-row justify-center">
                  <div className="h-6 sm:h-8">
                    <img src={linkedin} alt="xyz" className="w-full h-full object-contain" />
                  </div>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default InstructorCard;

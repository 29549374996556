import { useState } from "react";
import linkedin from "../../../assets/companylogos/linkedin.png";

const HiringCompanyCard = ({ hiringCompanyDetail }) => {

  const [setFullDescription, setShowFullDescription] = useState(false);

  const toggleFullDescription = () => {
    setShowFullDescription(!setFullDescription);
  };

  return (
    <div className="flex flex-col">
      <div
        key={hiringCompanyDetail.id}
        className="flex flex-col justify-center border-2 shadow-md rounded-[5%] w-[300px] mx-5 px-5 py-3 md:w-[500px] md:mx-5 md:px-10"
      >
        <div className="px-2 md:px-5 pt-2 md:pt-5 flex flex-row justify-center mt-1">
          <div className="w-[100px] h-[100px] md:w-fit md:max-h-[8rem] md:max-w-[10rem]">
          <img
            src={hiringCompanyDetail.img}
            alt="xyz"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="font-inter text-lg text-[#7A7A7A] px-2 md:px-5 py-2 text-center">
          {setFullDescription
            ? hiringCompanyDetail.description
            : hiringCompanyDetail.description.substring(0, 300) + "..."}
          <span>
            <button
              className="text-primary font-bold"
              onClick={toggleFullDescription}
            >
              {setFullDescription ? "Read Less" : "Read More"}
            </button>
          </span>
        </div>
        <div className="font-inter text-lg text-black px-2 md:px-5 pt-2 text-center">
          {hiringCompanyDetail.reviewername}
        </div>
        <div className="font-inter text-sm text-black px-2 md:px-5 text-center">
          {hiringCompanyDetail.designation}
        </div>
        <div className="flex flex-row justify-center py-2 md:py-4">
          <button className="rounded-[10px] px-2 p-1 border-2 w-[90%] md:w-full flex flex-row justify-center hover:bg-blue-100">
            <a href={hiringCompanyDetail.linkedin} rel="noreferrer" target="_blank">
              <img src={linkedin} alt="xyz" className="h-6" />
            </a>
          </button>
        </div>
      </div>
    </div>

  );
};

export default HiringCompanyCard;

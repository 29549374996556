import FeatureCard from "../cards/FeatureCard";

const FeatureUI = ({ features }) => {
    return (
        <div className="flex flex-col items-center">
            <FeatureCard feature={features[0]} />
            <FeatureCard feature={features[1]} />
            <FeatureCard feature={features[2]} />
            <FeatureCard feature={features[3]} />
        </div>
    );
}
export default FeatureUI;
import srikanth from "../assets/instructors/srikanth/profileimg.png";
import krishna from "../assets/instructors/krishna/profileimg.png";
import shubham from "../assets/instructors/shubham/profileimg.png";
import abhishek from "../assets/instructors/abhishek/profileimg.png";
import priyank from "../assets/instructors/priyank/profileimg.webp";
import vikas from "../assets/instructors/vikas/profileimg.webp";
import debjit from "../assets/instructors/debjit/profileimg.webp";
import akash from "../assets/instructors/akash/profileimg.webp";
import LiveIcon from "../assets/programdescriptionicons/icon1.svg";
import MicroSkillIcon from "../assets/programdescriptionicons/icon2.svg";
import IndustryIcon from "../assets/programdescriptionicons/icon3.svg";
import ToolsIcon from "../assets/programdescriptionicons/icon4.svg";
import aiskill1 from "../assets/skillslearnt/datascienceandaiskills/AWS.webp";
import aiskill2 from "../assets/skillslearnt/datascienceandaiskills/Databricks.webp";
import aiskill3 from "../assets/skillslearnt/datascienceandaiskills/Keras.webp";
import aiskill4 from "../assets/skillslearnt/datascienceandaiskills/Matplotlib.webp";
import aiskill5 from "../assets/skillslearnt/datascienceandaiskills/mlflow.webp";
import aiskill6 from "../assets/skillslearnt/datascienceandaiskills/NLTK.webp";
import aiskill7 from "../assets/skillslearnt/datascienceandaiskills/NumPy.webp";
import aiskill8 from "../assets/skillslearnt/datascienceandaiskills/OpenCV.webp";
import aiskill9 from "../assets/skillslearnt/datascienceandaiskills/Pandas.webp";
import aiskill10 from "../assets/skillslearnt/datascienceandaiskills/Plotly.webp";
import aiskill11 from "../assets/skillslearnt/datascienceandaiskills/PyTorch.webp";
import aiskill12 from "../assets/skillslearnt/datascienceandaiskills/RASA.webp";
import aiskill13 from "../assets/skillslearnt/datascienceandaiskills/seaborn.webp";
import aiskill14 from "../assets/skillslearnt/datascienceandaiskills/SpaCy.webp";
import aiskill15 from "../assets/skillslearnt/datascienceandaiskills/Spark.webp";
import aiskill16 from "../assets/skillslearnt/datascienceandaiskills/Tableau.webp";
import aiskill17 from "../assets/skillslearnt/datascienceandaiskills/Flask.webp";
import mernskill1 from "../assets/skillslearnt/mernskills/AWS.webp";
import mernskill2 from "../assets/skillslearnt/mernskills/Docker.webp";
import mernskill3 from "../assets/skillslearnt/mernskills/Linux.webp";
import mernskill4 from "../assets/skillslearnt/mernskills/HTML.webp";
import mernskill5 from "../assets/skillslearnt/mernskills/CSS.webp";
import mernskill6 from "../assets/skillslearnt/mernskills/Bootstrap.webp";
import mernskill7 from "../assets/skillslearnt/mernskills/Javascript.webp";
import mernskill8 from "../assets/skillslearnt/mernskills/React.webp";
import mernskill9 from "../assets/skillslearnt/mernskills/Express.webp";
import mernskill10 from "../assets/skillslearnt/mernskills/MongoDB.webp";
import mernskill11 from "../assets/skillslearnt/mernskills/Mongoose.webp";
import mernskill12 from "../assets/skillslearnt/mernskills/Node.webp";
import mernskill13 from "../assets/skillslearnt/mernskills/REST.webp";
import mernskill14 from "../assets/skillslearnt/mernskills/Git.webp";
import cgi from "../assets/companylogos/cgi.png";
import cigna from "../assets/companylogos/cigna.png";
import siemens from "../assets/companylogos/siemens.png";
import wilistowerwatson from "../assets/companylogos/wilistowerwatson.png";
import growthsource from "../assets/companylogos/growthsource.webp";
import algonox from "../assets/companylogos/algonox.webp";
import policyboss from "../assets/companylogos/policyboss.webp";
import skillgigs from "../assets/companylogos/skillgigs.webp";
import geotech from "../assets/companylogos/geotech.webp";
import acolite from "../assets/companylogos/acolite.webp";
import flipkart from "../assets/companylogos/flipkart.webp";
import amex from "../assets/companylogos/amex.webp";
import exl from "../assets/companylogos/exl.webp";
import iitb from "../assets/companylogos/iitb.webp";
import gayathree from "../assets/surgealumni/Gayathree/gayathree.webp";
import likhith from "../assets/surgealumni/Likhith/likhith.webp";
import sriram from "../assets/surgealumni/Sriram/sriram.webp";
import surekha from "../assets/surgealumni/Surekha/surekha.webp";
import sravya from "../assets/surgealumni/Sravya/sravya.webp";
import lavanya from "../assets/surgealumni/Lavanya/lavanya.webp";
import jagdeesh from "../assets/surgealumni/Jagdeesh/jagdeesh.webp";
import tcs from "../assets/hiringcompanies/tcs.png";
import infosys from "../assets/hiringcompanies/infosys.png";
import sureify from "../assets/hiringcompanies/sureify.png";
import amazon from "../assets/alumnicompanies/amazon.png";
import axisbank from "../assets/hiringcompanies/axisbank.png";
import fullstackcov from "../assets/main/fullstackcov.png";
import dsaicampuscov from "../assets/main/dsaicampuscov.png";
import dsaiprocov from "../assets/main/dsaiprocov.png";
import mernprocov from "../assets/main/mernprocov.png";
import dsacov from "../assets/main/dsacov.png";
import dsaiselfpacedcov from "../assets/main/dsaiselfpacedcov.png";
import selfpaceprogramcert from "../assets/certificates/selfpaceprogramcertsample.png";
import mlfundamentalscert from "../assets/certificates/mlfundamentalscert.png";
import dsaisppf1 from "../assets/programfeature/dsaiselfpaced/1.png";
import dsaisppf2 from "../assets/programfeature/dsaiselfpaced/2.png";
import dsaisppf3 from "../assets/programfeature/dsaiselfpaced/3.png";
import dsaisppf4 from "../assets/programfeature/dsaiselfpaced/4.png";
import p1img from "../assets/projects/backorder.png";
import p2img from "../assets/projects/termdeposit.png";
import p3img from "../assets/projects/imdbmovieanalysis.png";
import p4img from "../assets/projects/carprices.png";
import p5img from "../assets/projects/frauddetection.png";
import p6img from "../assets/projects/recommendationsystem.png";
import p7img from "../assets/projects/telecomchurn.png";
import p8img from "../assets/projects/retailgaintforcasting.png";
import p9img from "../assets/projects/gesturerecognition.png";
import p10img from "../assets/projects/imagecaptioning.png";
import p11img from "../assets/projects/speechrecog.png";
import p12img from "../assets/projects/sentimentanalysis.png";
import p13img from "../assets/projects/newsarticleclassification.png";
import p14img from "../assets/projects/sarcasmdetection.png";
import p15img from "../assets/projects/chatbot.png";
import p16img from "../assets/projects/pneumoniaidentifier.png";

export const CampusProgram = [
  {
    id: 1,
    title: "Data Science and AI \nCampus Program",
    code: "Data-science-and-AI-program",
    tag: "For Colleges",
    type: "Campus Program",
    description: "Boost your college’s premium placements by 2x. Elevate your campus placements by adding 200+ hiring partners. Expand your campus recruitment profile by adding different kind of roles. Upskill your students in Data Science and AI to meet the industry requirements.",
    description_second: "Build a top profile in Data Science, AI by working on both foundational and advanced concepts for building new-age AI Applications. Designed especially for college students and freshers, gain real time work experience along side mastering Python, SQL, Tableau, Machine Learning, NLP and Deep Learning to advance your career in Data Science.",
    syllabus_download_link: "/brochures/datascienceandaicampusprogram.pdf",
    deadline: "30 Sept,2023",
    cover: dsaicampuscov,
    course_overview: [
      {
        title: "100% Online",
        description: "Live Interactive Sessions"
      }, {
        title: "6 months",
        description: "250+ hours of live content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Acquire new-age Data Science Professional skills with our comprehensive project-based learning program. Upskill yourself in Python, SQL, Tableau, Machine Learning, NLP and Deep Learning through industry relevant structured curriculum, industry-standard projects , hands-on activities, and immersive workshops.",
      features: [
        {
          title: "Live Interactive Sessions",
          icon: LiveIcon,
          description: "Well-structured live interactive sessions from industry seasoned IT Experts and access to Session Recordings."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "50+ handson micro-skilling exercises to learn Data Science concepts and achieve indepth understanding of ML Algos.",
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "20+ industry standard projects from various domains, with personalised mentorship, to master Data Science skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "20+ Programming tools and technologies to be a self sufficient Data Science Professional to meet current day requirements."
        }
      ],
    },

    syllabus: [
      {
        id: 0,
        title: "Data Science Foundations",
        description: "The Foundations module comprises five courses where we get our hands dirty with Python basics, Python coding, Python Data Science Packages, Statistics, SQL Programming and Machine Learning and Deep Learning Fundamentals getting exposure to AI terminologies. These courses set our foundations and helps us to learn essential skills so that we sail through the rest of the journey with minimal hindrance as Data Science Professional. This module also helps to apply all the acquired knowledge to extract actionable insights from the Data.",
        topics: [
          {
            id: 0,
            title: "Python for Data Science",
            description: "This course will helps us to learn the Python programming language used for Data Science. We start with python basics learn keywords, syntax and writing usable code of this language. We will have high-level idea of Object Oriented Programming required for AI and ML concepts implementations. We learn to manipulate datasets in Python using Pandas which is the most powerful library for data preparation and analysis. Also We learn to plot and interpret various graphs using Python Visualisation libraries and observe how they make data analysis and extract actionable insights.",
          },
          {
            id: 1,
            title: "Statistics",
            description:
              "We will learn fundamentals like Statistics, Probability, etc., which are key topics in Data Analytics course and also learn concepts vital to Exploratory Data Analysis and Machine Learning in general to extract actionable insights from data. We will learn to ‘infer’ insights from a huge population using a small sample and formulate and validate hypotheses for a population to solve real-life business problems.",
          },
          {
            id: 2,
            title: "SQL Programming",
            description:
              "Here, we will cover database fundamentals to everything you need to know about SQL programming, such as DBMS, Normalization, Joins, etc. we will learn to extract information from RDBMS using the structured query language and apply advanced SQL concepts like windowing and procedures to derive insights from data and answer pertinent business questions.",
          },
          {
            id: 3,
            title: "Machine Learning and Deep Learning Fundamentals ",
            description:
              "Here, we get started with Surge Classes in house tool Alpha to get exposure with ML terminologies and how ML being leveraged in Business decision making. Also build mathematical foundations required for understanding Machine Learning and Deep Learning.",
          },
          {
            id: 4,
            title: "Exploratory Data Analysis",
            description:
              "This course will teach you all about the Exploratory Data Analysis like Preprocessing, Missing values, Outliers, Transformations and Feature Engineering techniques etc.",
          },
        ]
      },
      {
        id: 1,
        title: "Machine Learning",
        description: "This Machine Learning module will teach us all the Machine Learning techniques from scratch, and the popularly used Classical ML algorithms that fall in each of the categories.",
        topics: [
          {
            id: 0,
            title: "Supervised Learning",
            description:
              "In this course we learn about Supervised ML algorithms, working of the algorithms and their scope of application - Regression and Classification.",
          },
          {
            id: 1,
            title: "Unsupervised Learning",
            description:
              "We learn what Unsupervised Learning algorithms are, working of the algorithms and their scope of application - Clustering and Dimensionality Reduction.",
          },
          {
            id: 2,
            title: "Tree Based models and Ensemble Techniques",
            description:
              "In this course, we earn about tree based models and also discuss supervised standalone models’ shortcomings and learn a few techniques, such as Ensemble techniques to overcome these shortcomings.",
          },
          {
            id: 3,
            title: "Model Selection and Tuning",
            description:
              "Learn various concepts that will be useful in creating functional machine learning models like model selection and tuning, model performance measures, ways of regularisation, etc.",
          },
          {
            id: 4,
            title: "Time Series Forecasting ",
            description:
              "This course will teach you how to predict future values based on the previously experimented values using Python.",
          },
          {
            id: 5,
            title: "Recommendation Systems",
            description:
              "In this course, we learn what recommendation systems are, their applications, critical approaches to building them - Popularity based systems, Collaborative filtering, Singular Value Decomposition, etc.",
          },
          {
            id: 6,
            title: "Model Deployment",
            description:
              "This course will teach you how to deploy your machine learning models using Docker, Kubernetes, etc.",
          },
        ]
      },
      {
        id: 2,
        title: "Business Intelligence",
        description: "This Business Intelligence module will teach us about most in-demand Business Intelligence tool Tableau, advanced Excel for Data Analysis and Problem solving skills using business case studies.",
        topics: [
          {
            id: 0,
            title: "Business Intelligence Tool Tableau",
            description:
              "We will learn data visualisation techniques using the most in-demand Business Intelligence tool Tableau in the industry. Also we will understand how to effectively communicate, and fine grain your data analysis projects and understand how to optimally present your findings to technical and non-technical stakeholders and upgrade your storytelling skills.",
          },
          {
            id: 1,
            title: "Data Analysis in Excel",
            description:
              "We will learn the basic and advanced functionalities of Excel and data manipulation and analysis to solve business problems using case studies.",
          },
          {
            id: 2,
            title: " Business Case Studies",
            description:
              "We will learn structured problem skills and methods to address real time business problem with case studies. We will also learn to pose a Data science problem in a business scenario.",
          },
          {
            id: 3,
            title: "BFSI ML Applications",
            description:
              "We learn how does BFSI sector works and how does it leverage ML for its business enhancements through business use cases in this sector. ",
          },
        ]
      },
      {
        id: 3,
        title: "Artificial Intelligence",
        description: "The next module is the Artificial Intelligence which will teach us from the introduction to Artificial Intelligence to taking us beyond the traditional ML into Neural Nets’ realm. We move on to training our models with Unstructured Data like Text and Images from the regular tabular data.",
        topics: [
          {
            id: 0,
            title: "Neural Networks and Deep Learning",
            description:
              "In this Artificial Intelligence Module, we start with the motive behind using the terms Neural network and look at the individual constituents of a neural network. Installation of and building familiarity with TensorFlow library, appreciate the simplicity of Keras and build a deep neural network model for a classification problem using Keras. We also learn how to tune a Deep Neural Network.",
          },
          {
            id: 1,
            title: "Computer Vision",
            description:
              "In this Computer Vision course, we will learn how to process and work with images for Image classification using Neural Networks. Going beyond plain Neural Networks, we will also learn a more advanced architecture - Convolutional Neural Networks.",
          },
          {
            id: 2,
            title: "Natural Language Processing",
            description:
              "Learn how to work with natural language processing with Python using traditional machine learning methods. Then, deep dive into the realm of Sequential Models and state of the art language models.",
          },
        ]
      }
    ],

    skills_learned: [
      aiskill1,
      aiskill2,
      aiskill3,
      aiskill4,
      aiskill5,
      aiskill6,
      aiskill7,
      aiskill8,
      aiskill9,
      aiskill10,
      aiskill11,
      aiskill12,
      aiskill13,
      aiskill14,
      aiskill15,
      aiskill16,
    ],

    learners_review: [
      {
        id: 0,
        name: "Gayathree Dulam",
        batch: "ECE",
        img: gayathree,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/gayathree-dulam-b69102198/",
        review:
          "It was really a great learning experience during the program . We learnt a lot of concepts related to data science and data structures from Instructors who are working in Microsoft, Amazon, Flipkart, Walmart, Siemens, Cigna and other MNCs through live sessions which made us well equipped for the industry. The Instructors were always more than happy to solve the doubts we had during sessions. Instructors have a lot of patience and depth in concepts with huge industry experience. The quality instructor led live sessions is the best thing happened for me to program and entire ecosystem helped me to get into Amazon. I would recommend Surge Classes on any day."
      },
      {
        id: 1,
        name: "Manthina Sriram Varma",
        batch: "CSE",
        img: sriram,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/manthina-sriram-varma-804632169/",
        review:
          "I have enrolled to the program for aiming high package jobs. Overall it was a very good program with many modules which were related to good placements. The teaching was good, we were given opportunity to learn thoroughly in every concept through live classes as well as good doubt clarification sessions. The best thing happened to me in the program is mock interviews by industry experts from working in Goldmansachs, Amazon, American Express, Wells Fargo and Siemens which played significant role in getting offer from Amazon at very high package.  Surge Classes is very good choice which I would recommend who want to make big."
      },
      {
        id: 2,
        name: "Surekha Dasari",
        batch: "ECE",
        img: surekha,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/surekha-dasari-5b9366204/",
        review:
          "Overall learning experience was good and the set of mentors who had great industrial knowledge is the top aspect of surge classes. Thanks for teaching us the data science and coding things patiently and motivating us to work hard and grow in life. They have guided us specific to certain company interviews even on odd times and on short notice."
      },
      {
        id: 3,
        name: "Likhith Sai kumar",
        batch: "Mech",
        img: likhith,
        company: tcs,
        linkedin: "https://www.linkedin.com/in/likhith-sai-kumar-730294190/",
        review:
          "The experience was amazing and the design of program was very good. we had done hands-on projects on every data science and coding module which gave me better understanding of Data science eco systems. The mentors were great they made us understand every concept they gave in depth knowledge of concepts and often clarified our doubts in detailed way. This program helped me to get the offers from TCS Digital and Gain Sight in Campus Placement. Being a Mechanical Engineer this is quite accomplishing. "
      },
      {
        id: 4,
        name: "Jagadeesh Medapati",
        batch: "Electrical",
        img: jagdeesh,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/jagadeesh-medapati/",
        review: "I was completely satisfied with the duration of the programme, Instructors, course lectures as well as projects we have built on various modules. I'd be recommending Surge Classes as anyone can learn the Data science concepts easily from scratch with that mentorship and Live classes."
      },
      {
        id: 5,
        name: "Lavanya Narayanam",
        batch: "IT",
        img: lavanya,
        company: axisbank,
        linkedin: "https://www.linkedin.com/in/narayanam-loka-lavanya/",
        review:
          "It was a pretty good program where I learned machine learning from scratch. The course structure and the hands-on projects are really beneficial to start a career in data science field and make big. The way of explanation from the trainers is easy to understand and they clarify our doubts well. This program helped me to build a profile with ML, NLP and Deep Learning projects on various domains. This quaility Portfolio built in the program helped me to get into Axis Bank in Data Science role. "
      },
      {
        id: 6,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        "title": "What is Data Science and AI?",
        "description": "Data Science and Artificial Intelligence (AI) involve the extraction of insights and patterns from data to create intelligent algorithms and models that mimic human cognitive functions and decision-making."
      },
      {
        "title": "Why are Data Science and AI important?",
        "description": "Data Science and AI play a critical role in transforming data into valuable insights for informed decision-making. They enable automation, predictive analysis, and innovative solutions across various industries."
      },
      {
        "title": "Is prior technical experience necessary for the program?",
        "description": "While some technical background can be advantageous, our Data Science and AI program is designed to accommodate learners from diverse academic and professional backgrounds. We offer comprehensive resources and guidance."
      },
      {
        "title": "What topics are covered in the program?",
        "description": "Our program covers a wide range of topics, including machine learning, data analysis, neural networks, natural language processing, and more. You'll gain a holistic understanding of the Data Science and AI landscape."
      },
      {
        "title": "Are there any prerequisites for enrollment?",
        "description": "Basic knowledge of programming and statistics is recommended, but not mandatory. The program provides foundational learning resources to help participants build the necessary skills."
      }
    ],

    fees: "Rs. 17,500/- + GST*",
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2023",
    instructor: [
      {
        id: 0,
        name: "Srikanth Annibathina",
        designation: "Analytics Manager, Cognizant",
        excompanies: {
          cgi: cgi,
          cigna: cigna
        },
        img: srikanth,
        organisation: "VTU, KARNATAKA",
        linkedinurl: "https://www.linkedin.com/in/srikanth-annabathina-7842a9188/"
      },
      {
        id: 1,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
      {
        id: 2,
        name: "Shubham Jaiswal",
        designation: "Analytics Analyst, McKinsey",
        excompanies: {
          wilistowerwatson: wilistowerwatson
        },
        img: shubham,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/shubham-jaiswal-0aa5a65b/"
      },
      {
        id: 3,
        name: "Akash Pandey",
        designation: "Manager, Times Internet",
        excompanies: {
          amex: amex,
          exl: exl
        },
        img: akash,
        organisation: "IIT KANPUR",
        linkedinurl: "https://www.linkedin.com/in/akash-pandey-676307108/"
      },
      {
        id: 4,
        name: "Abhishek Potnis",
        designation: "R&D Associate in ML, Oak Ridge",
        excompanies: {
          iitb: iitb
        },
        img: abhishek,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/abhishekpotnis/"
      },
    ]
  },
  {
    id: 20,
    title: "Data Science and AI \nCampus Program (Level 1)",
    code: "Data-science-and-AI-program-level-1",
    tag: "For Colleges",
    type: "Campus Program",
    description: "Boost your college’s premium placements by 2x. Elevate your campus placements by adding 200+ hiring partners. Expand your campus recruitment profile by adding different kind of roles. Upskill your students in Data Science and AI to meet the industry requirements.",
    description_second: "Build a top profile in Data Science by working on foundational concepts and advance into building new-age ML Applications. Designed especially for college students and freshers, gain real time work experience along side mastering Python, SQL, Tableau and Machine Learning to advance your career in Data Science.",
    syllabus_download_link: "/brochures/datascienceandaicampusprogramlevel1.pdf",
    deadline: "5 Dec,2024",
    cover: dsaicampuscov,
    course_overview: [
      {
        title: "100% Online",
        description: "Live Interactive Sessions"
      }, {
        title: "6 months",
        description: "250+ hours of live content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Acquire new-age Data Science Professional skills with our comprehensive project-based learning program. Upskill yourself in Python, SQL, Tableau and Machine Learning through industry relevant structured curriculum, industry-standard projects , hands-on activities, and immersive workshops.",
      features: [
        {
          title: "Live Interactive Sessions",
          icon: LiveIcon,
          description: "Well-structured live interactive sessions from industry seasoned IT Experts and access to Session Recordings."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "30+ handson micro-skilling exercises to learn Data Science concepts and achieve indepth understanding of ML Algos.",
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "12+ industry standard projects from various domains, with personalised mentorship, to master Data Science skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "20+ Programming tools and technologies to be a self sufficient Data Science Professional to meet current day requirements."
        }
      ],
    },

    syllabus: [
      {
        id: 0,
        title: "Data Science Foundations",
        description: "The Foundations module comprises five courses where we get our hands dirty with Python basics, Python coding, Python Data Science Packages, Statistics, SQL Programming and Machine Learning and Deep Learning Fundamentals getting exposure to AI terminologies. These courses set our foundations and helps us to learn essential skills so that we sail through the rest of the journey with minimal hindrance as Data Science Professional. This module also helps to apply all the acquired knowledge to extract actionable insights from the Data.",
        topics: [
          {
            id: 0,
            title: "Python for Data Science",
            description: "This course will helps us to learn the Python programming language used for Data Science. We start with python basics learn keywords, syntax and writing usable code of this language. We will have high-level idea of Object Oriented Programming required for AI and ML concepts implementations. We learn to manipulate datasets in Python using Pandas which is the most powerful library for data preparation and analysis. Also We learn to plot and interpret various graphs using Python Visualisation libraries and observe how they make data analysis and extract actionable insights.",
          },
          {
            id: 1,
            title: "Statistics",
            description:
              "We will learn fundamentals like Statistics, Probability, etc., which are key topics in Data Analytics course and also learn concepts vital to Exploratory Data Analysis and Machine Learning in general to extract actionable insights from data. We will learn to ‘infer’ insights from a huge population using a small sample and formulate and validate hypotheses for a population to solve real-life business problems.",
          },
          {
            id: 2,
            title: "SQL Programming",
            description:
              "Here, we will cover database fundamentals to everything you need to know about SQL programming, such as DBMS, Normalization, Joins, etc. we will learn to extract information from RDBMS using the structured query language and apply advanced SQL concepts like windowing and procedures to derive insights from data and answer pertinent business questions.",
          },
          {
            id: 3,
            title: "Machine Learning and Deep Learning Fundamentals ",
            description:
              "Here, we get started with Surge Classes in house tool Alpha to get exposure with ML terminologies and how ML being leveraged in Business decision making. Also build mathematical foundations required for understanding Machine Learning and Deep Learning.",
          },
          {
            id: 4,
            title: "Exploratory Data Analysis",
            description:
              "This course will teach you all about the Exploratory Data Analysis like Preprocessing, Missing values, Outliers, Transformations and Feature Engineering techniques etc.",
          },
        ]
      },
      {
        id: 1,
        title: "Machine Learning",
        description: "This Machine Learning module will teach us all the Machine Learning techniques from scratch, and the popularly used Classical ML algorithms that fall in each of the categories.",
        topics: [
          {
            id: 0,
            title: "Supervised Learning",
            description:
              "In this course we learn about Supervised ML algorithms, working of the algorithms and their scope of application - Regression and Classification.",
          },
          {
            id: 1,
            title: "Unsupervised Learning",
            description:
              "We learn what Unsupervised Learning algorithms are, working of the algorithms and their scope of application - Clustering and Dimensionality Reduction.",
          },
          {
            id: 2,
            title: "Tree Based models and Ensemble Techniques",
            description:
              "In this course, we earn about tree based models and also discuss supervised standalone models’ shortcomings and learn a few techniques, such as Ensemble techniques to overcome these shortcomings.",
          },
          {
            id: 3,
            title: "Model Selection and Tuning",
            description:
              "Learn various concepts that will be useful in creating functional machine learning models like model selection and tuning, model performance measures, ways of regularisation, etc.",
          },
          {
            id: 4,
            title: "Time Series Forecasting ",
            description:
              "This course will teach you how to predict future values based on the previously experimented values using Python.",
          },
          {
            id: 5,
            title: "Recommendation Systems",
            description:
              "In this course, we learn what recommendation systems are, their applications, critical approaches to building them - Popularity based systems, Collaborative filtering, Singular Value Decomposition, etc.",
          },
          {
            id: 6,
            title: "Model Deployment",
            description:
              "This course will teach you how to deploy your machine learning models using Docker, Kubernetes, etc.",
          },
        ]
      },
      {
        id: 2,
        title: "Business Intelligence",
        description: "This Business Intelligence module will teach us about most in-demand Business Intelligence tool Tableau, advanced Excel for Data Analysis and Problem solving skills using business case studies.",
        topics: [
          {
            id: 0,
            title: "Business Intelligence Tool Tableau",
            description:
              "We will learn data visualisation techniques using the most in-demand Business Intelligence tool Tableau in the industry. Also we will understand how to effectively communicate, and fine grain your data analysis projects and understand how to optimally present your findings to technical and non-technical stakeholders and upgrade your storytelling skills.",
          },
          {
            id: 1,
            title: "Data Analysis in Excel",
            description:
              "We will learn the basic and advanced functionalities of Excel and data manipulation and analysis to solve business problems using case studies.",
          },
          {
            id: 2,
            title: " Business Case Studies",
            description:
              "We will learn structured problem skills and methods to address real time business problem with case studies. We will also learn to pose a Data science problem in a business scenario.",
          },
          {
            id: 3,
            title: "BFSI ML Applications",
            description:
              "We learn how does BFSI sector works and how does it leverage ML for its business enhancements through business use cases in this sector. ",
          },
        ]
      },
    ],

    skills_learned: [
      aiskill1,
      aiskill2,
      aiskill3,
      aiskill4,
      aiskill5,
      aiskill6,
      aiskill7,
      aiskill8,
      aiskill9,
      aiskill10,
      aiskill11,
      aiskill12,
      aiskill13,
      aiskill14,
      aiskill15,
      aiskill16,
    ],

    learners_review: [
      {
        id: 0,
        name: "Gayathree Dulam",
        batch: "ECE",
        img: gayathree,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/gayathree-dulam-b69102198/",
        review:
          "It was really a great learning experience during the program . We learnt a lot of concepts related to data science and data structures from Instructors who are working in Microsoft, Amazon, Flipkart, Walmart, Siemens, Cigna and other MNCs through live sessions which made us well equipped for the industry. The Instructors were always more than happy to solve the doubts we had during sessions. Instructors have a lot of patience and depth in concepts with huge industry experience. The quality instructor led live sessions is the best thing happened for me to program and entire ecosystem helped me to get into Amazon. I would recommend Surge Classes on any day."
      },
      {
        id: 1,
        name: "Manthina Sriram Varma",
        batch: "CSE",
        img: sriram,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/manthina-sriram-varma-804632169/",
        review:
          "I have enrolled to the program for aiming high package jobs. Overall it was a very good program with many modules which were related to good placements. The teaching was good, we were given opportunity to learn thoroughly in every concept through live classes as well as good doubt clarification sessions. The best thing happened to me in the program is mock interviews by industry experts from working in Goldmansachs, Amazon, American Express, Wells Fargo and Siemens which played significant role in getting offer from Amazon at very high package.  Surge Classes is very good choice which I would recommend who want to make big."
      },
      {
        id: 2,
        name: "Surekha Dasari",
        batch: "ECE",
        img: surekha,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/surekha-dasari-5b9366204/",
        review:
          "Overall learning experience was good and the set of mentors who had great industrial knowledge is the top aspect of surge classes. Thanks for teaching us the data science and coding things patiently and motivating us to work hard and grow in life. They have guided us specific to certain company interviews even on odd times and on short notice."
      },
      {
        id: 3,
        name: "Likhith Sai kumar",
        batch: "Mech",
        img: likhith,
        company: tcs,
        linkedin: "https://www.linkedin.com/in/likhith-sai-kumar-730294190/",
        review:
          "The experience was amazing and the design of program was very good. we had done hands-on projects on every data science and coding module which gave me better understanding of Data science eco systems. The mentors were great they made us understand every concept they gave in depth knowledge of concepts and often clarified our doubts in detailed way. This program helped me to get the offers from TCS Digital and Gain Sight in Campus Placement. Being a Mechanical Engineer this is quite accomplishing. "
      },
      {
        id: 4,
        name: "Jagadeesh Medapati",
        batch: "Electrical",
        img: jagdeesh,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/jagadeesh-medapati/",
        review: "I was completely satisfied with the duration of the programme, Instructors, course lectures as well as projects we have built on various modules. I'd be recommending Surge Classes as anyone can learn the Data science concepts easily from scratch with that mentorship and Live classes."
      },
      {
        id: 5,
        name: "Lavanya Narayanam",
        batch: "IT",
        img: lavanya,
        company: axisbank,
        linkedin: "https://www.linkedin.com/in/narayanam-loka-lavanya/",
        review:
          "It was a pretty good program where I learned machine learning from scratch. The course structure and the hands-on projects are really beneficial to start a career in data science field and make big. The way of explanation from the trainers is easy to understand and they clarify our doubts well. This program helped me to build a profile with ML, NLP and Deep Learning projects on various domains. This quaility Portfolio built in the program helped me to get into Axis Bank in Data Science role. "
      },
      {
        id: 6,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        "title": "What is Data Science and AI?",
        "description": "Data Science and Artificial Intelligence (AI) involve the extraction of insights and patterns from data to create intelligent algorithms and models that mimic human cognitive functions and decision-making."
      },
      {
        "title": "Why are Data Science and AI important?",
        "description": "Data Science and AI play a critical role in transforming data into valuable insights for informed decision-making. They enable automation, predictive analysis, and innovative solutions across various industries."
      },
      {
        "title": "Is prior technical experience necessary for the program?",
        "description": "While some technical background can be advantageous, our Data Science and AI program is designed to accommodate learners from diverse academic and professional backgrounds. We offer comprehensive resources and guidance."
      },
      {
        "title": "What topics are covered in the program?",
        "description": "Our program covers a wide range of topics, including machine learning, data analysis, neural networks, natural language processing, and more. You'll gain a holistic understanding of the Data Science and AI landscape."
      },
      {
        "title": "Are there any prerequisites for enrollment?",
        "description": "Basic knowledge of programming and statistics is recommended, but not mandatory. The program provides foundational learning resources to help participants build the necessary skills."
      }
    ],

    fees: "Rs. 17,500/- + GST*",
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2023",
    instructor: [
      {
        id: 0,
        name: "Srikanth Annibathina",
        designation: "Analytics Manager, Cognizant",
        excompanies: {
          cgi: cgi,
          cigna: cigna
        },
        img: srikanth,
        organisation: "VTU, KARNATAKA",
        linkedinurl: "https://www.linkedin.com/in/srikanth-annabathina-7842a9188/"
      },
      {
        id: 1,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
      {
        id: 2,
        name: "Shubham Jaiswal",
        designation: "Analytics Analyst, McKinsey",
        excompanies: {
          wilistowerwatson: wilistowerwatson
        },
        img: shubham,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/shubham-jaiswal-0aa5a65b/"
      },
      {
        id: 3,
        name: "Akash Pandey",
        designation: "Manager, Times Internet",
        excompanies: {
          amex: amex,
          exl: exl
        },
        img: akash,
        organisation: "IIT KANPUR",
        linkedinurl: "https://www.linkedin.com/in/akash-pandey-676307108/"
      },
      {
        id: 4,
        name: "Abhishek Potnis",
        designation: "R&D Associate in ML, Oak Ridge",
        excompanies: {
          iitb: iitb
        },
        img: abhishek,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/abhishekpotnis/"
      },
    ]
  },
  {
    id: 10,
    title: "MERN Full Stack \nCampus Program",
    code: "MERN-Full-Stack-Development",
    tag: "For Colleges",
    type: "Campus Program",
    description: "Boost your college’s premium placements by 2x. Elevate your campus placements by adding 200+ hiring partners. Expand your campus recruitment profile by adding different kind of roles. Upskill your students in MERN Full Stack Development to meet the industry requirements.",
    description_second: "Advance your career in full-stack development by working on industry standard tech projects and building new-age applications. Gain real work experience and master Javascript, React, NodeJS, Express, MongoDB database and more to land a top career as a full-stack developer.",
    syllabus_download_link: "/brochures/mernfullstackprogram.pdf",
    deadline: "30 Sept,2023",
    cover: fullstackcov,
    course_overview: [
      {
        title: "100% Online",
        description: "Live Interactive Sessions"
      }, {
        title: "3 months",
        description: "100+ hours of live content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Succeed as a new-age Full-Stack Developer with our comprehensive project-based learning program. Upskill yourself in JavaScript, React, Express, NodeJS, MongoDB and more through industry standard projects, hands-on activities, and immersive workshops.",
      features: [
        {
          title: "Live Interactive Sessions",
          icon: LiveIcon,
          description: "Well-structured live interactive sessions from industry seasoned IT Experts and access to Session Recordings."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "12+ hands-on micro-skilling exercises to learn CS fundamentals and achieve in-depth understanding of developer skills."
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "5+ industry-standard projects from various domains, with personalised mentorship, to master developer skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "12+ Programming tools and technologies to be a self sufficient Developer to meet current day requirements."
        }
      ],
    },

    syllabus: [
      {
        id: 0,
        title: "Build SurgeKart(Static)",
        description: "Build a responsive front end for SurgeKart, a FlipKart like website and learn HTML, CSS, Bootstrap and more.",
        topics: [
          {
            "id": 0,
            "title": "HTML",
            "description": "Master HTML basics, tags, and attributes to create structured web pages for SurgeKart. Learn essential elements like headings, paragraphs, lists, and links. Dive into semantic HTML for better accessibility. Build forms, embed multimedia, and understand best coding practices. Become proficient in crafting web content using HTML."
          },
          {
            "id": 1,
            "title": "CSS, FlexBox",
            "description": "Learn the art of CSS to style and enhance web elements, and delve into Flexbox to create versatile layouts with precision. This course empowers you with skills to transform designs, optimize responsiveness, and elevate the visual appeal of web pages on SurgeKart."
          },
          {
            "id": 2,
            "title": "Bootstrap",
            "description": "Master Bootstrap, the leading front-end framework, by designing a mobile-first, responsive web page. Explore its components, grid system, and CSS classes to create visually appealing layouts. Elevate your web design skills with Bootstrap on SurgeKart."
          }
        ]
      },
      {
        id: 1,
        title: "Web Developer Essentials",
        description: "Learn essential skills that form the foundation of any successful modern web developer.",
        topics: [
          {
            "id": 0,
            "title": "HTTP, REST API",
            "description": "Learn about HTTP and REST APIs, and understand app-backend communication. Explore tweeting from a Linux Terminal using Twitter APIs for practical insight."
          },
          {
            "id": 1,
            "title": "LINUX",
            "description": "Discover Linux and common developer workflows, analyzing data using Linux. Gain fundamental skills for effective development in a Linux environment."
          },
          {
            "id": 2,
            "title": "Cloud Deployment",
            "description": "Get introduced to Cloud Deployment by hosting a web app on a cloud-based Linux server. Understand online accessibility and scalability of applications."
          }
        ]
      },
      {
        id: 2,
        title: "Build SurgeKart(Dynamic)",
        description: "Learn to make API calls by using it to enhance SurgeKart and make it dynamic.",
        topics: [
          {
            "id": 0,
            "title": "API Calls",
            "description": "Learn to make API calls to dynamically enhance SurgeKart's functionality by retrieving real-time product data."
          },
          {
            "id": 1,
            "title": "Routing",
            "description": "Explore DOM Manipulation with API data to implement seamless web page routing and navigation through different product categories."
          },
          {
            "id": 2,
            "title": "Data Persistence",
            "description": "Utilize DOM Manipulation with API data to create persistent changes, ensuring that user preferences and cart items are retained during sessions."
          },
          {
            "id": 3,
            "title": "JavaScript",
            "description": "Master filters and maps in JavaScript for interactive websites, enabling users to refine product searches and visualize data through dynamic charts."
          },
          {
            "id": 4,
            "title": "Third Party Libraries",
            "description": "Integrate third-party libraries for engaging SurgeKart features, such as incorporating a responsive image gallery using the Lightbox library."
          },
          {
            "id": 5,
            "title": "Surgeshorts",
            "description": "Apply SurgeKart concepts to build Surgeshorts, an independent newsfeed project aggregating news articles from reputable sources, offering readers a diverse content experience."
          }
        ]
      },
      {
        id: 3,
        title: "Build SurgeKart(frontend)",
        description: "Build the frontend for SurgeKart - a rich shopping app, using React, and create a rich shopping experience",
        topics: [
          {
            id: 0,
            title: "Component",
            description: "Component, Component life Cycle, Component state management, Component Styling, Props, Routing, Global context."
          },
          {
            id: 1,
            title: "Component LifeCycle",
            description: "Dive deeper in understanding component state and managing component lifecycle.",
          },
          {
            id: 2,
            title: "Component State Management",
            description: "Dive deeper in understanding component state and managing component lifecycle.",
          },
          {
            id: 3,
            title: "Component styling",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart.",
          },
          {
            id: 4,
            title: "Props",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart.",
          },
          {
            id: 5,
            title: "Routing",
            description: "Implement frontend routing and React routes in SurgeKart.",
          },
          {
            id: 6,
            title: "Global Context",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart",
          },
          {
            id: 7,
            title: "Build SurgeFlix(Frontend)",
            description: "Apply your learnings from SurgeKart Frontend to build your second independent project using React, the frontend for SurgeFlix - a video-streaming platform like Netflix.",
          },
        ]
      },
      {
        id: 4,
        title: "Build SurgeKart(backend)",
        description: "Build a scalabe NodeJS backend for SurgeKart using the Express.js",
        topics: [
          {
            id: 0,
            title: "Express.js",
            description:
              "Get started with Express.js framework and create REST APIs for SurgeKart with custom routes and request validation.",
          },
          {
            id: 1,
            title: "Node.js",
            description: "Build a scalable NodeJS backend for SurgeKart using the Express.js framework.",
          },
          {
            id: 2,
            title: "MongoDB",
            description: "Learn MongoDB by using it to introduce a database layer in SurgeKart.",
          },
          {
            id: 3,
            title: "REST API",
            description: "Add authentication to secure the REST APIs you have implemented.",
          },
          {
            id: 4,
            title: "Build SurgeFlix(Backend)",
            description: "Apply your learnings from SurgeKart Backend to build your third independent project using Express and Node JS, the backend for SurgeFlix - a video streaming platform like Netflix.",
          }
        ]
      }
    ],

    skills_learned: [
      mernskill1,
      mernskill2,
      mernskill3,
      mernskill4,
      mernskill5,
      mernskill6,
      mernskill7,
      mernskill8,
      mernskill9,
      mernskill10,
      mernskill11,
      mernskill12,
      mernskill13,
      mernskill14
    ],

    learners_review: [
      {
        id: 0,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        title: "What does the MERN full stack course cover?",
        description: "Our MERN full stack course covers all aspects of building modern web applications using the MERN stack, which includes MongoDB, Express.js, React, and Node.js. You'll learn to create both the front-end and back-end components of web applications."
      },
      {
        title: "Is prior programming experience required?",
        description: "While prior programming experience can be helpful, our MERN full stack course is designed for learners of all levels. We start with the fundamentals and gradually progress to more advanced topics. Beginners are welcome!"
      },
      {
        title: "What kind of projects will I work on?",
        description: "Throughout the course, you'll work on a variety of projects that simulate real-world scenarios. These projects will help you apply what you've learned and build a solid portfolio showcasing your MERN full stack development skills."
      },
      {
        title: "Do I need to install any software?",
        description: "Yes, you'll need to have the MERN development environment set up on your computer. We provide detailed instructions on how to install and configure MongoDB, Express.js, React, and Node.js. Our course also includes guidance on setting up code editors and tools."
      },
      {
        title: "How is the course structured?",
        description: "The course is structured into modules that cover different aspects of the MERN stack. Each module includes video lectures, hands-on exercises, and coding challenges. You'll have access to our online learning platform where you can track your progress and interact with instructors and fellow learners."
      }
    ],

    fees: "Rs. 7,500/- + GST*",
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2023",
    instructor: [
      {
        id: 1,
        name: "Priyank Srivastav",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          skillgigs: skillgigs,
          policyboss: policyboss
        },
        img: priyank,
        organisation: "IIT ROORKEE",
        linkedinurl: "https://www.linkedin.com/in/thepriyank/"
      },
      {
        id: 2,
        name: "Vikas Srivastava",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          flipkart: flipkart,
          acolite: acolite,
        },
        img: vikas,
        organisation: "IIT DELHI",
        linkedinurl: "https://www.linkedin.com/in/vikasjk/"
      },

      {
        id: 3,
        name: "Debjit Sinha",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          acolite: acolite,
          geotech: geotech
        },
        img: debjit,
        organisation: "SENIOR SDE, VIACOM18",
        linkedinurl: "https://www.linkedin.com/in/frontend-ninja/"
      },
      {
        id: 4,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
    ]
  },
  {
    id: 2,
    title: "Data Structures and Algorithms \nCampus Program",
    code: "Data-structure-and-algorithm",
    tag: "For Colleges",
    type: "Campus Program",
    description: "Boost your college’s premium placements by 2x. Elevate your campus placements by adding 200+ hiring partners. Expand your campus recruitment profile by adding different kind of roles. Train your students in Data Structures and Algorithms for product based companies.",
    description_second: "Build a top profile in software engineering by mastering Data Structures and Algorithms from highly experienced professionals working in companies like Microsoft, Amazon, Google, Qualcomm",
    syllabus_download_link: "",
    deadline: "30 Sept,2023",
    cover: dsacov,
    course_overview: [
      {
        title: "100% Online",
        description: "Live Interactive Sessions"
      }, {
        title: "4 months",
        description: "120+ hours of live content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Learn Data Structures and Algorithms from basic to an advanced level. Level up and crack product-based companies coding rounds and interviews. Learn with a combination of in-depth tutorials, mentor support from experienced mentors, weekly assessments and hackathons.",
      features: [
        {
          title: "Live Interactive Sessions",
          icon: LiveIcon,
          description: "Well-structured live interactive sessions from industry seasoned IT Experts and access to Session Recordings."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "12+ hands-on micro-skilling exercises to learn CS fundamentals and achieve in-depth understanding of developer skills."
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "5+ industry-standard projects from various domains, with personalised mentorship, to master developer skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "12+ Programming tools and technologies to be a self sufficient Developer to meet current day requirements."
        }
      ],
    },

    syllabus: [
      {
        id: 0,
        title: "Module 1",
        description:
          "In this module we will start with introduction to DSA and learn Arrays, String and Search Algorithms",
        topics: [
          {
            id: 0,
            title: "Introduction",
            description: "Introduction to DSA.",
          },
          {
            id: 1,
            title: "Arrays & Strings",
            description: "Learn Arrays & Strings Operations in advanced.",
          },
          {
            id: 2,
            title: " Search Algorithms",
            description: "Learn different search Algorithms.",
          },
        ],
      },
      {
        id: 1,
        title: "Module 2",
        description:
          "In this module we will learn sorting, arrays related algorithm techniques",
        topics: [
          {
            id: 0,
            title: "Sorting",
            description: "Learn How to Sort, and different sorting algorithm.",
          },
          {
            id: 1,
            title: "Arrays Related Algorithm Techniques",
            description: "Different algorithm & techniques.",
          },
        ],
      },
      {
        id: 2,
        title: "Module 3",
        description:
          "In this learn stack, recursion, queue, deque, standard template library, linkedlist",
        topics: [
          {
            id: 0,
            title: "Stack",
            description: "Learn Stack",
          },
          {
            id: 1,
            title: "Recursion",
            description: "Learn Recursion",
          },
          {
            id: 2,
            title: "Queue",
            description: "Learn Queue",
          },
          {
            id: 3,
            title: "Deque(Double Ended Queue)",
            description: "Learn Deque",
          },
          {
            id: 4,
            title: " Standard Template Library",
            description: "Learn Standard Template Library",
          },
          {
            id: 5,
            title: "LinkedList",
            description: "Learn LinkedList.",
          },
        ],
      },
      {
        id: 3,
        title: "Module 4",
        description:
          "In this module we will learn dynamic programming, divide conquer, pattern matching algorithms, tree and graph",
        topics: [
          {
            id: 0,
            title: "Dynamic Programming",
            description: "What is dynamic programming.",
          },
          {
            id: 1,
            title: "Divide Conquer (Revisit)",
            description: "Divide & Conquer Revisit",
          },
          {
            id: 2,
            title: " Pattern Matching Algorithms (KMP, Boyer Moore)",
            description: "Learn PMA",
          },
          {
            id: 3,
            title: "Tree and Graph",
            description: "Learn about trees and graphs.",
          },
        ],
      },
    ],

    skills_learned: [
      aiskill1,
      aiskill2,
      aiskill3,
      aiskill4,
      aiskill5,
      aiskill6,
      aiskill7,
      aiskill8,
      aiskill9,
      aiskill10,
      aiskill11,
      aiskill12,
      aiskill13,
      aiskill14,
      aiskill15,
      aiskill16,
    ],

    learners_review: [
      {
        id: 0,
        name: "Gayathree Dulam",
        batch: "ECE",
        img: gayathree,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/gayathree-dulam-b69102198/",
        review:
          "It was really a great learning experience during the program . We learnt a lot of concepts related to data science and data structures from Instructors who are working in Microsoft, Amazon, Flipkart, Walmart, Siemens, Cigna and other MNCs through live sessions which made us well equipped for the industry. The Instructors were always more than happy to solve the doubts we had during sessions. Instructors have a lot of patience and depth in concepts with huge industry experience. The quality instructor led live sessions is the best thing happened for me to program and entire ecosystem helped me to get into Amazon. I would recommend Surge Classes on any day."
      },
      {
        id: 1,
        name: "Surekha Dasari",
        batch: "ECE",
        img: surekha,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/surekha-dasari-5b9366204/",
        review:
          "Overall learning experience was good and the set of mentors who had great industrial knowledge is the top aspect of surge classes. Thanks for teaching us the data science and coding things patiently and motivating us to work hard and grow in life. They have guided us specific to certain company interviews even on odd times and on short notice."
      },
      {
        id: 2,
        name: "Likhith Sai kumar",
        batch: "Mech",
        img: likhith,
        company: tcs,
        linkedin: "https://www.linkedin.com/in/likhith-sai-kumar-730294190/",
        review:
          "The experience was amazing and the design of program was very good. we had done hands-on projects on every data science and coding module which gave me better understanding of Data science eco systems. The mentors were great they made us understand every concept they gave in depth knowledge of concepts and often clarified our doubts in detailed way. This program helped me to get the offers from TCS Digital and Gain Sight in Campus Placement. Being a Mechanical Engineer this is quite accomplishing. "
      },
      {
        id: 3,
        name: "Jagadeesh Medapati",
        batch: "Electrical",
        img: jagdeesh,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/jagadeesh-medapati/",
        review: "I was completely satisfied with the duration of the programme, Instructors, course lectures as well as projects we have built on various modules. I'd be recommending Surge Classes as anyone can learn the Data science concepts easily from scratch with that mentorship and Live classes."
      },
      {
        id: 4,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        title: "What is Data Structures and Algorithms?",
        description: "Data Structures and Algorithms (DSA) are fundamental concepts in computer science that involve organizing and managing data effectively and solving computational problems efficiently."
      },
      {
        title: "Why is DSA important for programmers?",
        description: "DSA knowledge is crucial for programmers because it helps them write efficient and optimized code. It allows them to solve complex problems, improve application performance, and excel in technical interviews."
      },
      {
        title: "Is prior programming experience required for the DSA course?",
        description: "While some programming experience can be helpful, our DSA course is designed to accommodate learners with various levels of experience. We provide comprehensive materials and support to help learners grasp the concepts."
      },
      {
        title: "What programming languages are covered in the course?",
        description: "Our DSA course covers a range of programming languages commonly used in the industry, including Python, Java, and C++. You can choose the language you're most comfortable with."
      },
      {
        title: "Are there any prerequisites for enrolling in the DSA course?",
        description: "Basic programming knowledge is recommended, but there are no strict prerequisites for the DSA course. Our course materials are structured to guide beginners through the concepts step by step."
      }
    ],

    fees: "Rs. 6,500/- + GST*",
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2023",
    instructor: [
      {
        id: 1,
        name: "Priyank Srivastav",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          skillgigs: skillgigs,
          policyboss: policyboss
        },
        img: priyank,
        organisation: "IIT ROORKEE",
        linkedinurl: "https://www.linkedin.com/in/thepriyank/"
      },
      {
        id: 2,
        name: "Vikas Srivastava",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          flipkart: flipkart,
          acolite: acolite,
        },
        img: vikas,
        organisation: "IIT DELHI",
        linkedinurl: "https://www.linkedin.com/in/vikasjk/"
      },

      {
        id: 3,
        name: "Debjit Sinha",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          acolite: acolite,
          geotech: geotech
        },
        img: debjit,
        organisation: "SENIOR SDE, VIACOM18",
        linkedinurl: "https://www.linkedin.com/in/frontend-ninja/"
      },
      {
        id: 4,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
    ]
  },
];

export const ProProgram = [
  {
    id: 3,
    title: "Data science and AI \nPro Program",
    code: "Data-science-and-AI-Pro-program",
    tag: "For Working professionals",
    type: "Pro Program",
    description: "Land a top career in Data Science, AI by working on industry standard projects and building new-age AI Applications. Designed for working professionals and experienced candidates, gain real time work experience along side mastering Python, SQL, Tableau, Machine Learning, NLP and Deep Learning to advance your career in Data Science.",
    syllabus_download_link: "/brochures/datascienceandaiproprogram.pdf",
    deadline: "30 Sept,2023",
    cover: dsaiprocov,
    course_overview: [
      {
        title: "100% Online",
        description: "Live Interactive Sessions"
      }, {
        title: "6 months",
        description: "300+ hours of live content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Succeed as a new-age Data Science and AI Professional with our comprehensive project-based learning program. Upskill yourself in Python, SQL, Tableau, Machine Learning, NLP and Deep Learning through industry relevant structured curriculum, industry-standard projects , hands-on activities, and immersive workshops.",
      features: [
        {
          title: "Live Interactive Sessions",
          icon: LiveIcon,
          description: "Well-structured live interactive sessions from industry seasoned IT Experts and access to Session Recordings."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "65+ hands-on micro-skilling exercises to learn Data Science concepts and achieve indepth understanding of ML Algos.",
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "30+ industry standard projects from various domains, with personalised mentorship, to master Data Science skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "20+ Programming tools and technologies to be a self sufficient Data Science Professional to meet current day requirements."
        }
      ],
    },

    syllabus: [
      {
        id: 0,
        title: "Data Science Foundations",
        description: "The Foundations module comprises five courses where we get our hands dirty with Python basics, Python coding, Python Data Science Packages, Statistics, SQL Programming and Machine Learning and Deep Learning Fundamentals getting exposure to AI terminologies. These courses set our foundations and helps us to learn essential skills so that we sail through the rest of the journey with minimal hindrance as Data Science Professional. This module also helps to apply all the acquired knowledge to extract actionable insights from the Data.",
        topics: [
          {
            id: 0,
            title: "Python for Data Science",
            description: "This course will helps us to learn the Python programming language used for Data Science. We start with python basics learn keywords, syntax and writing usable code of this language. We will have high-level idea of Object Oriented Programming required for AI and ML concepts implementations. We learn to manipulate datasets in Python using Pandas which is the most powerful library for data preparation and analysis. Also We learn to plot and interpret various graphs using Python Visualisation libraries and observe how they make data analysis and extract actionable insights.",
          },
          {
            id: 1,
            title: "Statistics",
            description:
              "We will learn fundamentals like Statistics, Probability, etc., which are key topics in Data Analytics course and also learn concepts vital to Exploratory Data Analysis and Machine Learning in general to extract actionable insights from data. We will learn to ‘infer’ insights from a huge population using a small sample and formulate and validate hypotheses for a population to solve real-life business problems.",
          },
          {
            id: 2,
            title: "SQL Programming",
            description:
              "Here, we will cover database fundamentals to everything you need to know about SQL programming, such as DBMS, Normalization, Joins, etc. we will learn to extract information from RDBMS using the structured query language and apply advanced SQL concepts like windowing and procedures to derive insights from data and answer pertinent business questions.",
          },
          {
            id: 3,
            title: "Machine Learning and Deep Learning Fundamentals ",
            description:
              "Here, we get started with Surge Classes in house tool Alpha to get exposure with ML terminologies and how ML being leveraged in Business decision making. Also build mathematical foundations required for understanding Machine Learning and Deep Learning.",
          },
          {
            id: 4,
            title: "Exploratory Data Analysis",
            description:
              "This course will teach you all about the Exploratory Data Analysis like Preprocessing, Missing values, Outliers, Transformations and Feature Engineering techniques etc.",
          },
        ]
      },
      {
        id: 1,
        title: "Machine Learning",
        description: "This Machine Learning module will teach us all the Machine Learning techniques from scratch, and the popularly used Classical ML algorithms that fall in each of the categories.",
        topics: [
          {
            id: 0,
            title: "Supervised Learning",
            description:
              "In this course we learn about Supervised ML algorithms, working of the algorithms and their scope of application - Regression and Classification.",
          },
          {
            id: 1,
            title: "Unsupervised Learning",
            description:
              "We learn what Unsupervised Learning algorithms are, working of the algorithms and their scope of application - Clustering and Dimensionality Reduction.",
          },
          {
            id: 2,
            title: "Tree Based models and Ensemble Techniques",
            description:
              "In this course, we earn about tree based models and also discuss supervised standalone models’ shortcomings and learn a few techniques, such as Ensemble techniques to overcome these shortcomings.",
          },
          {
            id: 3,
            title: "Model Selection and Tuning",
            description:
              "Learn various concepts that will be useful in creating functional machine learning models like model selection and tuning, model performance measures, ways of regularisation, etc.",
          },
          {
            id: 4,
            title: "Time Series Forecasting ",
            description:
              "This course will teach you how to predict future values based on the previously experimented values using Python.",
          },
          {
            id: 5,
            title: "Recommendation Systems",
            description:
              "In this course, we learn what recommendation systems are, their applications, critical approaches to building them - Popularity based systems, Collaborative filtering, Singular Value Decomposition, etc.",
          },
          {
            id: 6,
            title: "Model Deployment",
            description:
              "This course will teach you how to deploy your machine learning models using Docker, Kubernetes, etc.",
          },
        ]
      },
      {
        id: 2,
        title: "Data Structures & Algorithms",
        description: "Master essential data structures and algorithms concepts required to crack interviews for sde roles of your dream companies through a curated set of DS/Algo problems, live classes and dedicated sessions for problem discussions",
        topics: [
          {
            id: 0,
            title: "Module 1",
            description:
              "In this module we will start with introduction to DSA and learn Arrays, String and Search Algorithms",
          },
          {
            id: 1,
            title: "Module 2",
            description:
              "In this module we will learn sorting, arrays related algorithm techniques",
          },
          {
            id: 2,
            title: "Module 3",
            description:
              "In this learn stack, recursion, queue, deque, standard template library, linkedlist",
          },
          {
            id: 3,
            title: "Module 4",
            description:
              "In this module we will learn dynamic programming, divide conquer, pattern matching algorithms, tree and graph",
          },
        ]
      },
      {
        id: 3,
        title: "Business Intelligence",
        description: "This Business Intelligence module will teach us about most in-demand Business Intelligence tool Tableau, advanced Excel for Data Analysis and Problem solving skills using business case studies.",
        topics: [
          {
            id: 0,
            title: "Business Intelligence Tool Tableau",
            description:
              "We will learn data visualisation techniques using the most in-demand Business Intelligence tool Tableau in the industry. Also we will understand how to effectively communicate, and fine grain your data analysis projects and understand how to optimally present your findings to technical and non-technical stakeholders and upgrade your storytelling skills.",
          },
          {
            id: 1,
            title: "Data Analysis in Excel",
            description:
              "We will learn the basic and advanced functionalities of Excel and data manipulation and analysis to solve business problems using case studies.",
          },
          {
            id: 2,
            title: " Business Case Studies",
            description:
              "We will learn structured problem skills and methods to address real time business problem with case studies. We will also learn to pose a Data science problem in a business scenario.",
          },
          {
            id: 3,
            title: "BFSI ML Applications",
            description:
              "We learn how does BFSI sector works and how does it leverage ML for its business enhancements through business use cases in this sector. ",
          },
        ]
      },
      {
        id: 4,
        title: "Artificial Intelligence",
        description: "The next module is the Artificial Intelligence which will teach us from the introduction to Artificial Intelligence to taking us beyond the traditional ML into Neural Nets’ realm. We move on to training our models with Unstructured Data like Text and Images from the regular tabular data.",
        topics: [
          {
            id: 0,
            title: "Neural Networks and Deep Learning",
            description:
              "In this Artificial Intelligence Module, we start with the motive behind using the terms Neural network and look at the individual constituents of a neural network. Installation of and building familiarity with TensorFlow library, appreciate the simplicity of Keras and build a deep neural network model for a classification problem using Keras. We also learn how to tune a Deep Neural Network.",
          },
          {
            id: 1,
            title: "Computer Vision",
            description:
              "In this Computer Vision course, we will learn how to process and work with images for Image classification using Neural Networks. Going beyond plain Neural Networks, we will also learn a more advanced architecture - Convolutional Neural Networks.",
          },
          {
            id: 2,
            title: "Natural Language Processing",
            description:
              "Learn how to work with natural language processing with Python using traditional machine learning methods. Then, deep dive into the realm of Sequential Models and state of the art language models.",
          },
        ]
      }
    ],

    skills_learned: [
      aiskill1,
      aiskill2,
      aiskill3,
      aiskill4,
      aiskill5,
      aiskill6,
      aiskill7,
      aiskill8,
      aiskill9,
      aiskill10,
      aiskill11,
      aiskill12,
      aiskill13,
      aiskill14,
      aiskill15,
      aiskill16,
    ],

    learners_review: [
      {
        id: 0,
        name: "Gayathree Dulam",
        batch: "ECE",
        img: gayathree,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/gayathree-dulam-b69102198/",
        review:
          "It was really a great learning experience during the program . We learnt a lot of concepts related to data science and data structures from Instructors who are working in Microsoft, Amazon, Flipkart, Walmart, Siemens, Cigna and other MNCs through live sessions which made us well equipped for the industry. The Instructors were always more than happy to solve the doubts we had during sessions. Instructors have a lot of patience and depth in concepts with huge industry experience. The quality instructor led live sessions is the best thing happened for me to program and entire ecosystem helped me to get into Amazon. I would recommend Surge Classes on any day."
      },
      {
        id: 1,
        name: "Manthina Sriram Varma",
        batch: "CSE",
        img: sriram,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/manthina-sriram-varma-804632169/",
        review:
          "I have enrolled to the program for aiming high package jobs. Overall it was a very good program with many modules which were related to good placements. The teaching was good, we were given opportunity to learn thoroughly in every concept through live classes as well as good doubt clarification sessions. The best thing happened to me in the program is mock interviews by industry experts from working in Goldmansachs, Amazon, American Express, Wells Fargo and Siemens which played significant role in getting offer from Amazon at very high package.  Surge Classes is very good choice which I would recommend who want to make big."
      },
      {
        id: 2,
        name: "Surekha Dasari",
        batch: "ECE",
        img: surekha,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/surekha-dasari-5b9366204/",
        review:
          "Overall learning experience was good and the set of mentors who had great industrial knowledge is the top aspect of surge classes. Thanks for teaching us the data science and coding things patiently and motivating us to work hard and grow in life. They have guided us specific to certain company interviews even on odd times and on short notice."
      },
      {
        id: 3,
        name: "Likhith Sai kumar",
        batch: "Mech",
        img: likhith,
        company: tcs,
        linkedin: "https://www.linkedin.com/in/likhith-sai-kumar-730294190/",
        review:
          "The experience was amazing and the design of program was very good. we had done hands-on projects on every data science and coding module which gave me better understanding of Data science eco systems. The mentors were great they made us understand every concept they gave in depth knowledge of concepts and often clarified our doubts in detailed way. This program helped me to get the offers from TCS Digital and Gain Sight in Campus Placement. Being a Mechanical Engineer this is quite accomplishing. "
      },
      {
        id: 4,
        name: "Jagadeesh Medapati",
        batch: "Electrical",
        img: jagdeesh,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/jagadeesh-medapati/",
        review: "I was completely satisfied with the duration of the programme, Instructors, course lectures as well as projects we have built on various modules. I'd be recommending Surge Classes as anyone can learn the Data science concepts easily from scratch with that mentorship and Live classes."
      },
      {
        id: 5,
        name: "Lavanya Narayanam",
        batch: "IT",
        img: lavanya,
        company: axisbank,
        linkedin: "https://www.linkedin.com/in/narayanam-loka-lavanya/",
        review:
          "It was a pretty good program where I learned machine learning from scratch. The course structure and the hands-on projects are really beneficial to start a career in data science field and make big. The way of explanation from the trainers is easy to understand and they clarify our doubts well. This program helped me to build a profile with ML, NLP and Deep Learning projects on various domains. This quaility Portfolio built in the program helped me to get into Axis Bank in Data Science role. "
      },
      {
        id: 6,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],


    faqs: [
      {
        "title": "What is Data Science and AI?",
        "description": "Data Science and Artificial Intelligence (AI) involve the extraction of insights and patterns from data to create intelligent algorithms and models that mimic human cognitive functions and decision-making."
      },
      {
        "title": "Why are Data Science and AI important?",
        "description": "Data Science and AI play a critical role in transforming data into valuable insights for informed decision-making. They enable automation, predictive analysis, and innovative solutions across various industries."
      },
      {
        "title": "Is prior technical experience necessary for the program?",
        "description": "While some technical background can be advantageous, our Data Science and AI program is designed to accommodate learners from diverse academic and professional backgrounds. We offer comprehensive resources and guidance."
      },
      {
        "title": "What topics are covered in the program?",
        "description": "Our program covers a wide range of topics, including machine learning, data analysis, neural networks, natural language processing, and more. You'll gain a holistic understanding of the Data Science and AI landscape."
      },
      {
        "title": "Are there any prerequisites for enrollment?",
        "description": "Basic knowledge of programming and statistics is recommended, but not mandatory. The program provides foundational learning resources to help participants build the necessary skills."
      }
    ],

    fees: 60000,
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2023",
    instructor: [
      {
        id: 0,
        name: "Srikanth Annibathina",
        designation: "Analytics Manager, Cognizant",
        excompanies: {
          cgi: cgi,
          cigna: cigna
        },
        img: srikanth,
        organisation: "VTU, KARNATAKA",
        linkedinurl: "https://www.linkedin.com/in/srikanth-annabathina-7842a9188/"
      },
      {
        id: 1,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
      {
        id: 2,
        name: "Shubham Jaiswal",
        designation: "Analytics Analyst, McKinsey",
        excompanies: {
          wilistowerwatson: wilistowerwatson
        },
        img: shubham,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/shubham-jaiswal-0aa5a65b/"
      },
      {
        id: 3,
        name: "Akash Pandey",
        designation: "Manager, Times Internet",
        excompanies: {
          amex: amex,
          exl: exl
        },
        img: akash,
        organisation: "IIT KANPUR",
        linkedinurl: "https://www.linkedin.com/in/akash-pandey-676307108/"
      },
      {
        id: 4,
        name: "Abhishek Potnis",
        designation: "R&D Associate in ML, Oak Ridge",
        excompanies: {
          iitb: iitb
        },
        img: abhishek,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/abhishekpotnis/"
      },
    ]
  },
  {
    id: 4,
    title: "MERN Full Stack \nPro Program",
    code: "MERN-Full-Stack-Pro-Program",
    tag: "For working professionals",
    type: "Pro Program",
    description: "Advance your career in full-stack development by working on industry standard tech projects and building new-age applications. Gain real work experience and master Javascript, React, NodeJS, Express, MongoDB database and more to land a top career as a full-stack developer.",
    syllabus_download_link: "/brochures/mernfullstackprogram.pdf",
    deadline: "30 Sept,2023",
    cover: mernprocov,
    course_overview: [
      {
        title: "100% Online",
        description: "Live Interactive Sessions"
      }, {
        title: "3 months",
        description: "100+ hours of live content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Succeed as a new-age Full-Stack Developer with our comprehensive project-based learning program. Upskill yourself in JavaScript, React, Express, NodeJS, MongoDB and more through industry standard projects, hands-on activities, and immersive workshops.",
      features: [
        {
          title: "Live Interactive Sessions",
          icon: LiveIcon,
          description: "Well-structured live interactive sessions from industry seasoned IT Experts and access to Session Recordings."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "12+ hands-on micro-skilling exercises to learn CS fundamentals and achieve in-depth understanding of developer skills."
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "5+ industry-standard projects from various domains, with personalised mentorship, to master developer skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "12+ Programming tools and technologies to be a self sufficient Developer to meet current day requirements."
        }
      ],
    },

    syllabus: [
      {
        id: 0,
        title: "Build SurgeKart(Static)",
        description: "Build a responsive front end for SurgeKart, a FlipKart like website and learn HTML, CSS, Bootstrap and more.",
        topics: [
          {
            id: 0,
            title: "HTML",
            description: "Master HTML elements, tags and attributes by using them to structure the web pages of SurgeKart",
          },
          {
            id: 1,
            title: "CSS, FlexBox",
            description: "Learn CSS and Flexbox by utilizing them to enhance the layout and position of various elements on the web page.",
          },
          {
            id: 2,
            title: "Bootstrap",
            description: "Learn Bootstrap by using it to design a mobile-first responsive web page.",
          }
        ]
      },
      {
        id: 1,
        title: "Web Developer Essentials",
        description: "Learn essential skills that form the foundation of any successful modern web developer.",
        topics: [
          {
            id: 0,
            title: "HTTP, REST API",
            description: "Learn about HTTP and REST APIs and understand how apps communicate with their backend by tweeting from a Linux Terminal using Twitter APIs.",
          },
          {
            id: 1,
            title: "LINUX",
            description: "Learn to use a Linux machine and get familiar with commonly used developer workflows by performing elementary data analysis using Linux.",
          },
          {
            id: 2,
            title: "Cloud Deployment",
            description: "Get introduced to Cloud Deployment by actually deploying a real web app to a cloud-based linux server.",
          }
        ]
      },
      {
        id: 2,
        title: "Build SurgeKart(Dynamic)",
        description: "Learn to make API calls by using it to enhance SurgeKart and make it dynamic.",
        topics: [
          {
            id: 0,
            title: "API Calls",
            description: "Learn to make API calls by using it to enhance SurgeKart and make it dynamic.",
          },
          {
            id: 1,
            title: "Routing",
            description: "Learn about DOM Manipulation by actually performing it with data fetched from API calls.",
          },
          {
            id: 2,
            title: "Data Persistence",
            description: "Learn about DOM Manipulation by actually performing it with data fetched from API calls.",
          },
          {
            id: 3,
            title: "JavaScript",
            description: "Learn various concepts of JavaScript such as filters, map ,etc that will be useful in creating dynamic website"
          },
          {
            id: 4,
            title: "Third Party Libraries",
            description: "Learn to integrate with 3rd party libraries by developing engaging new features like galleries in SurgeKart."
          },
          {
            id: 5,
            title: "Surgeshorts",
            description: "Apply your learnings from SurgeKart Static and SurgeKart Dynamic to build your first independent project, Surgeshorts- a newsfeed that aggregates articles from popular media outlets."
          }
        ]
      },
      {
        id: 3,
        title: "Build SurgeKart(frontend)",
        description: "Build the frontend for SurgeKart - a rich shopping app, using React, and create a rich shopping experience",
        topics: [
          {
            id: 0,
            title: "Component",
            description: "Component, Component life Cycle, Component state management, Component Styling, Props, Routing, Global context."
          },
          {
            id: 1,
            title: "Component LifeCycle",
            description: "Dive deeper in understanding component state and managing component lifecycle.",
          },
          {
            id: 2,
            title: "Component State Management",
            description: "Dive deeper in understanding component state and managing component lifecycle.",
          },
          {
            id: 3,
            title: "Component styling",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart.",
          },
          {
            id: 4,
            title: "Props",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart.",
          },
          {
            id: 5,
            title: "Routing",
            description: "Implement frontend routing and React routes in SurgeKart.",
          },
          {
            id: 6,
            title: "Global Context",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart",
          },
          {
            id: 7,
            title: "Build SurgeFlix(Frontend)",
            description: "Apply your learnings from SurgeKart Frontend to build your second independent project using React, the frontend for SurgeFlix - a video-streaming platform like Netflix.",
          },
        ]
      },
      {
        id: 4,
        title: "Build SurgeKart(backend)",
        description: "Build a scalabe NodeJS backend for SurgeKart using the Express.js",
        topics: [
          {
            id: 0,
            title: "Express.js",
            description:
              "Get started with Express.js framework and create REST APIs for SurgeKart with custom routes and request validation.",
          },
          {
            id: 1,
            title: "Node.js",
            description: "Build a scalable NodeJS backend for SurgeKart using the Express.js framework.",
          },
          {
            id: 2,
            title: "MongoDB",
            description: "Learn MongoDB by using it to introduce a database layer in SurgeKart.",
          },
          {
            id: 3,
            title: "REST API",
            description: "Add authentication to secure the REST APIs you have implemented.",
          },
          {
            id: 4,
            title: "Build SurgeFlix(Backend)",
            description: "Apply your learnings from SurgeKart Backend to build your third independent project using Express and Node JS, the backend for SurgeFlix - a video streaming platform like Netflix.",
          }
        ]
      }
    ],

    skills_learned: [
      mernskill1,
      mernskill2,
      mernskill3,
      mernskill4,
      mernskill5,
      mernskill6,
      mernskill7,
      mernskill8,
      mernskill9,
      mernskill10,
      mernskill11,
      mernskill12,
      mernskill13,
      mernskill14
    ],

    learners_review: [
      {
        id: 0,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        title: "What does the MERN full stack course cover?",
        description: "Our MERN full stack course covers all aspects of building modern web applications using the MERN stack, which includes MongoDB, Express.js, React, and Node.js. You'll learn to create both the front-end and back-end components of web applications."
      },
      {
        title: "Is prior programming experience required?",
        description: "While prior programming experience can be helpful, our MERN full stack course is designed for learners of all levels. We start with the fundamentals and gradually progress to more advanced topics. Beginners are welcome!"
      },
      {
        title: "What kind of projects will I work on?",
        description: "Throughout the course, you'll work on a variety of projects that simulate real-world scenarios. These projects will help you apply what you've learned and build a solid portfolio showcasing your MERN full stack development skills."
      },
      {
        title: "Do I need to install any software?",
        description: "Yes, you'll need to have the MERN development environment set up on your computer. We provide detailed instructions on how to install and configure MongoDB, Express.js, React, and Node.js. Our course also includes guidance on setting up code editors and tools."
      },
      {
        title: "How is the course structured?",
        description: "The course is structured into modules that cover different aspects of the MERN stack. Each module includes video lectures, hands-on exercises, and coding challenges. You'll have access to our online learning platform where you can track your progress and interact with instructors and fellow learners."
      }
    ],

    fees: 30000,
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2023",
    instructor: [
      {
        id: 1,
        name: "Priyank Srivastav",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          skillgigs: skillgigs,
          policyboss: policyboss
        },
        img: priyank,
        organisation: "IIT ROORKEE",
        linkedinurl: "https://www.linkedin.com/in/thepriyank/"
      },
      {
        id: 2,
        name: "Vikas Srivastava",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          flipkart: flipkart,
          acolite: acolite,
        },
        img: vikas,
        organisation: "IIT DELHI",
        linkedinurl: "https://www.linkedin.com/in/vikasjk/"
      },

      {
        id: 3,
        name: "Debjit Sinha",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          acolite: acolite,
          geotech: geotech
        },
        img: debjit,
        organisation: "SENIOR SDE, VIACOM18",
        linkedinurl: "https://www.linkedin.com/in/frontend-ninja/"
      },
      {
        id: 4,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
    ]
  },
];

export const SelfPacedProgram = [
  {
    id: 5,
    title: "Data Science and AI \nSelf Paced Program",
    code: "Data-science-and-AI-self-paced-program",
    tag: "",
    type: "Self Paced Program",
    description: "<li>Build a top profile in Data Science and AI by working on both foundational and advanced concepts for building new-age AI Applications.</li><li>Gain real time work experience through project based learning along side mastering Python, SQL,Tableau, Machine Learning, NLP, Deep Learning, Computer Vision and GenAI to advance your career in Data Science and AI.</li>",
    syllabus_download_link: "/brochures/datascienceandaiselfpacedprogram.pdf",
    deadline: "30 Sept,2024",
    // cover: dsaiselfpacedcov,
    course_certificate: selfpaceprogramcert,
    discount: 50,
    payment_page_info: {
      course_name: "Data Science and AI Self Paced Program",
      course_description: "Build a top profile in Data Science and AI by working on both foundational and advanced concepts for building new-age AI Applications.",
      about_course: [
        "Duration : 4 months (1 year access to recordings and files)",
        "Includes 5 Modules"
      ],
      course_key_points: [
        "100% Online recorded sessions",
        "300+ hours of recorded content",
        "1 year accesses",
        "Personal mentor for doubts",
        "Weekly reviews and updates",
        "Certificate after completion"
      ],
      course_fee: 6500,
      discount: 50,
      discounted_fee: 6500,
    },
    course_overview: [
      {
        title: "100% Online",
        description: "Pre Recorded Lectures"
      }, {
        title: "1 year access",
        description: "300+ hours of recorded content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Acquire new-age Data Science Professional skills with our comprehensive project-based learning program. Upskill yourself in Python, SQL,Tableau, Machine Learning, NLP, Deep Learning, Computer Vision and GenAI through industry relevant structured curriculum, industry-standard projects ,hands-on activities, and immersive workshops.",
      features: [
        {
          title: "Pre Recorded Lectures",
          icon: LiveIcon,
          description: "Well-structured recorded sessions from industry seasoned Data Science Experts with 1 year course access."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "60+ handson micro-skilling exercises to learn Data Science concepts and achieve indepth understanding of ML Algos.",
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "30+ industry standard projects from various domains, with personalised mentorship, to master Data Science skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "30+ Programming tools and technologies to be a self sufficient Data Science Professional to meet current day requirements."
        }
      ],
    },
    p_feature: [
      {
        id: 0,
        title: "Learn by doing .industry. relevant projects",
        image: dsaisppf1,
        description_points: [
          "Projects across multiple disciplines",
          "Master your data science and AI projects",
          "Individual mentor appointed to assist with your project"
        ]
      },
      {
        id: 1,
        title: "Individual .personal mentor. will be allotted",
        image: dsaisppf2,
        description_points: [
          "Mentor assigned to discuss concepts and clear doubts",
          "Weekly in-person talks with mentors about assignments and projects",
          "Individual guidance to acquire data science skills"
        ]
      },
      {
        id: 2,
        title: "Tracking program .milestones.",
        image: dsaisppf3,
        description_points: [
          "Keeping track of objectives to ensure efficient resume and profile creation",
          "Monitoring of program milestones",
          "Using quizzes to monitor program learning"
        ]
      },
      {
        id: 3,
        title: "Most .affordable. program",
        image: dsaisppf4,
        description_points: [
          "50% discount on entire program using referral code",
          "India’s most affordable data science and AI program",
          "Most reasonably priced course with personal mentor"
        ]
      },
    ],
    syllabus: [
      {
        id: 0,
        title: "Data Science Foundations",
        description: "The Foundations module comprises five courses where we get our hands dirty with Python basics, Python coding, Python Data Science Packages, Statistics, SQL Programming and Machine Learning and Deep Learning Fundamentals getting exposure to AI terminologies. These courses set our foundations and helps us to learn essential skills so that we sail through the rest of the journey with minimal hindrance as Data Science Professional. This module also helps to apply all the acquired knowledge to extract actionable insights from the Data.",
        topics: [
          {
            id: 0,
            title: "Python for Data Science",
            description: "This course will helps us to learn the Python programming language used for Data Science. We start with python basics learn keywords, syntax and writing usable code of this language. We will have high-level idea of Object Oriented Programming required for AI and ML concepts implementations. We learn to manipulate datasets in Python using Pandas which is the most powerful library for data preparation and analysis. Also We learn to plot and interpret various graphs using Python Visualisation libraries and observe how they make data analysis and extract actionable insights.",
          },
          {
            id: 1,
            title: "Statistics",
            description:
              "We will learn fundamentals like Statistics, Probability, etc., which are key topics in Data Analytics course and also learn concepts vital to Exploratory Data Analysis and Machine Learning in general to extract actionable insights from data. We will learn to ‘infer’ insights from a huge population using a small sample and formulate and validate hypotheses for a population to solve real-life business problems.",
          },
          {
            id: 2,
            title: "SQL Programming",
            description:
              "Here, we will cover database fundamentals to everything you need to know about SQL programming, such as DBMS, Normalization, Joins, etc. we will learn to extract information from RDBMS using the structured query language and apply advanced SQL concepts like windowing and procedures to derive insights from data and answer pertinent business questions.",
          },
          {
            id: 3,
            title: "Machine Learning and Deep Learning Fundamentals ",
            description:
              "Here, we get started with Surge Classes in house tool Alpha to get exposure with ML terminologies and how ML being leveraged in Business decision making. Also build mathematical foundations required for understanding Machine Learning and Deep Learning.",
          },
          {
            id: 4,
            title: "Exploratory Data Analysis",
            description:
              "This course will teach you all about the Exploratory Data Analysis like Preprocessing, Missing values, Outliers, Transformations and Feature Engineering techniques etc.",
          },
        ]
      },
      {
        id: 1,
        title: "Machine Learning",
        description: "This Machine Learning module will teach us all the Machine Learning techniques from scratch, and the popularly used Classical ML algorithms that fall in each of the categories.",
        topics: [
          {
            id: 0,
            title: "Supervised Learning",
            description:
              "In this course we learn about Supervised ML algorithms, working of the algorithms and their scope of application - Regression and Classification.",
          },
          {
            id: 1,
            title: "Unsupervised Learning",
            description:
              "We learn what Unsupervised Learning algorithms are, working of the algorithms and their scope of application - Clustering and Dimensionality Reduction.",
          },
          {
            id: 2,
            title: "Tree Based models and Ensemble Techniques",
            description:
              "In this course, we earn about tree based models and also discuss supervised standalone models’ shortcomings and learn a few techniques, such as Ensemble techniques to overcome these shortcomings.",
          },
          {
            id: 3,
            title: "Model Selection and Tuning",
            description:
              "Learn various concepts that will be useful in creating functional machine learning models like model selection and tuning, model performance measures, ways of regularisation, etc.",
          },
          {
            id: 4,
            title: "Time Series Forecasting ",
            description:
              "This course will teach you how to predict future values based on the previously experimented values using Python.",
          },
          {
            id: 5,
            title: "Recommendation Systems",
            description:
              "In this course, we learn what recommendation systems are, their applications, critical approaches to building them - Popularity based systems, Collaborative filtering, Singular Value Decomposition, etc.",
          },
          {
            id: 6,
            title: "Model Deployment",
            description:
              "This course will teach you how to deploy your machine learning models using Docker, Kubernetes, etc.",
          },
        ]
      },
      {
        id: 2,
        title: "Business Intelligence",
        description: "This Business Intelligence module will teach us about most in-demand Business Intelligence tool Tableau, advanced Excel for Data Analysis and Problem solving skills using business case studies.",
        topics: [
          {
            id: 0,
            title: "Business Intelligence Tool Tableau",
            description:
              "We will learn data visualisation techniques using the most in-demand Business Intelligence tool Tableau in the industry. Also we will understand how to effectively communicate, and fine grain your data analysis projects and understand how to optimally present your findings to technical and non-technical stakeholders and upgrade your storytelling skills.",
          },
          {
            id: 1,
            title: "Data Analysis in Excel",
            description:
              "We will learn the basic and advanced functionalities of Excel and data manipulation and analysis to solve business problems using case studies.",
          },
          {
            id: 2,
            title: " Business Case Studies",
            description:
              "We will learn structured problem skills and methods to address real time business problem with case studies. We will also learn to pose a Data science problem in a business scenario.",
          },
          {
            id: 3,
            title: "BFSI ML Applications",
            description:
              "We learn how does BFSI sector works and how does it leverage ML for its business enhancements through business use cases in this sector. ",
          },
        ]
      },
      {
        id: 3,
        title: "Artificial Intelligence",
        description: "The next module is the Artificial Intelligence which will teach us from the introduction to Artificial Intelligence to taking us beyond the traditional ML into Neural Nets’ realm. We move on to training our models with Unstructured Data like Text and Images from the regular tabular data.",
        topics: [
          {
            id: 0,
            title: "Neural Networks and Deep Learning",
            description:
              "In this Artificial Intelligence Module, we start with the motive behind using the terms Neural network and look at the individual constituents of a neural network. Installation of and building familiarity with TensorFlow library, appreciate the simplicity of Keras and build a deep neural network model for a classification problem using Keras. We also learn how to tune a Deep Neural Network.",
          },
          {
            id: 1,
            title: "Computer Vision",
            description:
              "In this Computer Vision course, we will learn how to process and work with images for Image classification using Neural Networks. Going beyond plain Neural Networks, we will also learn a more advanced architecture - Convolutional Neural Networks.",
          },
          {
            id: 2,
            title: "Natural Language Processing",
            description:
              "Learn how to work with natural language processing with Python using traditional machine learning methods. Then, deep dive into the realm of Sequential Models and state of the art language models.",
          },
          {
            id: 3,
            title: "Generative AI",
            description:
              "In this course, we will learn how to generate AI using GANs, reinforcement learning, and other generative AI models such as GPT, ChatGPT, prompt engineering, OpenAI use cases, GANs, stable diffusion, DALL-E, MidJourney, and fine-tuning."
          }
        ]
      }
    ],
    projects: [
      {
        id: 0,
        name: "Back Orders",
        category: "Supply chain management",
        description: "Learn how predictive analytics can be used by supply chain management team to identify back orders products even before sales take place and have effective supply chain management.",
        img: p1img,
      },
      {
        id: 1,
        name: "Term Deposit",
        category: "Banking",
        description: "Use analytics to identify whether the bank customer subscribes to a term deposit or not. Research and analyse data from a marketing campaign to gain valuable insights.",
        img: p2img,
      },
      {
        id: 2,
        name: "IMDB Movie Analysis",
        category: "Entertainment",
        description: "Analyse movie data from the past hundred years and find out various insights to determine what makes a movie do well.",
        img: p3img,
      },
      {
        id: 3,
        name: "Car Prices",
        category: "Automobile",
        description: "Learning how one variable can be predicted using several other variables through a car prices dataset where you will predict the prices of car based on various factors.",
        img: p4img,
      },
      {
        id: 4,
        name: "Fraud Detection",
        category: "Banking",
        description: "Decline fraud transactions on a credit card in real time to minimize the losses so that customers are not charged for items that they did not purchase. Learners will able to build a model to recognize fraudulent credit card transactions using ML.",
        img: p5img,
      },
      {
        id: 5,
        name: "Recommendation System",
        category: "Marketing",
        description: "Learn to build a recommendation system using popularity based and collaborative filtering methods to recommend personalised products to user.",
        img: p6img,
      },
      {
        id: 6,
        name: "Telecom Churn",
        category: "Customer retention",
        description: "Telecom companies often face the problem of churning customers due to the competitive nature of the industry. Help a telecom company identify customers that are likely to churn and make data driven strategies to retain them.",
        img: p7img,
      },
      {
        id: 7,
        name: "Retail Giant Sales Forcasting",
        category: "Retail",
        description: "Help a retail-gaint to have a clear idea of how their sales will look like in the future and take informed decisions",
        img: p8img,
      },
      {
        id: 8,
        name: "Gesture Recognition",
        category: "Computer Vision",
        description: "Learners will build a gesture recognition model for a smart TV to recognise 5 hand gestures using a 3D Convolutional network",
        img: p9img,
      },
      {
        id: 9,
        name: "Image Captioning",
        category: "Computer Vision",
        description: "Build a model which will caption a given image and describe it in sentences in a detailed way like objects present the actions they are doing etc.",
        img: p10img,
      },
      {
        id: 10,
        name: "Speech Recognition",
        category: "Sound Tech",
        description: "Learn how google speech to text works and make your own model Learn to make one using deep learning by analysing the voice modulations.",
        img: p11img,
      },
      {
        id: 11,
        name: "Sentiment Analysis",
        category: "E commerce",
        description: "Learn to perform a sentiment analysis on product reviews from Amazon using NLP &amp; Machine learning. This assignment will be focused to give you a business understanding on how to do product optimisation using NLP.",
        img: p12img,
      },
      {
        id: 12,
        name: "News Article Classification",
        category: "Media",
        description: "Learn to perform topic modelling for discovering the abstract topics that occur in collection documents. Learn to use Latent Dirichlet Allocation (LDA) to classify text in a document to a particular topic.",
        img: p13img,
      },
      {
        id: 13,
        name: "Sarcasm Detection",
        category: "Social Media",
        description: "Many tweets are replies to other tweets and detecting sarcasm in these requires the availability of contextual tweets. In this hands-on project, the goal is to build a model to detect whether a sentence is sarcastic or not, using Bidirectional LSTMs.",
        img: p14img,
      },
      {
        id: 14,
        name: "Chatbot",
        category: "Ed Tech",
        description: "Imagine if you could learn Machine Learning algorithms and prepare for interview questions without opening Google. Build your own Learning Agent Chatbot with the help of RASA - an open source framework and deploy it on Slack",
        img: p15img,
      },
      {
        id: 15,
        name: "Pneumonia Identifier",
        category: "Healthcare",
        description: "To build an image classifier and object detection model which can classify an chest X ray image into with/without pneumonia disease and identify the location of the chest X ray where the disease is localised by publishing a bounding box around it.",
        img: p16img,
      },
      {
        id: 16,
        name: "Conversational Ai Chatbot",
        category: "Generative AI",
        description: "To build LLM powered conversational AI chatbot which can interview",
        img: p15img,
      },
      {
        id: 17,
        name: "Chatbot",
        category: "Generative AI",
        description: "To build LLM powered knowledge based QNA system",
        img: p15img,
      }
    ],

    skills_learned: [
      aiskill1,
      aiskill2,
      aiskill3,
      aiskill4,
      aiskill5,
      aiskill6,
      aiskill7,
      aiskill8,
      aiskill9,
      aiskill10,
      aiskill11,
      aiskill12,
      aiskill13,
      aiskill14,
      aiskill15,
      aiskill16,
      aiskill17
    ],

    learners_review: [
      {
        id: 0,
        name: "Gayathree Dulam",
        batch: "ECE",
        img: gayathree,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/gayathree-dulam-b69102198/",
        review:
          "It was really a great learning experience during the program . We learnt a lot of concepts related to data science and data structures from Instructors who are working in Microsoft, Amazon, Flipkart, Walmart, Siemens, Cigna and other MNCs through live sessions which made us well equipped for the industry. The Instructors were always more than happy to solve the doubts we had during sessions. Instructors have a lot of patience and depth in concepts with huge industry experience. The quality instructor led live sessions is the best thing happened for me to program and entire ecosystem helped me to get into Amazon. I would recommend Surge Classes on any day."
      },
      {
        id: 1,
        name: "Manthina Sriram Varma",
        batch: "CSE",
        img: sriram,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/manthina-sriram-varma-804632169/",
        review:
          "I have enrolled to the program for aiming high package jobs. Overall it was a very good program with many modules which were related to good placements. The teaching was good, we were given opportunity to learn thoroughly in every concept through live classes as well as good doubt clarification sessions. The best thing happened to me in the program is mock interviews by industry experts from working in Goldmansachs, Amazon, American Express, Wells Fargo and Siemens which played significant role in getting offer from Amazon at very high package.  Surge Classes is very good choice which I would recommend who want to make big."
      },
      {
        id: 2,
        name: "Surekha Dasari",
        batch: "ECE",
        img: surekha,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/surekha-dasari-5b9366204/",
        review:
          "Overall learning experience was good and the set of mentors who had great industrial knowledge is the top aspect of surge classes. Thanks for teaching us the data science and coding things patiently and motivating us to work hard and grow in life. They have guided us specific to certain company interviews even on odd times and on short notice."
      },
      {
        id: 3,
        name: "Likhith Sai kumar",
        batch: "Mech",
        img: likhith,
        company: tcs,
        linkedin: "https://www.linkedin.com/in/likhith-sai-kumar-730294190/",
        review:
          "The experience was amazing and the design of program was very good. we had done hands-on projects on every data science and coding module which gave me better understanding of Data science eco systems. The mentors were great they made us understand every concept they gave in depth knowledge of concepts and often clarified our doubts in detailed way. This program helped me to get the offers from TCS Digital and Gain Sight in Campus Placement. Being a Mechanical Engineer this is quite accomplishing. "
      },
      {
        id: 4,
        name: "Jagadeesh Medapati",
        batch: "Electrical",
        img: jagdeesh,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/jagadeesh-medapati/",
        review: "I was completely satisfied with the duration of the programme, Instructors, course lectures as well as projects we have built on various modules. I'd be recommending Surge Classes as anyone can learn the Data science concepts easily from scratch with that mentorship and Live classes."
      },
      {
        id: 5,
        name: "Lavanya Narayanam",
        batch: "IT",
        img: lavanya,
        company: axisbank,
        linkedin: "https://www.linkedin.com/in/narayanam-loka-lavanya/",
        review:
          "It was a pretty good program where I learned machine learning from scratch. The course structure and the hands-on projects are really beneficial to start a career in data science field and make big. The way of explanation from the trainers is easy to understand and they clarify our doubts well. This program helped me to build a profile with ML, NLP and Deep Learning projects on various domains. This quaility Portfolio built in the program helped me to get into Axis Bank in Data Science role. "
      },
      {
        id: 6,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        "title": "What is Data Science and AI?",
        "description": "Data Science and Artificial Intelligence (AI) involve the extraction of insights and patterns from data to create intelligent algorithms and models that mimic human cognitive functions and decision-making."
      },
      {
        "title": "Why are Data Science and AI important?",
        "description": "Data Science and AI play a critical role in transforming data into valuable insights for informed decision-making. They enable automation, predictive analysis, and innovative solutions across various industries."
      },
      {
        "title": "Is prior technical experience necessary for the program?",
        "description": "While some technical background can be advantageous, our Data Science and AI program is designed to accommodate learners from diverse academic and professional backgrounds. We offer comprehensive resources and guidance."
      },
      {
        "title": "What topics are covered in the program?",
        "description": "Our program covers a wide range of topics, including machine learning, data analysis, neural networks, natural language processing, and more. You'll gain a holistic understanding of the Data Science and AI landscape."
      },
      {
        "title": "Are there any prerequisites for enrollment?",
        "description": "Basic knowledge of programming and statistics is recommended, but not mandatory. The program provides foundational learning resources to help participants build the necessary skills."
      }
    ],

    fees: 13000,
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2024",
    instructor: [
      {
        id: 0,
        name: "Srikanth Annibathina",
        designation: "Analytics Manager, Cognizant",
        excompanies: {
          cgi: cgi,
          cigna: cigna
        },
        img: srikanth,
        organisation: "VTU, KARNATAKA",
        linkedinurl: "https://www.linkedin.com/in/srikanth-annabathina-7842a9188/"
      },
      {
        id: 1,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
      {
        id: 2,
        name: "Shubham Jaiswal",
        designation: "Analytics Analyst, McKinsey",
        excompanies: {
          wilistowerwatson: wilistowerwatson
        },
        img: shubham,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/shubham-jaiswal-0aa5a65b/"
      },
      {
        id: 3,
        name: "Akash Pandey",
        designation: "Manager, Times Internet",
        excompanies: {
          amex: amex,
          exl: exl
        },
        img: akash,
        organisation: "IIT KANPUR",
        linkedinurl: "https://www.linkedin.com/in/akash-pandey-676307108/"
      },
      {
        id: 4,
        name: "Abhishek Potnis",
        designation: "R&D Associate in ML, Oak Ridge",
        excompanies: {
          iitb: iitb
        },
        img: abhishek,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/abhishekpotnis/"
      },
    ]
  },
  {
    id: 6,
    title: "MERN Full Stack \nSelf Paced Program",
    code: "MERN-Full-Stack-self-paced-program",
    tag: "",
    type: "Self Paced Program",
    description: "Advance your career in full-stack development by working on industry standard tech projects and building new-age applications. Gain real work experience and master Javascript, React, NodeJS, Express, MongoDB database and more to land a top career as a full-stack developer.",
    syllabus_download_link: "/brochures/mernfullstackprogram.pdf",
    deadline: "30 Sept,2023",
    course_overview: [
      {
        title: "100% Online",
        description: "Live Interactive Sessions"
      }, {
        title: "3 months",
        description: "100+ hours of live content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Succeed as a new-age Full-Stack Developer with our comprehensive project-based learning program. Upskill yourself in JavaScript, React, Express, NodeJS, MongoDB and more through industry standard projects, hands-on activities, and immersive workshops.",
      features: [
        {
          title: "Live Interactive Sessions",
          icon: LiveIcon,
          description: "Well-structured live interactive sessions from industry seasoned IT Experts and access to Session Recordings."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "12+ hands-on micro-skilling exercises to learn CS fundamentals and achieve in-depth understanding of developer skills."
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "5+ industry-standard projects from various domains, with personalised mentorship, to master developer skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "12+ Programming tools and technologies to be a self sufficient Developer to meet current day requirements."
        }
      ],
    },

    syllabus: [
      {
        id: 0,
        title: "Build SurgeKart(Static)",
        description: "Build a responsive front end for SurgeKart, a FlipKart like website and learn HTML, CSS, Bootstrap and more.",
        topics: [
          {
            id: 0,
            title: "HTML",
            description: "Master HTML elements, tags and attributes by using them to structure the web pages of SurgeKart",
          },
          {
            id: 1,
            title: "CSS, FlexBox",
            description: "Learn CSS and Flexbox by utilizing them to enhance the layout and position of various elements on the web page.",
          },
          {
            id: 2,
            title: "Bootstrap",
            description: "Learn Bootstrap by using it to design a mobile-first responsive web page.",
          }
        ]
      },
      {
        id: 1,
        title: "Web Developer Essentials",
        description: "Learn essential skills that form the foundation of any successful modern web developer.",
        topics: [
          {
            id: 0,
            title: "HTTP, REST API",
            description: "Learn about HTTP and REST APIs and understand how apps communicate with their backend by tweeting from a Linux Terminal using Twitter APIs.",
          },
          {
            id: 1,
            title: "LINUX",
            description: "Learn to use a Linux machine and get familiar with commonly used developer workflows by performing elementary data analysis using Linux.",
          },
          {
            id: 2,
            title: "Cloud Deployment",
            description: "Get introduced to Cloud Deployment by actually deploying a real web app to a cloud-based linux server.",
          }
        ]
      },
      {
        id: 2,
        title: "Build SurgeKart(Dynamic)",
        description: "Learn to make API calls by using it to enhance SurgeKart and make it dynamic.",
        topics: [
          {
            id: 0,
            title: "API Calls",
            description: "Learn to make API calls by using it to enhance SurgeKart and make it dynamic.",
          },
          {
            id: 1,
            title: "Routing",
            description: "Learn about DOM Manipulation by actually performing it with data fetched from API calls.",
          },
          {
            id: 2,
            title: "Data Persistence",
            description: "Learn about DOM Manipulation by actually performing it with data fetched from API calls.",
          },
          {
            id: 3,
            title: "JavaScript",
            description: "Learn various concepts of JavaScript such as filters, map ,etc that will be useful in creating dynamic website"
          },
          {
            id: 4,
            title: "Third Party Libraries",
            description: "Learn to integrate with 3rd party libraries by developing engaging new features like galleries in SurgeKart."
          },
          {
            id: 5,
            title: "Surgeshorts",
            description: "Apply your learnings from SurgeKart Static and SurgeKart Dynamic to build your first independent project, Surgeshorts- a newsfeed that aggregates articles from popular media outlets."
          }
        ]
      },
      {
        id: 3,
        title: "Build SurgeKart(frontend)",
        description: "Build the frontend for SurgeKart - a rich shopping app, using React, and create a rich shopping experience",
        topics: [
          {
            id: 0,
            title: "Component",
            description: "Component, Component life Cycle, Component state management, Component Styling, Props, Routing, Global context."
          },
          {
            id: 1,
            title: "Component LifeCycle",
            description: "Dive deeper in understanding component state and managing component lifecycle.",
          },
          {
            id: 2,
            title: "Component State Management",
            description: "Dive deeper in understanding component state and managing component lifecycle.",
          },
          {
            id: 3,
            title: "Component styling",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart.",
          },
          {
            id: 4,
            title: "Props",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart.",
          },
          {
            id: 5,
            title: "Routing",
            description: "Implement frontend routing and React routes in SurgeKart.",
          },
          {
            id: 6,
            title: "Global Context",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart",
          },
          {
            id: 7,
            title: "Build SurgeFlix(Frontend)",
            description: "Apply your learnings from SurgeKart Frontend to build your second independent project using React, the frontend for SurgeFlix - a video-streaming platform like Netflix.",
          },
        ]
      },
      {
        id: 4,
        title: "Build SurgeKart(backend)",
        description: "Build a scalabe NodeJS backend for SurgeKart using the Express.js",
        topics: [
          {
            id: 0,
            title: "Express.js",
            description:
              "Get started with Express.js framework and create REST APIs for SurgeKart with custom routes and request validation.",
          },
          {
            id: 1,
            title: "Node.js",
            description: "Build a scalable NodeJS backend for SurgeKart using the Express.js framework.",
          },
          {
            id: 2,
            title: "MongoDB",
            description: "Learn MongoDB by using it to introduce a database layer in SurgeKart.",
          },
          {
            id: 3,
            title: "REST API",
            description: "Add authentication to secure the REST APIs you have implemented.",
          },
          {
            id: 4,
            title: "Build SurgeFlix(Backend)",
            description: "Apply your learnings from SurgeKart Backend to build your third independent project using Express and Node JS, the backend for SurgeFlix - a video streaming platform like Netflix.",
          }
        ]
      }
    ],

    skills_learned: [
      mernskill1,
      mernskill2,
      mernskill3,
      mernskill4,
      mernskill5,
      mernskill6,
      mernskill7,
      mernskill8,
      mernskill9,
      mernskill10,
      mernskill11,
      mernskill12,
      mernskill13,
      mernskill14
    ],

    learners_review: [
      {
        id: 0,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        title: "What does the MERN full stack course cover?",
        description: "Our MERN full stack course covers all aspects of building modern web applications using the MERN stack, which includes MongoDB, Express.js, React, and Node.js. You'll learn to create both the front-end and back-end components of web applications."
      },
      {
        title: "Is prior programming experience required?",
        description: "While prior programming experience can be helpful, our MERN full stack course is designed for learners of all levels. We start with the fundamentals and gradually progress to more advanced topics. Beginners are welcome!"
      },
      {
        title: "What kind of projects will I work on?",
        description: "Throughout the course, you'll work on a variety of projects that simulate real-world scenarios. These projects will help you apply what you've learned and build a solid portfolio showcasing your MERN full stack development skills."
      },
      {
        title: "Do I need to install any software?",
        description: "Yes, you'll need to have the MERN development environment set up on your computer. We provide detailed instructions on how to install and configure MongoDB, Express.js, React, and Node.js. Our course also includes guidance on setting up code editors and tools."
      },
      {
        title: "How is the course structured?",
        description: "The course is structured into modules that cover different aspects of the MERN stack. Each module includes video lectures, hands-on exercises, and coding challenges. You'll have access to our online learning platform where you can track your progress and interact with instructors and fellow learners."
      }
    ],

    fees: 7500,
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2023",
    instructor: [
      {
        id: 1,
        name: "Priyank Srivastav",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          skillgigs: skillgigs,
          policyboss: policyboss
        },
        img: priyank,
        organisation: "IIT ROORKEE",
        linkedinurl: "https://www.linkedin.com/in/thepriyank/"
      },
      {
        id: 2,
        name: "Vikas Srivastava",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          flipkart: flipkart,
          acolite: acolite,
        },
        img: vikas,
        organisation: "IIT DELHI",
        linkedinurl: "https://www.linkedin.com/in/vikasjk/"
      },

      {
        id: 3,
        name: "Debjit Sinha",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          acolite: acolite,
          geotech: geotech
        },
        img: debjit,
        organisation: "SENIOR SDE, VIACOM18",
        linkedinurl: "https://www.linkedin.com/in/frontend-ninja/"
      },
      {
        id: 4,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
    ]
  },
];

export const LiveBatches = [
  {
    id: 8,
    title: "Data science and AI Program",
    code: "Data-science-and-AI-Live-program",
    tag: "",
    type: "Live Batches",
    description: "Build a top profile in Data Science, AI by working on both foundational and advanced concepts for building new-age AI Applications. Designed especially for college students and freshers, gain real time work experience along side mastering Python, SQL, Tableau, Machine Learning, NLP and Deep Learning to advance your career in Data Science. This Program also comes with a dedicated module On Data Structures and Algorithms.",
    syllabus_download_link: "/brochures/datascienceandaimasterprogram.pdf",
    deadline: "30 Sept,2023",
    course_overview: [
      {
        title: "100% Online",
        description: "Live Interactive Sessions"
      }, {
        title: "6 months",
        description: "300+ hours of live content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Acquire new-age Data Science Professional skills with our comprehensive project-based learning program. Upskill yourself in Python, SQL, Tableau, Machine Learning, NLP and Deep Learning through industry relevant structured curriculum, industry-standard projects , hands-on activities, and immersive workshops.",
      features: [
        {
          title: "Live Interactive Sessions",
          icon: LiveIcon,
          description: "Well-structured live interactive sessions from industry seasoned IT Experts and access to Session Recordings."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "50+ handson micro-skilling exercises to learn Data Science concepts and achieve indepth understanding of ML Algos.",
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "20+ industry standard projects from various domains, with personalised mentorship, to master Data Science skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "20+ Programming tools and technologies to be a self sufficient Data Science Professional to meet current day requirements."
        }
      ],
    },

    syllabus: [
      {
        id: 0,
        title: "Data Science Foundations",
        description: "The Foundations module comprises five courses where we get our hands dirty with Python basics, Python coding, Python Data Science Packages, Statistics, SQL Programming and Machine Learning and Deep Learning Fundamentals getting exposure to AI terminologies. These courses set our foundations and helps us to learn essential skills so that we sail through the rest of the journey with minimal hindrance as Data Science Professional. This module also helps to apply all the acquired knowledge to extract actionable insights from the Data.",
        topics: [
          {
            id: 0,
            title: "Python for Data Science",
            description: "This course will helps us to learn the Python programming language used for Data Science. We start with python basics learn keywords, syntax and writing usable code of this language. We will have high-level idea of Object Oriented Programming required for AI and ML concepts implementations. We learn to manipulate datasets in Python using Pandas which is the most powerful library for data preparation and analysis. Also We learn to plot and interpret various graphs using Python Visualisation libraries and observe how they make data analysis and extract actionable insights.",
          },
          {
            id: 1,
            title: "Statistics",
            description:
              "We will learn fundamentals like Statistics, Probability, etc., which are key topics in Data Analytics course and also learn concepts vital to Exploratory Data Analysis and Machine Learning in general to extract actionable insights from data. We will learn to ‘infer’ insights from a huge population using a small sample and formulate and validate hypotheses for a population to solve real-life business problems.",
          },
          {
            id: 2,
            title: "SQL Programming",
            description:
              "Here, we will cover database fundamentals to everything you need to know about SQL programming, such as DBMS, Normalization, Joins, etc. we will learn to extract information from RDBMS using the structured query language and apply advanced SQL concepts like windowing and procedures to derive insights from data and answer pertinent business questions.",
          },
          {
            id: 3,
            title: "Machine Learning and Deep Learning Fundamentals ",
            description:
              "Here, we get started with Surge Classes in house tool Alpha to get exposure with ML terminologies and how ML being leveraged in Business decision making. Also build mathematical foundations required for understanding Machine Learning and Deep Learning.",
          },
          {
            id: 4,
            title: "Exploratory Data Analysis",
            description:
              "This course will teach you all about the Exploratory Data Analysis like Preprocessing, Missing values, Outliers, Transformations and Feature Engineering techniques etc.",
          },
        ]
      },
      {
        id: 1,
        title: "Machine Learning",
        description: "This Machine Learning module will teach us all the Machine Learning techniques from scratch, and the popularly used Classical ML algorithms that fall in each of the categories.",
        topics: [
          {
            id: 0,
            title: "Supervised Learning",
            description:
              "In this course we learn about Supervised ML algorithms, working of the algorithms and their scope of application - Regression and Classification.",
          },
          {
            id: 1,
            title: "Unsupervised Learning",
            description:
              "We learn what Unsupervised Learning algorithms are, working of the algorithms and their scope of application - Clustering and Dimensionality Reduction.",
          },
          {
            id: 2,
            title: "Tree Based models and Ensemble Techniques",
            description:
              "In this course, we earn about tree based models and also discuss supervised standalone models’ shortcomings and learn a few techniques, such as Ensemble techniques to overcome these shortcomings.",
          },
          {
            id: 3,
            title: "Model Selection and Tuning",
            description:
              "Learn various concepts that will be useful in creating functional machine learning models like model selection and tuning, model performance measures, ways of regularisation, etc.",
          },
          {
            id: 4,
            title: "Time Series Forecasting ",
            description:
              "This course will teach you how to predict future values based on the previously experimented values using Python.",
          },
          {
            id: 5,
            title: "Recommendation Systems",
            description:
              "In this course, we learn what recommendation systems are, their applications, critical approaches to building them - Popularity based systems, Collaborative filtering, Singular Value Decomposition, etc.",
          },
          {
            id: 6,
            title: "Model Deployment",
            description:
              "This course will teach you how to deploy your machine learning models using Docker, Kubernetes, etc.",
          },
        ]
      },
      {
        id: 2,
        title: "Business Intelligence",
        description: "This Business Intelligence module will teach us about most in-demand Business Intelligence tool Tableau, advanced Excel for Data Analysis and Problem solving skills using business case studies.",
        topics: [
          {
            id: 0,
            title: "Business Intelligence Tool Tableau",
            description:
              "We will learn data visualisation techniques using the most in-demand Business Intelligence tool Tableau in the industry. Also we will understand how to effectively communicate, and fine grain your data analysis projects and understand how to optimally present your findings to technical and non-technical stakeholders and upgrade your storytelling skills.",
          },
          {
            id: 1,
            title: "Data Analysis in Excel",
            description:
              "We will learn the basic and advanced functionalities of Excel and data manipulation and analysis to solve business problems using case studies.",
          },
          {
            id: 2,
            title: " Business Case Studies",
            description:
              "We will learn structured problem skills and methods to address real time business problem with case studies. We will also learn to pose a Data science problem in a business scenario.",
          },
          {
            id: 3,
            title: "BFSI ML Applications",
            description:
              "We learn how does BFSI sector works and how does it leverage ML for its business enhancements through business use cases in this sector. ",
          },
        ]
      },
      {
        id: 3,
        title: "Artificial Intelligence",
        description: "The next module is the Artificial Intelligence which will teach us from the introduction to Artificial Intelligence to taking us beyond the traditional ML into Neural Nets’ realm. We move on to training our models with Unstructured Data like Text and Images from the regular tabular data.",
        topics: [
          {
            id: 0,
            title: "Neural Networks and Deep Learning",
            description:
              "In this Artificial Intelligence Module, we start with the motive behind using the terms Neural network and look at the individual constituents of a neural network. Installation of and building familiarity with TensorFlow library, appreciate the simplicity of Keras and build a deep neural network model for a classification problem using Keras. We also learn how to tune a Deep Neural Network.",
          },
          {
            id: 1,
            title: "Computer Vision",
            description:
              "In this Computer Vision course, we will learn how to process and work with images for Image classification using Neural Networks. Going beyond plain Neural Networks, we will also learn a more advanced architecture - Convolutional Neural Networks.",
          },
          {
            id: 2,
            title: "Natural Language Processing",
            description:
              "Learn how to work with natural language processing with Python using traditional machine learning methods. Then, deep dive into the realm of Sequential Models and state of the art language models.",
          },
        ]
      }
    ],

    skills_learned: [
      aiskill1,
      aiskill2,
      aiskill3,
      aiskill4,
      aiskill5,
      aiskill6,
      aiskill7,
      aiskill8,
      aiskill9,
      aiskill10,
      aiskill11,
      aiskill12,
      aiskill13,
      aiskill14,
      aiskill15,
      aiskill16,
    ],

    learners_review: [
      {
        id: 0,
        name: "Gayathree Dulam",
        batch: "ECE",
        img: gayathree,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/gayathree-dulam-b69102198/",
        review:
          "It was really a great learning experience during the program . We learnt a lot of concepts related to data science and data structures from Instructors who are working in Microsoft, Amazon, Flipkart, Walmart, Siemens, Cigna and other MNCs through live sessions which made us well equipped for the industry. The Instructors were always more than happy to solve the doubts we had during sessions. Instructors have a lot of patience and depth in concepts with huge industry experience. The quality instructor led live sessions is the best thing happened for me to program and entire ecosystem helped me to get into Amazon. I would recommend Surge Classes on any day."
      },
      {
        id: 1,
        name: "Manthina Sriram Varma",
        batch: "CSE",
        img: sriram,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/manthina-sriram-varma-804632169/",
        review:
          "I have enrolled to the program for aiming high package jobs. Overall it was a very good program with many modules which were related to good placements. The teaching was good, we were given opportunity to learn thoroughly in every concept through live classes as well as good doubt clarification sessions. The best thing happened to me in the program is mock interviews by industry experts from working in Goldmansachs, Amazon, American Express, Wells Fargo and Siemens which played significant role in getting offer from Amazon at very high package.  Surge Classes is very good choice which I would recommend who want to make big."
      },
      {
        id: 2,
        name: "Surekha Dasari",
        batch: "ECE",
        img: surekha,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/surekha-dasari-5b9366204/",
        review:
          "Overall learning experience was good and the set of mentors who had great industrial knowledge is the top aspect of surge classes. Thanks for teaching us the data science and coding things patiently and motivating us to work hard and grow in life. They have guided us specific to certain company interviews even on odd times and on short notice."
      },
      {
        id: 3,
        name: "Likhith Sai kumar",
        batch: "Mech",
        img: likhith,
        company: tcs,
        linkedin: "https://www.linkedin.com/in/likhith-sai-kumar-730294190/",
        review:
          "The experience was amazing and the design of program was very good. we had done hands-on projects on every data science and coding module which gave me better understanding of Data science eco systems. The mentors were great they made us understand every concept they gave in depth knowledge of concepts and often clarified our doubts in detailed way. This program helped me to get the offers from TCS Digital and Gain Sight in Campus Placement. Being a Mechanical Engineer this is quite accomplishing. "
      },
      {
        id: 4,
        name: "Jagadeesh Medapati",
        batch: "Electrical",
        img: jagdeesh,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/jagadeesh-medapati/",
        review: "I was completely satisfied with the duration of the programme, Instructors, course lectures as well as projects we have built on various modules. I'd be recommending Surge Classes as anyone can learn the Data science concepts easily from scratch with that mentorship and Live classes."
      },
      {
        id: 5,
        name: "Lavanya Narayanam",
        batch: "IT",
        img: lavanya,
        company: axisbank,
        linkedin: "https://www.linkedin.com/in/narayanam-loka-lavanya/",
        review:
          "It was a pretty good program where I learned machine learning from scratch. The course structure and the hands-on projects are really beneficial to start a career in data science field and make big. The way of explanation from the trainers is easy to understand and they clarify our doubts well. This program helped me to build a profile with ML, NLP and Deep Learning projects on various domains. This quaility Portfolio built in the program helped me to get into Axis Bank in Data Science role. "
      },
      {
        id: 6,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        "title": "What is Data Science and AI?",
        "description": "Data Science and Artificial Intelligence (AI) involve the extraction of insights and patterns from data to create intelligent algorithms and models that mimic human cognitive functions and decision-making."
      },
      {
        "title": "Why are Data Science and AI important?",
        "description": "Data Science and AI play a critical role in transforming data into valuable insights for informed decision-making. They enable automation, predictive analysis, and innovative solutions across various industries."
      },
      {
        "title": "Is prior technical experience necessary for the program?",
        "description": "While some technical background can be advantageous, our Data Science and AI program is designed to accommodate learners from diverse academic and professional backgrounds. We offer comprehensive resources and guidance."
      },
      {
        "title": "What topics are covered in the program?",
        "description": "Our program covers a wide range of topics, including machine learning, data analysis, neural networks, natural language processing, and more. You'll gain a holistic understanding of the Data Science and AI landscape."
      },
      {
        "title": "Are there any prerequisites for enrollment?",
        "description": "Basic knowledge of programming and statistics is recommended, but not mandatory. The program provides foundational learning resources to help participants build the necessary skills."
      }
    ],

    fees: 15000,
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2023",
    instructor: [
      {
        id: 0,
        name: "Srikanth Annibathina",
        designation: "Analytics Manager, Cognizant",
        excompanies: {
          cgi: cgi,
          cigna: cigna
        },
        img: srikanth,
        organisation: "VTU, KARNATAKA",
        linkedinurl: "https://www.linkedin.com/in/srikanth-annabathina-7842a9188/"
      },
      {
        id: 1,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
      {
        id: 2,
        name: "Shubham Jaiswal",
        designation: "Analytics Analyst, McKinsey",
        excompanies: {
          wilistowerwatson: wilistowerwatson
        },
        img: shubham,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/shubham-jaiswal-0aa5a65b/"
      },
      {
        id: 3,
        name: "Akash Pandey",
        designation: "Manager, Times Internet",
        excompanies: {
          amex: amex,
          exl: exl
        },
        img: akash,
        organisation: "IIT KANPUR",
        linkedinurl: "https://www.linkedin.com/in/akash-pandey-676307108/"
      },
      {
        id: 4,
        name: "Abhishek Potnis",
        designation: "R&D Associate in ML, Oak Ridge",
        excompanies: {
          iitb: iitb
        },
        img: abhishek,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/abhishekpotnis/"
      },
    ]
  },
  {
    id: 9,
    title: "MERN Full Stack Program",
    code: "MERN-Full-Stack-Live-Program",
    tag: "For working professionals",
    type: "Live Batches",
    description: "Boost your college’s premium placements by 2x. Elevate your campus placements by adding 200+ hiring partners. Expand your campus recruitment profile by adding different kind of roles.",
    syllabus_download_link: "/brochures/mernfullstackprogram.pdf",
    deadline: "30 Sept,2023",
    course_overview: [
      {
        title: "100% Online",
        description: "Live Interactive Sessions"
      }, {
        title: "3 months",
        description: "100+ hours of live content"
      }, {
        title: "Module Certifications",
        description: "No Pre requisites"
      },
    ],
    about_program: {
      description: "Succeed as a new-age Full-Stack Developer with our comprehensive project-based learning program. Upskill yourself in JavaScript, React, Express, NodeJS, MongoDB and more through industry standard projects, hands-on activities, and immersive workshops.",
      features: [
        {
          title: "Live Interactive Sessions",
          icon: LiveIcon,
          description: "Well-structured live interactive sessions from industry seasoned IT Experts and access to Session Recordings."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "12+ hands-on micro-skilling exercises to learn CS fundamentals and achieve in-depth understanding of developer skills."
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "5+ industry-standard projects from various domains, with personalised mentorship, to master developer skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "12+ Programming tools and technologies to be a self sufficient Developer to meet current day requirements."
        }
      ],
    },

    syllabus: [
      {
        id: 0,
        title: "Build SurgeKart(Static)",
        description: "Build a responsive front end for SurgeKart, a FlipKart like website and learn HTML, CSS, Bootstrap and more.",
        topics: [
          {
            id: 0,
            title: "HTML",
            description: "Master HTML elements, tags and attributes by using them to structure the web pages of SurgeKart",
          },
          {
            id: 1,
            title: "CSS, FlexBox",
            description: "Learn CSS and Flexbox by utilizing them to enhance the layout and position of various elements on the web page.",
          },
          {
            id: 2,
            title: "Bootstrap",
            description: "Learn Bootstrap by using it to design a mobile-first responsive web page.",
          }
        ]
      },
      {
        id: 1,
        title: "Web Developer Essentials",
        description: "Learn essential skills that form the foundation of any successful modern web developer.",
        topics: [
          {
            id: 0,
            title: "HTTP, REST API",
            description: "Learn about HTTP and REST APIs and understand how apps communicate with their backend by tweeting from a Linux Terminal using Twitter APIs.",
          },
          {
            id: 1,
            title: "LINUX",
            description: "Learn to use a Linux machine and get familiar with commonly used developer workflows by performing elementary data analysis using Linux.",
          },
          {
            id: 2,
            title: "Cloud Deployment",
            description: "Get introduced to Cloud Deployment by actually deploying a real web app to a cloud-based linux server.",
          }
        ]
      },
      {
        id: 2,
        title: "Build SurgeKart(Dynamic)",
        description: "Learn to make API calls by using it to enhance SurgeKart and make it dynamic.",
        topics: [
          {
            id: 0,
            title: "API Calls",
            description: "Learn to make API calls by using it to enhance SurgeKart and make it dynamic.",
          },
          {
            id: 1,
            title: "Routing",
            description: "Learn about DOM Manipulation by actually performing it with data fetched from API calls.",
          },
          {
            id: 2,
            title: "Data Persistence",
            description: "Learn about DOM Manipulation by actually performing it with data fetched from API calls.",
          },
          {
            id: 3,
            title: "JavaScript",
            description: "Learn various concepts of JavaScript such as filters, map ,etc that will be useful in creating dynamic website"
          },
          {
            id: 4,
            title: "Third Party Libraries",
            description: "Learn to integrate with 3rd party libraries by developing engaging new features like galleries in SurgeKart."
          },
          {
            id: 5,
            title: "Surgeshorts",
            description: "Apply your learnings from SurgeKart Static and SurgeKart Dynamic to build your first independent project, Surgeshorts- a newsfeed that aggregates articles from popular media outlets."
          }
        ]
      },
      {
        id: 3,
        title: "Build SurgeKart(frontend)",
        description: "Build the frontend for SurgeKart - a rich shopping app, using React, and create a rich shopping experience",
        topics: [
          {
            id: 0,
            title: "Component",
            description: "Component, Component life Cycle, Component state management, Component Styling, Props, Routing, Global context."
          },
          {
            id: 1,
            title: "Component LifeCycle",
            description: "Dive deeper in understanding component state and managing component lifecycle.",
          },
          {
            id: 2,
            title: "Component State Management",
            description: "Dive deeper in understanding component state and managing component lifecycle.",
          },
          {
            id: 3,
            title: "Component styling",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart.",
          },
          {
            id: 4,
            title: "Props",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart.",
          },
          {
            id: 5,
            title: "Routing",
            description: "Implement frontend routing and React routes in SurgeKart.",
          },
          {
            id: 6,
            title: "Global Context",
            description: "Get started with React components and props to create a modular frontend interface for SurgeKart",
          },
          {
            id: 7,
            title: "Build SurgeFlix(Frontend)",
            description: "Apply your learnings from SurgeKart Frontend to build your second independent project using React, the frontend for SurgeFlix - a video-streaming platform like Netflix.",
          },
        ]
      },
      {
        id: 4,
        title: "Build SurgeKart(backend)",
        description: "Build a scalabe NodeJS backend for SurgeKart using the Express.js",
        topics: [
          {
            id: 0,
            title: "Express.js",
            description:
              "Get started with Express.js framework and create REST APIs for SurgeKart with custom routes and request validation.",
          },
          {
            id: 1,
            title: "Node.js",
            description: "Build a scalable NodeJS backend for SurgeKart using the Express.js framework.",
          },
          {
            id: 2,
            title: "MongoDB",
            description: "Learn MongoDB by using it to introduce a database layer in SurgeKart.",
          },
          {
            id: 3,
            title: "REST API",
            description: "Add authentication to secure the REST APIs you have implemented.",
          },
          {
            id: 4,
            title: "Build SurgeFlix(Backend)",
            description: "Apply your learnings from SurgeKart Backend to build your third independent project using Express and Node JS, the backend for SurgeFlix - a video streaming platform like Netflix.",
          }
        ]
      }
    ],

    skills_learned: [
      mernskill1,
      mernskill2,
      mernskill3,
      mernskill4,
      mernskill5,
      mernskill6,
      mernskill7,
      mernskill8,
      mernskill9,
      mernskill10,
      mernskill11,
      mernskill12,
      mernskill13,
      mernskill14
    ],

    learners_review: [
      {
        id: 0,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        title: "What does the MERN full stack course cover?",
        description: "Our MERN full stack course covers all aspects of building modern web applications using the MERN stack, which includes MongoDB, Express.js, React, and Node.js. You'll learn to create both the front-end and back-end components of web applications."
      },
      {
        title: "Is prior programming experience required?",
        description: "While prior programming experience can be helpful, our MERN full stack course is designed for learners of all levels. We start with the fundamentals and gradually progress to more advanced topics. Beginners are welcome!"
      },
      {
        title: "What kind of projects will I work on?",
        description: "Throughout the course, you'll work on a variety of projects that simulate real-world scenarios. These projects will help you apply what you've learned and build a solid portfolio showcasing your MERN full stack development skills."
      },
      {
        title: "Do I need to install any software?",
        description: "Yes, you'll need to have the MERN development environment set up on your computer. We provide detailed instructions on how to install and configure MongoDB, Express.js, React, and Node.js. Our course also includes guidance on setting up code editors and tools."
      },
      {
        title: "How is the course structured?",
        description: "The course is structured into modules that cover different aspects of the MERN stack. Each module includes video lectures, hands-on exercises, and coding challenges. You'll have access to our online learning platform where you can track your progress and interact with instructors and fellow learners."
      }
    ],

    fees: 7500,
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2023",
    instructor: [
      {
        id: 1,
        name: "Priyank Srivastav",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          skillgigs: skillgigs,
          policyboss: policyboss
        },
        img: priyank,
        organisation: "IIT ROORKEE",
        linkedinurl: "https://www.linkedin.com/in/thepriyank/"
      },
      {
        id: 2,
        name: "Vikas Srivastava",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          flipkart: flipkart,
          acolite: acolite,
        },
        img: vikas,
        organisation: "IIT DELHI",
        linkedinurl: "https://www.linkedin.com/in/vikasjk/"
      },

      {
        id: 3,
        name: "Debjit Sinha",
        designation: "MERN instructor, Surge Classes",
        excompanies: {
          acolite: acolite,
          geotech: geotech
        },
        img: debjit,
        organisation: "SENIOR SDE, VIACOM18",
        linkedinurl: "https://www.linkedin.com/in/frontend-ninja/"
      },
      {
        id: 4,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
    ]
  },
];

export const FreeProgram = [
  {
    id: 5,
    title: "Machine Learning Fundamentals Certification Course",
    code: "Machine-learning-fundamentals",
    tag: "Free Course for anyone",
    type: "Free Program",
    description: "All of the terminology related to machine learning will be covered in detail in this foundational course, along with topics like building custom ML models for business success and harnessing the power of ML in the BFSI industry. Get Certification over completion of program.",
    syllabus_download_link: "/brochures/datascienceandaiselfpacedprogram.pdf",
    course_certificate: mlfundamentalscert,
    payment_page_info: {
      course_name: "Machine Learning Fundamentals",
      course_description: "All of the terminology related to machine learning will be covered in detail in this foundational course, along with topics like building custom ML models for business success and harnessing the power of ML in the BFSI industry.",
      about_course: [
        "Duration : 1 months (1 month access to recordings and files)",
        "Includes 5 Modules"
      ],
      course_key_points: [
        "100% Online recorded sessions",
        "300+ hours of recorded content",
        "1 month access",
        "Personal mentor for doubts",
        "Weekly reviews and updates",
        "Certificate after completion"
      ],
      course_fee: 0,
      discount: 0,
      discounted_fee: 0,
    },
    course_overview: [
      {
        title: "100% Online",
        description: "Pre Recorded Lectures"
      }, {
        title: "1 month access",
        description: "20+ hours of recorded content"
      }, {
        title: "Course Certifications",
        description: "Get certificate after completion"
      },
    ],
    about_program: {
      description: "All of the core machine learning approaches as well as the widely used classical machine learning algorithms that fit into each category will be covered in this course.",
      features: [
        {
          title: "Pre Recorded Lectures",
          icon: LiveIcon,
          description: "Well-structured recorded sessions from industry seasoned Data Science Experts with 1 year course access."
        },
        {
          title: "Micro-Skilling Exercises",
          icon: MicroSkillIcon,
          description: "3 handson micro-skilling exercises to learn Data Science concepts and achieve indepth understanding of ML Algos.",
        },
        {
          title: "Industry Relevant Projects",
          icon: IndustryIcon,
          description: "2 industry standard projects from various domains, with personalised mentorship, to master Data Science skills."
        },
        {
          title: "Technologies and Tools",
          icon: ToolsIcon,
          description: "5 Programming tools and technologies to be a self sufficient Data Science Professional to meet current day requirements."
        }
      ],
    },
    p_feature: [
      {
        id: 0,
        title: "Learn by doing .industry. relevant projects",
        image: dsaisppf1,
        description_points: [
          "Projects across multiple disciplines",
          "Master your data science and AI projects",
          "Individual mentor appointed to assist with your project"
        ]
      },
      {
        id: 1,
        title: "Individual .personal mentor. will be allotted",
        image: dsaisppf2,
        description_points: [
          "Mentor assigned to discuss concepts and clear doubts",
          "Weekly in-person talks with mentors about assignments and projects",
          "Individual guidance to acquire data science skills"
        ]
      },
      {
        id: 2,
        title: "Tracking program .milestones.",
        image: dsaisppf3,
        description_points: [
          "Keeping track of objectives to ensure efficient resume and profile creation",
          "Monitoring of program milestones",
          "Using quizzes to monitor program learning"
        ]
      },
      {
        id: 3,
        title: "Best .free. course",
        image: dsaisppf4,
        description_points: [
          "Free course for anyone",
          "1 year free access to all course materials",
          "Free course with personal mentor"
        ]
      },
    ],
    syllabus: {
      description: "All of the terminology related to machine learning will be covered in detail in this foundational course, along with topics like building custom ML models for business success and harnessing the power of ML in the BFSI industry.",
      content: [
        {
          id: 0,
          title: "Discovering ML Marvels",
          description: "Unveiling the ML World and its Components for Business Advantage"
        },
        {
          id: 1,
          title: "Crafting Intelligent Systems",
          description: "Building Learning Systems for Optimal Business Profitability"
        },
        {
          id: 2,
          title: "Decoding ML Jargon",
          description: "Acquiring Knowledge on ML fundamentals and Terminologies, Utilizing and Building ML Template"
        },
        {
          id: 3,
          title: "Hands-On ML Magic",
          description: "Creating Learning Systems and ML Models in Action"
        },
        {
          id: 4,
          title: "Journey into AI",
          description: "Understanding Learning Mechanisms from Simple AI to OpenAI's ChatGPT",
        },
        {
          id: 5,
          title: "Path to AI Excellence",
          description: "Developing a Learning Roadmap for Professional Mastery"
        },
        {
          id: 6,
          title: "ML in BFSI",
          description: "Harnessing the Power of ML in the BFSI Industry and Building Custom ML Models for Business Success in BFSI Industry"
        }
      ]
    },

    learners_review: [
      {
        id: 0,
        name: "Gayathree Dulam",
        batch: "ECE",
        img: gayathree,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/gayathree-dulam-b69102198/",
        review:
          "It was really a great learning experience during the program . We learnt a lot of concepts related to data science and data structures from Instructors who are working in Microsoft, Amazon, Flipkart, Walmart, Siemens, Cigna and other MNCs through live sessions which made us well equipped for the industry. The Instructors were always more than happy to solve the doubts we had during sessions. Instructors have a lot of patience and depth in concepts with huge industry experience. The quality instructor led live sessions is the best thing happened for me to program and entire ecosystem helped me to get into Amazon. I would recommend Surge Classes on any day."
      },
      {
        id: 1,
        name: "Manthina Sriram Varma",
        batch: "CSE",
        img: sriram,
        company: amazon,
        linkedin: "https://www.linkedin.com/in/manthina-sriram-varma-804632169/",
        review:
          "I have enrolled to the program for aiming high package jobs. Overall it was a very good program with many modules which were related to good placements. The teaching was good, we were given opportunity to learn thoroughly in every concept through live classes as well as good doubt clarification sessions. The best thing happened to me in the program is mock interviews by industry experts from working in Goldmansachs, Amazon, American Express, Wells Fargo and Siemens which played significant role in getting offer from Amazon at very high package.  Surge Classes is very good choice which I would recommend who want to make big."
      },
      {
        id: 2,
        name: "Surekha Dasari",
        batch: "ECE",
        img: surekha,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/surekha-dasari-5b9366204/",
        review:
          "Overall learning experience was good and the set of mentors who had great industrial knowledge is the top aspect of surge classes. Thanks for teaching us the data science and coding things patiently and motivating us to work hard and grow in life. They have guided us specific to certain company interviews even on odd times and on short notice."
      },
      {
        id: 3,
        name: "Likhith Sai kumar",
        batch: "Mech",
        img: likhith,
        company: tcs,
        linkedin: "https://www.linkedin.com/in/likhith-sai-kumar-730294190/",
        review:
          "The experience was amazing and the design of program was very good. we had done hands-on projects on every data science and coding module which gave me better understanding of Data science eco systems. The mentors were great they made us understand every concept they gave in depth knowledge of concepts and often clarified our doubts in detailed way. This program helped me to get the offers from TCS Digital and Gain Sight in Campus Placement. Being a Mechanical Engineer this is quite accomplishing. "
      },
      {
        id: 4,
        name: "Jagadeesh Medapati",
        batch: "Electrical",
        img: jagdeesh,
        company: infosys,
        linkedin: "https://www.linkedin.com/in/jagadeesh-medapati/",
        review: "I was completely satisfied with the duration of the programme, Instructors, course lectures as well as projects we have built on various modules. I'd be recommending Surge Classes as anyone can learn the Data science concepts easily from scratch with that mentorship and Live classes."
      },
      {
        id: 5,
        name: "Lavanya Narayanam",
        batch: "IT",
        img: lavanya,
        company: axisbank,
        linkedin: "https://www.linkedin.com/in/narayanam-loka-lavanya/",
        review:
          "It was a pretty good program where I learned machine learning from scratch. The course structure and the hands-on projects are really beneficial to start a career in data science field and make big. The way of explanation from the trainers is easy to understand and they clarify our doubts well. This program helped me to build a profile with ML, NLP and Deep Learning projects on various domains. This quaility Portfolio built in the program helped me to get into Axis Bank in Data Science role. "
      },
      {
        id: 6,
        name: "Sravya Deepika Kommisetti",
        batch: "CSE",
        img: sravya,
        company: sureify,
        linkedin: "https://www.linkedin.com/in/sravya-deepika-kommisetti-b2a975198/",
        review:
          "I had very good time during surge classes. Here theory goes hand in hand with hands on project based learning which helped in good understanding and effective profile building. Regarding the mentors, they are good with their quality content, industry expertise and are always available for doubts. They guides us with through out interview preparation and effective mock interviews. Surge Classes eco system is top class and I would recommend for any folks who want to upskill."
      }
    ],

    faqs: [
      {
        "title": "What is Data Science and AI?",
        "description": "Data Science and Artificial Intelligence (AI) involve the extraction of insights and patterns from data to create intelligent algorithms and models that mimic human cognitive functions and decision-making."
      },
      {
        "title": "Why are Data Science and AI important?",
        "description": "Data Science and AI play a critical role in transforming data into valuable insights for informed decision-making. They enable automation, predictive analysis, and innovative solutions across various industries."
      },
      {
        "title": "Is prior technical experience necessary for the program?",
        "description": "While some technical background can be advantageous, our Data Science and AI program is designed to accommodate learners from diverse academic and professional backgrounds. We offer comprehensive resources and guidance."
      },
      {
        "title": "What topics are covered in the program?",
        "description": "Our program covers a wide range of topics, including machine learning, data analysis, neural networks, natural language processing, and more. You'll gain a holistic understanding of the Data Science and AI landscape."
      },
      {
        "title": "Are there any prerequisites for enrollment?",
        "description": "Basic knowledge of programming and statistics is recommended, but not mandatory. The program provides foundational learning resources to help participants build the necessary skills."
      }
    ],

    fees: 13000,
    completion_date: "Sept 2023",
    enroll_deadline: "30 Sept,2024",
    instructor: [
      {
        id: 0,
        name: "Srikanth Annibathina",
        designation: "Analytics Manager, Cognizant",
        excompanies: {
          cgi: cgi,
          cigna: cigna
        },
        img: srikanth,
        organisation: "VTU, KARNATAKA",
        linkedinurl: "https://www.linkedin.com/in/srikanth-annabathina-7842a9188/"
      },
      {
        id: 1,
        name: "Krishna Swamy Jalla",
        designation: "Co-Founder, Surge Classes",
        excompanies: {
          siemens: siemens,
          cigna: cigna
        },
        img: krishna,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
      },
      {
        id: 2,
        name: "Shubham Jaiswal",
        designation: "Analytics Analyst, McKinsey",
        excompanies: {
          wilistowerwatson: wilistowerwatson
        },
        img: shubham,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/shubham-jaiswal-0aa5a65b/"
      },
      {
        id: 3,
        name: "Akash Pandey",
        designation: "Manager, Times Internet",
        excompanies: {
          amex: amex,
          exl: exl
        },
        img: akash,
        organisation: "IIT KANPUR",
        linkedinurl: "https://www.linkedin.com/in/akash-pandey-676307108/"
      },
      {
        id: 4,
        name: "Abhishek Potnis",
        designation: "R&D Associate in ML, Oak Ridge",
        excompanies: {
          iitb: iitb
        },
        img: abhishek,
        organisation: "IIT BOMBAY",
        linkedinurl: "https://www.linkedin.com/in/abhishekpotnis/"
      },
    ]
  },
];

export const SurgePrograms = [
  ...CampusProgram,
  ...ProProgram,
  ...SelfPacedProgram,
  ...LiveBatches
];

export const SurgeProgramsAll = {
  campusprogram: CampusProgram,
  proprogram: ProProgram,
  selfpacedprogram: SelfPacedProgram,
  livebatches: LiveBatches
};

import logo from "../assets/main/logo.svg";
import facebookicon from "../assets/socialicons/facebookicon.svg";
import instagramicon from "../assets/socialicons/instagramicon.svg";
import linkedinicon from "../assets/socialicons/linkedinicon.svg";
import { useState, useEffect } from "react";
import { useHttp } from "../hooks/useHttp";
import { Link } from "react-router-dom";

const socialLinks = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/Surge-Classes-111312501672213",
    icon: facebookicon,
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/surgeclasses/",
    icon: instagramicon,
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/surge-classes/",
    icon: linkedinicon,
  },
];

const footerLinks = [
  {
    name: "Company",
    links: [
      {
        name: "About Us",
        url: "/about",
      },
      {
        name: "Contact Us",
        url: "/contact",
      },
      {
        name: "Terms & Conditions",
        url: "/terms",
      },
      {
        name: "Pricing",
        url: "/pricing",
      },
      {
        name: "Testimonials",
        url: "/testimonials",
      },
    ],
  },
  {
    name: "Support",
    links: [
      {
        name: "Help Center",
        url: "/help",
      },
      {
        name: "Terms of Service",
        url: "/terms",
      },
      {
        name: "Legal",
        url: "/legal",
      },
      {
        name: "Privacy Policy",
        url: "/privacy",
      },
      {
        name: "Status",
        url: "/status",
      },
    ],
  },
];

const Footer = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const { response, apiCall } = useHttp();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (!email.trim()) {
        setEmailError("This is a required field");
        return;
      }

      if (!validateEmail(email)) {
        setEmailError("Invalid email format");
        return;
      }
      apiCall({
        path: "/newsletter/subscribe",
        method: "POST",
        data: { email: email },
      });
    }
  };

  useEffect(() => {
    if (email) {
      setEmailError("");
    }
  }, [email]);

  useEffect(() => {
    if (response) {
      setEmail("");
    }
  }, [response]);

  return (
    <footer className="flex flex-row justify-center bg-[#262626]">
      <div className="flex flex-row justify-center">
        <div className="flex flex-col text-white md:w-[1000px] xl:w-[1460px]">
          <div className="flex flex-row justify-evenly p-5 md:p-10 ">
            <div className="flex flex-col pl-1 pr-4 md:px-3">
              <Link to="/">
              <div className="bg-white py-3 rounded-[5px] my-1 md:my-0 w-20 px-1 md:w-48 h-15 cursor-pointer">
                <img src={logo} alt="SurgeClasses Logo" className="w-full h-full object-cover" />
              </div>
              </Link>
              <div className="text-[0.65rem] w-20 md:w-48 md:text-base py-2">
                Edtech product by Jalla Labs Pvt. Ltd.
              </div>
              <div className="font-bold text-sm md:text-base py-1 pt-5 pb-3">Join Us</div>
              <div>
                <ul className="flex flex-row justify-start">
                  {socialLinks.map((link, i) => (
                    <li key={i}>
                    <a
                      key={i}
                      href={link.url}
                      target="_blank"
                      className="flex flex-col justify-evenly pr-2 md:pr-5"
                      rel="noreferrer"
                      >
                        <div className="w-4 h-4 md:w-8 md:h-8 flex flex-row justify-center items-center">
                          <img src={link.icon} alt={link.name} className="w-full h-full object-contain" />
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex flex-col px-1 md:px-3">
              <h3 className="font-bold py-1 text-sm md:text-base">Company</h3>
              <ul className="flex flex-col">
                {footerLinks[0].links.map((link, i) => (
                  <li key={i} className="py-1 text-xs md:text-base">
                    <Link to={link.url}>{link.name}</Link>
                  </li>
                ))}
              </ul>

        </div>
            <div className="flex flex-col px-1 md:px-3">
              <h3 className="font-bold py-1 text-sm md:text-base">Support</h3>
              <ul className="flex flex-col">
                {footerLinks[1].links.map((link, i) => (
                  <li key={i} className="py-1 text-xs md:text-base">
                    <Link to={link.url}>{link.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="hidden lg:px-3 lg:flex lg:flex-col">
              <h3 className="font-bold py-1">Stay Up to Date</h3>
              <input
                id="subscribe-email"
                type="email"
                className={`bg-white p-1 mx-0 border rounded-[5px] text-black py-1 w-full md:w-[200px] my-2 ${emailError ? "border-red-500" : ""
                  }`}
                placeholder="Enter your Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {emailError && (
                <p className="text-red-500 text-sm">{emailError}</p>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-center p-3 text-xs md:text-base">
            SurgeClasses @ 2023. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

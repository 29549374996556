import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-SFJYX9PKC4");
TagManager.initialize({ gtmId: "GTM-TRLB9M8W" });

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
    >
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);

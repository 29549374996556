import pen from "../assets/hiringcompanies/pen.webp";
import fivot from "../assets/hiringcompanies/fivot.webp";
import icon1 from "../assets/hiringprocess/icon1.svg";
import icon2 from "../assets/hiringprocess/icon2.svg";
import icon3 from "../assets/hiringprocess/icon3.svg";
import icon4 from "../assets/hiringprocess/icon4.svg";

export const hiringCompanyDetails = [
  {
    id: 0,
    reviewername: "Chinmaya Gupta",
    designation: "Founder",
    img: fivot,
    linkedin: "https://www.linkedin.com/in/chinmayagupta/",
    description: "“Our hiring experience with Surge Classes has been truly exceptional. When interviewing candidates for entry-level ML-NLP Engineers, we encountered a pool of equally skilled and talented individuals, making our selection process quite challenging. Surge's training program effectively nullified the significance of college and background, emphasizing comprehensive training instead. What stood out was the students' profound exposure to AI technology, equipping them to contribute immediately from their first day on the job. Furthermore, Surge's policy of extending just one offer per candidate guarantees that once an offer is made, students promptly accept and join. In summary, we have full confidence that Surge is the premier choice for high-quality and expedited hiring.“"
  },
  {
    id: 1,
    reviewername: "Ashish Khan",
    designation: "Manager - Product Team",
    img: pen,
    linkedin: "https://www.linkedin.com/in/ashishkhan/",
    description:
      "“We sought to recruit entry-level data scientists, and Surge Classes exceeded our expectations. Our acceptance rate for candidates from Surge was superior to that of other recruitment channels, and we completed the entire process in just two days. Remarkably, all Surge candidates who received offers accepted them, achieving a 100% offer-to-joining ratio. These data scientists exhibited a solid grasp of the necessary technologies and were ready to contribute from day one. We enthusiastically endorse Surge for organizations in need of a rapid and effective solution for hiring entry-level data scientists.”"
  },
];

export const hiringProcess = [
  {
    id: 1,
    title: "Specify the requirements you have",
    description: "There will be a specific Hiring Expert assigned to you.",
    icon: icon1,
  },
  {
    id: 2,
    title: "24 hours to get selected profiles",
    description: "We select qualified developers to meet your needs.",
    icon: icon2,
  },{
    id: 3,
    title: "Begin the hiring procedure",
    description: "Test and interview candidates to determine their suitability for the job.",
    icon: icon3,
  },{
    id: 4,
    title: "Pick the most suitable profile",
    description: "Candidates join right away.",
    icon: icon4,
  },
]
import logo from "../../assets/main/logo.svg";
import { useState } from "react";
import { validate, VALIDATOR_EMAIL, VALIDATOR_MOBILE, VALIDATOR_MAXLENGTH, VALIDATOR_REQUIRE } from "../validators/InputValidator";

const UserDetailsForPayment = ({ formData, setFormData, setSelectedTab }) => {
    const [formValidity, setFormValidity] = useState({
        name: false,
        mobile: false,
        email: false,
    });

    const checkValidity = (name, value) => {
        switch (name) {
            case "name":
                return validate(value, [VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(50)]);
            case "mobile":
                return validate(value, [VALIDATOR_REQUIRE(), VALIDATOR_MOBILE()]);
            case "email":
                return validate(value, [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]);
            default:
                return false;
        }
    };

    const onInputChange = (name, value) => {
        const phoneNumberExtension = "+91";
        if (name === "mobile") {
            value = phoneNumberExtension + value;
        }
        const isValid = checkValidity(name, value);
        setFormValidity((prevValidity) => ({
            ...prevValidity,
            [name]: isValid,
        }));
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const onNext = (e) => {
        e.preventDefault();
        if (formValidity.name && formValidity.mobile && formValidity.email) {
            setSelectedTab("payment-info");
        }
    };

    return (
        <div className="lg:w-[40%] md:w-[50%] w-[100%] h-full">
            <div className="flex flex-row justify-center pb-2">
                <img src={logo} alt="Surge Classes" className="w-[65%]" />
            </div>
            <div className="font-inter text-2xl text-[#101828] font-semibold text-center py-2">
                Submit
            </div>
            <div className="font-inter text-base text-center text-[#667085]">
                Please enter your details.
            </div>
            <form className="flex flex-col py-4" onSubmit={onNext}>
                <label
                    htmlFor="name"
                    className="flex flex-row justify-start font-inter text-lg py-3 text-[#344054]"
                >
                    Full Name
                </label>
                <input
                    className={`hover:border-gray-400 placeholder:text-slate-400 border h-10 border-[#D0D5DD] rounded-sm px-2 py-4 ${formValidity.name ? "" : "border-red-500"
                        }`}
                    placeholder="Enter your full name"
                    type="text"
                    required
                    name="name"
                    onChange={(e) => onInputChange("name", e.target.value)}
                />

                <label
                    htmlFor="mobile"
                    className="flex flex-row justify-start font-inter text-lg py-3 text-[#344054]"
                >
                    Mobile
                </label>
                <div className={`flex flex-row justify-start font-inter text-lg text-[#344054] border border-[#D0D5DD] rounded-sm ${formValidity.mobile ? "" : "border-red-500"} hover:border-gray-400`}>
                    <div
                        className="p-1 px-2 mr-2 w-[15%]"
                    >
                        +91
                    </div>
                    <input
                        className="placeholder:text-slate-400 h-10 px-2 py-4 outline-none border-l-2 w-[85%]"
                        placeholder="9876543210"
                        type="text"
                        required
                        name="mobile"
                        onChange={(e) => onInputChange("mobile", e.target.value)}
                    />
                </div>

                <label
                    htmlFor="email"
                    className="flex flex-row justify-start font-inter text-lg py-3 text-[#344054]"
                >
                    Email
                </label>
                <input
                    className={`hover:border-gray-400 placeholder:text-slate-400 border h-10 border-[#D0D5DD] rounded-sm px-2 py-4 ${formValidity.email ? "" : "border-red-500"
                        }`}
                    placeholder="Enter your email"
                    type="email"
                    required
                    name="email"
                    onChange={(e) => onInputChange("email", e.target.value)}
                />

                <div className="flex flex-row justify-center font-inter text-base py-4 text-[#344054]">
                    <button
                        className={`bg-[#F05454] text-white font-inter text-base font-semibold py-2 rounded-sm w-[100%] mt-4 ${!formValidity.name || !formValidity.mobile || !formValidity.email
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:bg-[#F05454]"
                            }`}
                        type="submit"
                        disabled={!formValidity.name || !formValidity.mobile || !formValidity.email}
                    >
                        NEXT
                    </button>
                </div>
            </form>
        </div>
    );
}
export default UserDetailsForPayment;
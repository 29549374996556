const Legal = () => {
    return (
        <div className="bg-white py-12">
            <div className="flex flex-col justify-center py-0 md:py-5 px-1 md:px-10">
                <h1 className="font-inter py-3 md:py-5 text-3xl md:text-4xl font-bold text-primary text-center">
                    Legal Information</h1>
                <div className="container mx-auto px-4 py-5 md:py-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="bg-[#F9FAFB] p-6 rounded-lg shadow-md">
                            <h2 className="text-xl md:text-2xl font-semibold mb-4 text-primary">Disclaimer</h2>
                            <div className="border-t border-gray-300 my-4"></div>
                            <p className="mb-2 text-sm md:text-base">
                                This website provides general information and does not constitute legal advice. The content is for informational purposes only and should not be considered as a substitute for professional legal advice.
                            </p>
                            <p className="mb-2  text-sm md:text-base">
                                The information on this website may not be accurate, complete, or up-to-date. Always consult with a qualified legal professional for advice related to your specific situation.
                            </p>
                        </div>

                        <div className="bg-[#F9FAFB] p-6 rounded-lg shadow-md">
                            <h2 className="text-xl md:text-2xl font-semibold mb-4 text-primary">Privacy Policy</h2>
                            <div className="border-t border-gray-300 my-4"></div>
                            <p className="mb-2  text-sm md:text-base">
                                We respect your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and store your data when you interact with our website.
                            </p>
                            <p className="mb-2  text-sm md:text-base">
                                By using this website, you consent to the practices described in our Privacy Policy. Make sure to review the policy to understand our data handling practices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Legal;

import LinkedinSvg from "../../../assets/svgs/LinkedinSvg";

const PlacedStudentCard = ({ placedStudentDetails }) => {
    return (
        <div className="flex flex-col h-full">
            <div
                key={placedStudentDetails.id}
                className="flex flex-col py-3 w-[250px] md:w-[380px] mx-5 cursor-pointer h-[200px]"
            >
                <div className="flex flex-row justify-center items-center shadow-lg shadow-[#00000040] rounded-[5%] hover:scale-105 transform transition-transform duration-300">
                    <div className="flex flex-col items-center">
                        <div className="">
                            <img
                                src={placedStudentDetails.img}
                                alt="xyz"
                                className="w-20 h-20 md:w-28 md:h-28 overflow-hidden rounded-full object-cover"
                            />
                            <div className="flex flex-row justify-center z-10 mt-[-40px] mr-[-100px]">
                                <button className="rounded-full">
                                    <a
                                        href={placedStudentDetails.linkedin}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <LinkedinSvg />
                                    </a>
                                </button>
                            </div>
                        </div>
                        {/* <a className="font-inter underline mt-3 text-sm font-bold text-[#004C73] text-center" href="https://drive.google.com/file/d/1Z" target="_blank" rel="noreferrer">
                            View Resume
                        </a> */}
                    </div>
                    <div className="p-5 flex flex-col justify-start mt-1">
                        <div className="font-inter text-base font-bold text-black text-center truncate max-w-[100px] md:max-w-[200px]">
                            {placedStudentDetails.name}
                        </div>
                        <div className="font-inter text-xs text-black text-center">
                            Placed at
                        </div>
                        <div className="flex flex-row justify-evenly my-2">
                            <div className="md:h-8 md:w-20 h-4 w-10">
                                <img
                                    srcSet={`${placedStudentDetails.company} 480w, ${placedStudentDetails.company} 800w, ${placedStudentDetails.company} 1200w`}
                                    sizes="(max-width: 600px) 480px, (max-width: 1024px) 800px, 1200px"
                                    src={placedStudentDetails.companyMedium}
                                    alt="xyz"
                                    className="w-full h-full object-contain"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row justify-center">
                            <button className="rounded-xl bg-primary text-white p-1 w-[90%] border-2">
                                {placedStudentDetails.package}
                            </button>
                        </div>
                        {/* <div className="flex flex-row justify-center py-4">
                            <button className="rounded-[10px] px-2 p-1  border-2 w-[90%] flex flex-row justify-center hover:bg-blue-100">
                                <a
                                    href={placedStudentDetails.linkedin}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <div className="h-6">
                                        <img src={linkedin} alt="linkedin" className="h-full w-full object-contain" />
                                    </div>
                                </a>
                            </button>
                        </div> */}
                    </div>
                </div>
                {/* <div className="font-inter text-xs text-[#7A7A7A] p-3">
          {placedStudentDetails.description}
        </div> */}
            </div>
        </div>
    );
};

export default PlacedStudentCard;

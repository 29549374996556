const NotFound = () => {
    const path = window.location.pathname.split('/')[1];

    const titles = {
        'course-not-found': 'Course Not Found',
        'page-not-found': 'Page Not Found',
    };

    const messages = {
        'course-not-found': 'The course you are looking for does not exist.',
        'page-not-found': 'The page you are looking for does not exist.',
    };

    const message = messages[path] || messages['page-not-found'];
    const title = titles[path] || titles['page-not-found'];

    return (
        <div className="bg-white py-24 px-4 sm:px-6 lg:px-8">

            <div className="flex flex-col bg-white items-center justify-center text-center min-h-full">
                <div className="text-sm font-bold text-primary mb-4">404 error</div>
                <h1 className="text-2xl md:text-4xl font-bold text-black mb-4">{title}</h1>
                <p className="text-gray-600 mb-4 text-sm md:text-lg">
                    Sorry, {message}
                </p>
                {path === 'course-not-found' ? (
                    <button
                        className="px-4 py-2 bg-primary text-white rounded-full hover:bg-[#213e59]  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={() => window.location.replace("/")}
                    >
                        Go Home
                    </button>
                ) : (
                    <button
                        className="px-4 py-2 bg-primary text-white rounded-full hover:bg-[#213e59]  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    onClick={() => window.history.back()}
                >
                    Go Back
                    </button>)}
            </div>
        </div>
    );
};

export default NotFound;

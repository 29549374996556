import Swal from "sweetalert2";

const toastMixin = ({
  autoDismiss = true,
  timeout = 4000
}) => {
  return Swal.mixin({
    toast: true,
    position: "top-right",
    showConfirmButton: false,
    timer: autoDismiss ? timeout : null,
    timerProgressBar: true,
  });
};

export default toastMixin;

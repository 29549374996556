import ClosePopupSvg from "../../../assets/svgs/ClosePopupSvg";

const Popup2 = ({ close, children }) => {
    return (
      <div
        onClick={close}
        className="fixed z-10 top-0 left-0 w-full h-full flex items-center bg-[#7c7c7c7c] justify-center"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="relative bg-[#FAFAFA] py-4 lg:py-10 px-4 rounded-sm w-[80%] min-h-[80%] 2xl:w-[50%] 2xl:min-h-[50%]"
        >
          <div
            onClick={close}
            className="absolute top-0 right-0 p-4 cursor-pointer"
          >
            <ClosePopupSvg />
          </div>
          <div className="flex flex-col px-4 md:px-10 w-full items-center">
            {children}
          </div>
        </div>
      </div>
    );
  };
  
  export default Popup2;
  
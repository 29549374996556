import forward from "../../../assets/programsicon/forward.svg"
const ProgramSidebarElement = ({
  programs,
  setSelectedProgramId,
  setActiveProgramId,
  selectedProgram,
  activeProgram
}) => {
  return (
    <>
      {/* {programs.map((program, i) => {
        const isActive = program.key === selectedProgram.key;
        return (
          <div
            key={i}
            onClick={() => setSelectedProgramId(program.key)}
            onMouseEnter={() => setActiveProgramId(program.key)}
            onMouseLeave={() => setActiveProgramId(-1)}
            className={`flex flex-row px-5 justify-between py-3 rounded-md cursor-pointer my-1 hover:border-primary hover:border-2
          ${isActive ? "bg-[#004C73]" : ""}
          `}
          >
            <div
              className={`flex flex-row
          ${isActive ? "filter invert" : ""}
          `}
            >
              <img src={program.icon} alt={program.title} className="px-2" />
              <div className="pl-2 pr-4">{program.title}</div>
            </div>
            <div className="flex flex-col justify-center">
              <img
                className={`py-1
             ${isActive ? "brightness-0 filter invert" : ""}
            `}
                src={forward}
                alt={forward}
              />
            </div>
          </div>
        );
      })} */}
      <div className="flex flex-row xl:flex-col pl-2 xl:px-5 mx-2 xl:mx-10 max-w-full xl:w-[450px] overflow-x-scroll no-scrollbar">

        {programs.map((program, i) => {
          const isActive = program.key === selectedProgram.key;
          return (
            //     <div
            //       key={i}
            //       onClick={() => setSelectedProgramId(program.key)}
            //       onMouseEnter={() => setActiveProgramId(program.key)}
            //       onMouseLeave={() => setActiveProgramId(-1)}
            //       className={`flex flex-row my-6 mx-10 w-[300px] px-5 justify-between py-3 rounded-md cursor-pointer xl:my-1 hover:border-primary hover:border-2
            // ${isActive ? "bg-[#004C73]" : ""}
            // `}
            //     >
            //       <div
            //         className={`flex flex-row
            //   ${isActive ? "filter invert" : ""}
            //   `}
            //       >
            //         <img src={program.icon} alt={program.title} className="px-2" />
            //         <div className="pl-2 pr-4">{program.title}</div>
            //       </div>
            //       <div className="hidden xl:flex xl:flex-row justify-center">
            //         <img
            //           className={`py-1 w-5 h-5
            //     ${isActive ? "brightness-0 filter invert" : ""}
            //     `}
            //           src={forward}
            //           alt={forward}
            //         />
            //       </div>

            //     </div>
            <div
              key={i}
              onClick={() => setSelectedProgramId(program.key)}
              onMouseEnter={() => setActiveProgramId(program.key)}
              onMouseLeave={() => setActiveProgramId(-1)}
              className={`flex flex-row my-6 mx-2 sm:mx-4 xl:mx-10 xl:w-[300px] px-3 justify-between py-3 rounded-md cursor-pointer xl:my-1 hover:border-primary hover:border-2 ${isActive ? "bg-[#004C73]" : ""
                }`}
            >
              <div
                className={`flex flex-row items-center ${isActive ? "filter invert" : ""
                  }`}
              >
                <img src={program.icon} alt={program.title} className="px-2" />
                <div className="px-2 pr-4 mr-2 xl:pr-0 xl:overflow-hidden whitespace-nowrap">
                  {program.title}
                </div>
              </div>
              <div className="hidden xl:flex xl:flex-row items-center justify-end pl-0.5">
                <img
                  className={`py-1 w-5 h-5 ${isActive ? "brightness-0 filter invert" : ""
                    }`}
                  src={forward}
                  alt={forward}
                />
              </div>
              <div className="xl:hidden flex flex-row items-center justify-end ml-5 w-3 h-6">
                <img
                  className={`py-1 mt-1 object-cover w-full h-full transform rotate-90 ${isActive ? " brightness-0 filter invert" : ""
                    }`}
                  src={forward}
                  alt={forward}
                />
              </div>
            </div>

        );
      })}
      </div>
    </>
  );
};

export default ProgramSidebarElement;

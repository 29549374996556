const FeatureCardArrow2Svg = () => {
    return (
        <svg width="178" height="177" viewBox="0 0 178 177" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1248_2243)">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.2139 44.4322C4.29792 45.4942 3.68759 47.77 3.84016 60.2105C3.94187 69.2123 4.55211 74.6738 5.56925 75.3312C8.01037 76.8483 12.3334 74.3198 12.13 70.4764C11.8757 66.2284 11.367 57.0751 11.2653 53.6363C10.8076 44.4828 10.6042 43.7747 8.2139 44.4322ZM6.89156 86.3052C3.99273 89.1878 5.77268 108.455 9.12923 109.973C13.4521 111.945 15.4356 107.798 14.0625 99.8583C13.1979 94.9529 12.842 91.3118 12.6386 88.4292C12.4351 85.5466 8.92585 84.2823 6.89156 86.3052ZM11.6721 123.374C8.82408 125.346 10.6549 135.966 15.9949 148.053C20.6229 158.521 27.031 155.386 23.1658 144.513C21.2841 139.253 18.5379 130.859 17.1648 125.903C15.8934 121.705 14.7744 121.199 11.6721 123.374ZM130.169 129.341C128.593 129.948 128.694 131.516 130.576 139.051C131.441 142.439 131.949 145.373 131.695 145.524C131.237 145.929 116.845 150.784 113.895 151.593C111.25 152.25 111.861 159.533 115.726 158.926C118.777 158.471 127.982 155.436 131.237 153.767C134.238 152.25 133.831 151.947 135.357 157.156C137.188 163.427 137.544 164.185 138.968 164.741C142.578 166.107 146.952 164.539 149.648 160.949C155.547 153.161 161.701 147.749 173.856 139.709C180.721 135.157 179.552 134.399 165.668 134.399C153.513 134.399 145.681 133.387 138.408 130.808C132.509 128.684 131.949 128.583 130.169 129.341ZM96.9596 160.139C96.2476 160.342 92.6876 161.353 89.0768 162.365C66.8522 168.484 69.751 173.844 80.1259 171.771C95.0779 168.787 101.028 166.056 101.028 162.112C101.028 160.038 99.5533 159.33 96.9596 160.139ZM29.1162 164.843C25.4545 170.001 37.2534 176.474 51.7986 177.233C66.0893 177.991 67.3099 170.102 56.0705 169.647C47.9842 169.293 43.1018 168.332 36.5921 165.702C30.6927 163.376 30.1842 163.325 29.1162 164.843Z" fill="#A6E1FF" />
            </g>
            <defs>
                <clipPath id="clip0_1248_2243">
                    <rect width="178" height="177" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default FeatureCardArrow2Svg;

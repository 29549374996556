import React, { useState, useEffect } from "react";
import logo from "../../assets/main/logo.svg";
// import google from "../../assets/auth/google2.png";
import { useHttp } from "../../hooks/useHttp";
import Popup from "../ui/popup/Popup";
import Loader from "../ui/Loader";
// import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// import { auth } from "../../firebase/Firebase";
import Swal from "sweetalert2";
import toastMixin from "../../utils/toastMixin";
import { validate, VALIDATOR_MOBILE } from "../validators/InputValidator";

import { GoogleLogin } from "@react-oauth/google";

const Login = ({
  setIsLogin,
  setShowLoginPopup,
  showLoginPopup,
  setShowForgotPasswordPopup,
  setShowSignupPopup,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { isLoading, error, response, apiCall } = useHttp();
  const [loading, setLoading] = useState(false);

  const callFirebaseAPi = async (url, data) => {
    setLoading(true);
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
  };

  const handleGoogleSignIn = (response) => {
    const token = response.credential;
    const idToken = {
      idToken: token,
    };
    callFirebaseAPi("/firebase/verify-google-token", idToken).then(
      (response) => {
        if (response) {
          const firebaseIdToken = response.firebase_id_token;
          const data = response;
          if (data.status === 1) {
            Swal.fire({
              title: "Enter your phone number",
              input: "text",
              inputLabel: "Your phone number",
              inputPlaceholder: "Enter your phone number in format +91xxxxxxxxxx",
              showCancelButton: true,
              allowOutsideClick: false,
              inputValidator: (value) => {
                if (!value) {
                  return "You need to write something!";
                } else {
                  if (!validate(value, [VALIDATOR_MOBILE()])) {
                    return "Invalid phone number";
                  }
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                let mobile = result.value;
                let phoneData = {
                  mobile: mobile,
                  idToken: firebaseIdToken,
                };
                callFirebaseAPi("/firebase/verify-phone", phoneData)
                  .then((result) => {
                    if (result && result.data) {
                      toastMixin({
                        timeout: 1000,
                      }).fire({
                        icon: "success",
                        title: "Phone number verified successfully",
                      });
                      setShowLoginPopup(false);
                      localStorage.setItem("token", result.data.token);
                      localStorage.setItem("user", JSON.stringify(result.data));
                      setIsLogin(true);
                    } else {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: "Please use another phone number",
                      });
                      setLoading(false);
                    }
                  })
                  .catch((err) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: "Please use another phone number",
                    });
                    setLoading(false);
                  });
              }
              else {
                setLoading(false);
              }
            });
          } else if (data.status === 2) {
            setLoading(false);
            toastMixin({
              timeout: 1000,
            }).fire({
              icon: "success",
              title: data.message,
            });
            setShowLoginPopup(false);
            localStorage.setItem("token", data.token);
            localStorage.setItem("user", JSON.stringify(data));
            setIsLogin(true);
          } else {
            setShowSignupPopup(true);
          }
        }
      }
    );
  };

  const togglePopup = () => {
    setShowLoginPopup(!showLoginPopup);
  };

  const handleForgotPassword = () => {
    setShowForgotPasswordPopup(true);
    setShowLoginPopup(false);
  };

  const handleSignUp = () => {
    setShowSignupPopup(true);
    setShowLoginPopup(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    const fields = [
      {
        value: email,
        setter: setEmailError,
        message: "This is a required field",
      },
      {
        value: password,
        setter: setPasswordError,
        message: "This is a required field",
      },
    ];

    for (const field of fields) {
      if (!field.value.trim()) {
        field.setter(field.message);
        return;
      }
    }

    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    }
    return true;
  };

  const onLogin = (e) => {
    e.preventDefault();

    const loginData = {
      email: email,
      password: password,
    };

    if (validateFields()) {
      apiCall({
        path: "/firebase/login",
        method: "POST",
        data: loginData,
      });
    }
  };

  useEffect(() => {
    if (response && response.data) {
      const firebaseIdToken = response.firebase_id_token;
      const data = response.data;
      setShowLoginPopup(false);
      localStorage.setItem("token", firebaseIdToken);
      localStorage.setItem("user", JSON.stringify(data));
      setIsLogin(true);
    }
  }, [response, setIsLogin, setShowLoginPopup]);

  useEffect(() => {
    if (email) {
      setEmailError("");
    }
    if (password) {
      setPasswordError("");
    }
  }, [email, password]);

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <>
      {showLoginPopup && (
        <Popup close={togglePopup}>
          <div className="flex flex-row justify-center pb-2">
            <img src={logo} alt="Surge Classes" className="w-[70%]" />
          </div>
          <div className="font-inter text-2xl text-[#101828] font-semibold text-center py-2">
            Log in to your account
          </div>
          <div className="font-inter text-sm  text-center text-[#667085]">
            Welcome back! Please enter your details.
          </div>
          <form className="flex flex-col py-2">
            <label
              htmlFor="email"
              className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]"
            >
              Email
            </label>
            <input
              className={`hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4 ${emailError ? "border-red-500" : ""
                }`}
              id="email-address"
              name="email"
              type="email"
              value={email}
              autoComplete="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
            <label
              htmlFor="password"
              className="flex flex-row justify-start font-inter text-base py-2 pt-4 text-[#344054]"
            >
              Password
            </label>
            <input
              className={`hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4 ${passwordError ? "border-red-500" : ""
                }`}
              id="password"
              name="password"
              type="password"
              value={password}
              autoComplete="current-password"
              required
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
            {passwordError && (
              <p className="text-red-500 text-sm">{passwordError}</p>
            )}
          </form>
          <div className="flex flex-row justify-between py-2">
            <div className="flex flex-row justify-start ">
              <input
                type="checkbox"
                name="rememberMe"
                className=" accent-black border-2"
                checked={rememberMe}
                onChange={handleRememberMe}
              />
              <div className="flex flex-col justify-center pl-1 text-xs">
                Remember for 30 days
              </div>
            </div>
            <div
              className="text-sm font-bold text-primary cursor-pointer"
              onClick={handleForgotPassword}
            >
              Forgot Password
            </div>
          </div>
          {isLoading ? (
            <div className="py-2">
              <Loader />
            </div>
          ) : (
            <button
              className="my-2 bg-primary text-white rounded-md py-2 font-bold"
              onClick={onLogin}
            >
              Sign in
            </button>
          )}
          <div className="flex justify-center py-2">
            <div className="w-fit">
              {loading ? (
                <Loader />
              ) : (
                <GoogleLogin
                    onSuccess={(credentialResponse) => {
                    handleGoogleSignIn(credentialResponse);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  useOneTap
                />
              )}
            </div>
          </div>
          <div className="flex flex-row justify-center py-2">
            <div className="flex flex-col justify-center text-xs text-[#667085]">
              Don’t have an account?
            </div>
            <div
              className="text-sm font-bold text-primary px-2 cursor-pointer"
              onClick={handleSignUp}
            >
              SignUp
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default Login;

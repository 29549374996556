import { useState, useEffect, useCallback } from "react";
import toastMixin from "../utils/toastMixin";
export const useHttp = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [success,setSuccess] = useState(null);

  const apiCall = useCallback(
    async ({
      path,
      method = "GET",
      data = {},
      headers = {},
    }) => {
      try {
        setLoading(true);
        setError(null);

        let payload = {
          body: JSON.stringify(data),
        }

        if (method === "GET") {
          delete payload.body;
        }

        const response = await fetch(process.env.REACT_APP_BACKEND_URL + path, {
          method: method,
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          ...payload
        });

        if(!response){
          throw new Error("Request failed");
        }

        if (response.status === 401) {
          delete localStorage['token'];
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          throw new Error("Please login again");
        }

        const responseData = await response.json();
        if (!responseData.message) {
          throw new Error(responseData.error);
        }else{
          if (responseData.message && !responseData.silent) {
        		setSuccess(responseData.message)
        	}
        }

        setResponse(responseData);
      } catch (error) {
        setError(error.message || "Something went wrong");
        setLoading(false);	
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(()=>{

  	if(error){
  		toastMixin({}).fire({
  			icon: "error",
  			title: error
  		})
  	}

  },[error])

	useEffect(()=>{

	  	if(success){
	  		toastMixin({
	  			timeout:1000
	  		}).fire({
	  			icon: "success",
	  			title: success
	  		})
	  	}

	  },[success])

  return { isLoading, error, response, apiCall };
};

import * as data from "../../data/Main";

const Testimonials = () => {
    return (
        <div className="bg-white py-12">
            <div className="flex flex-col justify-center py-5 px-3 md:px-10">
                <h1 className="font-inter py-3 md:py-5 text-3xl md:text-4xl font-bold text-primary text-center">
                    Testimonials</h1>
                <div className="container mx-auto px-4 py-5 md:py-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {data.reviewDetails.map(testimonial => (
                            <div key={testimonial.id} className="flex flex-col justify-between bg-[#F9FAFB] p-6 rounded-lg shadow-md">
                                <p className="mb-4 text-gray-600 text-sm md:text-base">{testimonial.review}</p>
                                <a className="flex items-center" href={testimonial.linkedin} target="_blank" rel="noreferrer">
                                    <div className="w-10 h-10 rounded-full">
                                        <img
                                            src={testimonial.img}
                                            alt={`${testimonial.name}'s profile`}
                                            className="w-full h-full object-cover rounded-full"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-base font-semibold text-primary">{testimonial.name}</p>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;

const ContactUs = () => {
    return (
        <div className="bg-gray-100 py-6 md:py-12">
            <div className="flex flex-col justify-center py-5 px-2 md:px-10">
                <h1 className="font-inter py-3 md:py-5 text-3xl md:text-4xl font-bold text-primary text-center">
                    Contact Us</h1>
                <div className="container mx-auto px-2 md:px-4 py-5 md:py-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
                            <h2 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4 text-primary">Our Address</h2>
                            <div className="border-t border-gray-300 my-2 md:my-4"></div>
                            <p className="mb-2 font-semibold text-sm md:text-xl">Jalla Labs Pvt. Ltd.</p>
                            <p className="mb-2 text-sm md:text-xl">No. 15, 25th A Main, Mariswamy Layout Agara,</p>
                            <p className="mb-2 text-sm md:text-xl">Sector 1, HSR Layout, Bengaluru, Karnataka 560102</p>
                            <p className="mb-2 text-sm md:text-xl">India</p>
                            <p className="mb-2 text-sm md:text-xl"><span className="font-bold">Phone:</span> <a href="tel:+919390249772" className="cursor-pointer">(+91) 9390249772</a></p>
                            <p><span className="font-bold text-sm md:text-xl ">Email:</span> <a href="mailto:connect@surgeclasses.com" className="text-sm md:text-xl text-primary">connect@surgeclasses.com</a></p>
                        </div>

                        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
                            <h2 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4 text-primary">Location</h2>
                            <div className="border-t border-gray-300 my-2 md:my-4"></div>
                            <div className="aspect-w-16 aspect-h-9">
                                <iframe
                                    title="Map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.84509240858!2d77.64535447733562!3d12.917675983575927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1483c9c8264f%3A0x6b0cd5f2aa93778c!2s15%2C%2025th%20A%20Main%20Rd%2C%20Mariswamy%20Layout%2C%20Agara%20Village%2C%201st%20Sector%2C%20HSR%20Layout%2C%20Bengaluru%2C%20Karnataka%20560102!5e0!3m2!1sen!2sin!4v1692996439611!5m2!1sen!2sin"
                                    allowFullScreen
                                    loading="lazy"
                                    className="w-full h-full"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;


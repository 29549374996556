import { useState, useEffect } from 'react';
import HomepageMainSlide from '../../../data/slides/HomepageMainSlide';
import SelfPacedProgramSlide from '../../../data/slides/SelfPacedProgramSlide';

const HomepageSlider = ({
    isLogin,
    setIsLogin,
    showPopup,
    setShowPopup,
    togglePopup,
    scrollToSection,
}) => {
    const slides = [<HomepageMainSlide
        isLogin={isLogin}
        setIsLogin={setIsLogin}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        togglePopup={togglePopup}
        scrollToSection={scrollToSection}

    />, <SelfPacedProgramSlide />];
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        if (showPopup) {
            setCurrentSlide(0);
        } else {
            const intervalId = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            }, 5000);
            return () => clearInterval(intervalId);
        }
    }, [showPopup, slides.length]);

    return (
        <div className="slider">
            {slides.map((slide, index) => (
                <div key={index} style={{ display: index === currentSlide ? 'block' : 'none' }}>
                    {slide}
                </div>
            ))}
        </div>
    );
};

export default HomepageSlider;

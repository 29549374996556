const Filter = ({ filterData, checked, handleCheckbox }) => {
  return (
    <div className="flex flex-col border-2 shadow-md rounded-[5%] w-[250px] mx-5 p-5">
      <div className="font-inter text-xl font-bold text-primary ">Filter</div>
      <div className="py-2">
        {filterData.map((data, i) => (
          <div key={i}>
            <div className="py-1">
            <input
              checked={checked.includes(data.title)}
              type="checkbox"
              id={data.title}
              onChange={(e) => handleCheckbox(data.title)}
              name="category"
              className=" accent-black border-2"
            ></input>
            <label htmlFor={data.title} key={i} className="text-primary px-2">
              {` ${data.title}`}
            </label>
            <br />
          </div>
          </div>
        ))}
      </div>
      {/* <div className="flex flex-row justify-center">
        <button className="rounded-lg text-lg p-1 px-4 mt-2 border-2 text-primary border-primary w-[50%] hover:text-white hover:bg-primary">
          Reset
        </button>
      </div> */}
    </div>
  );
};

export default Filter;

import krishna from "../assets/instructors/krishna/profileimg.png";
import syam from "../assets/surgeteam/syam.png";
import priyanka from "../assets/surgeteam/priyanka.webp";
import abhishek from "../assets/surgeinstructors/abhishek.png";
import akash from "../assets/surgeinstructors/akash.png";
import shubham from "../assets/surgeinstructors/shubham.png";
import srikanth from "../assets/surgeinstructors/srikanth.png";


export const surgeTeam = [
  {
    id: 0,
    name: "Krishna Swammy Jalla",
    designation: "Co-Founder",
    img: krishna,
    linkedin: "https://www.linkedin.com/in/krishna-swamy-jalla-00093b10a/"
  },
  {
    id: 1,
    name: "Syam Jalla",
    designation: "Co-Founder",
    img:  syam,
    linkedin: "https://www.linkedin.com/in/syam-jalla-8451890069/"
  },
  {
    id: 2,
    name: "Priyanka Bhardwaj",
    designation: "Software Development Engineer",
    img: priyanka,
    linkedin: "https://www.linkedin.com/in/priyanka-bhardwaj-32309a1a9/"
  }
];

export const surgeInstructors = [
  {
    id: 0,
    name: "Akash Pandey",
    designation: "Manager, Times Internet",
    img:  akash,
    linkedin: "https://www.linkedin.com/in/akash-pandey-676307108/"
  },
  {
    id: 1,
    name: "Srikanth Annibathina",
    designation: "Analytics Manager, Cognizant",
    img:  srikanth,
    linkedin: "https://www.linkedin.com/in/srikanth-annabathina-7842a9188/"
  },
  {
    id: 2,
    name: "Shubham Jaiswal",
    designation: "Analytics Analyst, McKinsey",
    img:  shubham,
    linkedin: "https://www.linkedin.com/in/shubham-jaiswal-0aa5a65b/"
  },
  {
    id: 3,
    name: "Abhishek Potnis",
    designation: "Deep Learning Specialist",
    img: abhishek ,
    linkedin: "https://www.linkedin.com/in/abhishekpotnis/"
  }

]
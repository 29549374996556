import logo from "../../assets/main/logo.svg";
import { useState, useEffect } from "react";
import { useHttp } from "../../hooks/useHttp";


const Hire = ({close}) => {
 	const [name, setName] = useState('');
 	const [companyName, setCompanyName] = useState('');
    const [workEmail, setWorkEmail] = useState('');
    const [phone, setPhone] = useState('');
	const { response, apiCall } = useHttp();

    const onSubmit = async (e) => {
      e.preventDefault()

	    const companyData = {
	        name: name,
	        company_name: companyName,
	        work_email: workEmail,
	        phone: phone
	    };

	    apiCall({
		  path: "/enroll/company",
		  method: "POST",
		  data: companyData
		});
    }

    useEffect(()=>{
    	if(response){
	        console.log("Your data has been successfully recorded, we'll come back to you soon.", response);
	        close();
    	}
	}, [close, response])

	return(
		<>
			<div className="flex flex-row justify-center pb-2">
				<img src={logo} alt="Surge Classes" className="w-[70%]" />
			</div>
			<div className="font-inter text-2xl text-[#101828] font-semibold text-center py-2">
				Hire With Us
			</div>
			<div className="font-inter text-sm  text-center text-[#667085]">
				Please enter your details.
			</div>
			<form className="flex flex-col py-4">
				<label htmlFor="name" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Name
				</label>
			    <input className="hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4" 
			    	placeholder="Enter your full name" 
			    	type="text" 
			    	required 
			    	name="name"
			    	onChange={(e) => setName(e.target.value)}  
			    />
			    <label htmlFor="company_name" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Company Name
				</label>
			    <input className="hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4" 
			    	placeholder="Enter the company name" 
			    	type="text" 
			    	required 
			    	name="company_name"
			    	onChange={(e) => setCompanyName(e.target.value)}  
			    />								
			    <label htmlFor="phone" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Phone
				</label>
			    <input className="hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4" 
			    	placeholder="Enter your phone no." 
			    	type="tel" 
			    	required 
			    	name="phone"
			    	onChange={(e) => setPhone(e.target.value)}  
			    />				
				<label htmlFor="work_email" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
					Email
				</label>
			    <input className="hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4" 
			    	type="email"
                    label="Email address"
                    onChange={(e) => setWorkEmail(e.target.value)}  
                    required                                    
			    	placeholder="Enter your work email"			    
			    />
			</form>
			<div className="flex flex-col w-full pb-4">
            <button className="my-2 bg-primary text-white rounded-md py-2 font-bold cursor-pointer"
            	type="submit" 
                onClick={onSubmit}
            > Enroll</button>
            </div>
		</>
	);
}

export default Hire;

import { useHttp } from "../../../hooks/useHttp";
import toastMixin from "../../../utils/toastMixin";
import { useEffect, useState } from "react";

const EventCard = ({ eventCardDetail, isLogin, enrolled = false }) => {

  const { isLoading, error, response, apiCall } = useHttp();

  const [enroll, setEnroll] = useState(false);

  useEffect(() => {
    if (enrolled) {
      setEnroll(true);
    }
  }, [enrolled])

  const handleEventEnroll = () => {
    if (isLogin) {
      apiCall({
        path: "/enroll/event",
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
        },
        data: {
          event_id: eventCardDetail.id,
        },
      });
      setEnroll(true);
    }
    else {
      toastMixin({}).fire({
        icon: "info",
        title: "Sign up first to enroll for the event",
      });
    }
  };

  return (
    <div
      key={eventCardDetail.id}
      className="flex flex-col border-2 shadow-md rounded-[5%] w-[310px] mx-3 relative" // Added 'relative' class
    >
      <div className="flex">
        <div className="bg-primary text-white w-fit px-4 z-1 absolute top-0 left-0 rounded-tl-lg"> {/* Added 'top-0 left-0' classes */}
          {eventCardDetail.tags}
        </div>
        <div className="mt-0 h-[200px] w-full">
        <img
          src={eventCardDetail.img}
          alt="xyz"
            className="h-full w-full object-cover"
        />
      </div>
      </div>
      <div className="p-5 flex flex-col justify-end">
        <div className="font-inter text-xl font-bold text-primary">
          {eventCardDetail.name}
        </div>
        <div className="font-inter text-base text-[#606060] py-2">
          {eventCardDetail.participants} participants
        </div>
        <div className="flex flex-row justify-start">
          <button disabled={enroll} className={"rounded-md text-xs p-2 px-4 w-[40%] text-white bg-secondary font-bold hover:bg-[#AB1200]" + (enroll ? " cursor-not-allowed" : "")} onClick={handleEventEnroll}>
            {enroll ? "Enrolled" : "Enroll"}
            {enroll && <span className="ml-2">✓</span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventCard;

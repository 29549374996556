import Button from "../../components/ui/Button";
import hirewithusimage from "../../assets/main/hirewithus.png";
import AboutCard from "../../components/ui/cards/AboutCard";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import * as data from "../../data/AboutUs";
import SignUp from "../../components/auth/SignUp";

const AboutUs = ({ isLogin, setIsLogin }) => {
  const navigate = useNavigate();
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);

  const toggleSignUpPopup = () => {
    setShowSignUpPopup(!showSignUpPopup);
  };

  const gotoCoursesHandler = () => {
    localStorage.setItem('scrollToCourses', true);
    navigate("/");
  }


  return (
    <div className="home-page bg-white">
      <div className="flex flex-row justify-center items-center">
        <div className="flex flex-col justify-center py-5 px-5 xl:px-5 xl:max-w-[1640px]">
          <h1 className="font-inter py-5 xl:text-5xl md:text-3xl text-2xl font-bold text-primary text-center">
            About the Company
          </h1>
        <div className="flex flex-row justify-center">
            <div className="font-inter text-sm md:text-base text-[#667085] text-center w-[98%] md:w-[65%]">
            <ul className="marker:text-primary list-disc list-inside">
              <li>Surge Classes believes in individual transformation and working towards a vision of creating a next generation quality talent pool in technologies like data science, machine learning, AI and software engineering.</li>
              <li>We offer an intensive six-month Master Programmes through live classes delivered by tech leaders and subject matter experts.</li>
              <li>The meticulously structured program which offers a modern curriculum enhances the skills of our learners with exposure to the latest technologies and industry relevant projects.</li>
            </ul>
          </div>
        </div>
      </div>
      </div>

      <div className="bg-[#F9FAFB] flex flex-row justify-center items-center mx-3 md:mx-0">
        <div className="flex flex-row justify-center lg:justify-start py-5 xl:px-5 xl:max-w-[1640px] ">
          <div className="hidden lg:flex flex-col justify-center lg:p-5 lg:pl-10 lg:w-[50%]">
            <div>
              <div className="flex felx-col justify-center px-10">
                <div className="w-full h-full">
                  <img src={hirewithusimage} alt="hire-with-us-vector" className="w-full h-full object-cover" />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:p-5 lg:pr-10 lg:w-[50%] w-full ">
            <div className="flex flex-col justify-between">
              <div className="hidden lg:flex lg:flex-col justify-center">
                <div className="font-inter pt-5 text-sm xl:text-xl font-bold text-primary">
                  We’ve helped hundreds of Students
                </div>
                <div className="font-inter py-5 text-3xl xl:text-4xl font-bold text-[#101828]">
                  We’re only just getting started on our journey
                </div>
              </div>
              <div className="flex flex-col justify-center  lg:my-4">
                <div className="grid grid-rows-2 grid-flow-col ">
                  <div className="flex flex-col justify-center my-4  w-[150px] md:w-[200px]">
                    <div className="font-inter pt-5 pb-4 text-3xl md:text-6xl font-bold text-primary">
                      100 +
                    </div>
                    <div className="text-xs md:text-base">  Placements completed</div>
                  </div>
                  <div className="flex flex-col justify-center my-4 w-[150px] md:w-[200px]">
                    <div className="font-inter pt-5 pb-4 text-3xl md:text-6xl font-bold text-primary">
                      80 %
                    </div>
                    <div className="text-xs md:text-base"> Placement</div>
                  </div>
                  <div className="flex flex-col justify-center my-4 w-[150px] md:w-[200px]">
                    <div className="font-inter pt-5 pb-4 text-3xl md:text-6xl font-bold text-primary">
                      50 +
                    </div>
                    <div className="text-xs md:text-base"> Professional teachers</div>
                  </div>
                  <div className="flex flex-col justify-center my-4 w-[150px] md:w-[200px]">
                    <div className="font-inter pt-5 pb-4 text-3xl md:text-6xl font-bold text-primary">
                      100 +
                    </div>
                    <div className="text-xs md:text-base"> Success stories</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="flex flex-col justify-center py-5 xl:px-10">
        <div className="font-inter py-5 xl:text-5xl md:text-3xl text-2xl font-bold text-primary text-center">
          Meet Our Team
        </div>
        <div className="flex flex-row justify-center">
          <div className="font-inter text-sm md:text-md text-[#667085] text-center w-[90%] md:w-[50%]">
            Discover the faces behind Surge Classes – a team of diverse expertise and unwavering dedication, guiding your educational journey with passion and innovation.          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center mt-10">
          {data.surgeTeam.map((Details, i) => (
            <AboutCard key={i} Details={Details} />
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-center py-5 xl:px-10 mt-0 md:mt-5">
        <div className="font-inter py-5 xl:text-5xl md:text-3xl text-2xl font-bold text-primary text-center">
          Surge classes instructors
        </div>
        <div className="flex flex-row justify-center">
          <div className="font-inter text-sm md:text-md text-[#667085] text-center w-[90%] md:w-[50%]">
            Immerse yourself in learning from seasoned industry experts in Artificial Intelligence, Machine Learning, Data Science, and more. Benefit from their wealth of experience from esteemed organizations and leading academic institutions, as they guide you through a diverse range of courses including AI, ML, MERN stack, Data Structures, and Algorithms.          </div>
        </div>
        {/* <div className="flex flex-row justify-center mt-10">
          {data.surgeInstructors.map((Details, i) => (
            <AboutCard key={i} Details={Details} />
          ))}
        </div> */}
        <div className="flex flex-wrap justify-center mt-10">
          {data.surgeInstructors.map((Details, i) => (
            <div key={i} className="flex flex-row justify-center w-1/2 lg:w-1/4 p-2">
              <AboutCard Details={Details} />
            </div>
          ))}
        </div>

      </div>

      <div className="hidden lg:flex lg:flex-row justify-center items-center">
        <div className="flex flex-row justify-between py-5 my-10 lg:w-[950px] xl:w-[1240px]">
        <div className="flex flex-col">
          <div className="font-inter text-4xl font-bold text-primary">
            Give a Surge start to your career
          </div>
          <div className="font-inter text-lg py-2 text-[#667085]">
            Join now and learn from experts to enhance your career
          </div>
        </div>
        <div className="flex flex-row py-5">
          {isLogin ? (<></>)
            :
            (<Button active onClick={toggleSignUpPopup} >Register</Button>)
          }
          {showSignUpPopup && (
            <SignUp showSignupPopup={showSignUpPopup} setShowSignupPopup={setShowSignUpPopup} setIsLogin={setIsLogin} />
          )}
          <div onClick={gotoCoursesHandler}><Button outline>View Courses</Button></div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default AboutUs;

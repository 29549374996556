const ProgramTags = ({ tag }) => {
  return (
    <div className="relative my-5">
      <div className="bg-[#A6E1FF] rounded-[5%] flex flex-col justify-center h-14 ml-[27px] pl-[27px] align-center w-[250px]">
        {tag.description}
      </div>
      <div className="bg-primary text-white font-bold rounded-[50%] h-14 w-14 mt-[-54px] flex flex-col justify-center">
        {tag.about}
      </div>
    </div>
  );
};

export default ProgramTags;

const CertificateSvg = () => (
    <svg width="316" height="381" viewBox="0 0 316 381" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M313.74 182.227C312.579 184.168 311.007 185.83 309.134 187.097C307.262 188.364 305.135 189.204 302.903 189.559C300.671 189.914 298.388 189.775 296.215 189.152C294.043 188.528 292.033 187.436 290.328 185.951L242.671 213.306L244.447 184.789L289.431 163.184C292.113 160.483 295.692 158.86 299.49 158.623C303.288 158.387 307.041 159.552 310.037 161.9C313.033 164.247 315.065 167.613 315.748 171.359C316.43 175.106 315.716 178.973 313.74 182.227Z" fill="#FFB8B8" />
        <path d="M175.768 291.811C189.905 311.613 189.399 344.742 185.455 381H118.616L115.71 374.214L111.835 381H48.8711C48.8711 381 53.7144 288.902 62.4326 285.994C71.1507 283.086 175.768 291.811 175.768 291.811Z" fill="#2F2E41" />
        <path d="M118.616 84.9226C99.8914 84.9226 84.7122 69.7313 84.7122 50.9918C84.7122 32.2524 99.8914 17.061 118.616 17.061C137.34 17.061 152.52 32.2524 152.52 50.9918C152.52 69.7313 137.34 84.9226 118.616 84.9226Z" fill="#FFB8B8" />
        <path d="M94.1115 93.7256L87.4815 106.443L41.6236 126.902L53.6778 243.649L58.7513 255.738L56.0101 266.237L62.6188 273.986C55.9851 279.993 55.1425 286.281 58.7513 292.786L79.8688 297.627C79.8688 297.627 197.288 308.077 187.392 289.871C176.58 269.98 173.826 211.234 197.696 179.842C217.96 153.191 202.402 118.608 202.402 118.608L154.334 104.232L136.654 93.7256L94.1115 93.7256Z" fill="#004C73" />
        <path d="M103.564 1.28843C103.405 0.951069 103.148 0.669066 102.828 0.478639C102.507 0.288211 102.137 0.19805 101.765 0.219741C117.286 -0.709466 133.636 1.10108 146.621 9.66144C149.767 11.7353 152.733 14.209 156.157 15.7135C156.318 15.7861 156.458 15.8994 156.563 16.0422C156.668 16.185 156.734 16.3526 156.755 16.5286C156.776 16.7046 156.752 16.8831 156.684 17.0467C156.615 17.2104 156.506 17.3536 156.366 17.4624L155.99 17.7575C155.842 17.874 155.728 18.0289 155.662 18.2054C155.595 18.382 155.578 18.5734 155.613 18.7588C155.648 18.9443 155.732 19.1167 155.858 19.2574C155.984 19.398 156.146 19.5014 156.326 19.5564C156.514 19.6138 156.682 19.7237 156.809 19.8733C156.937 20.0228 157.019 20.2057 157.046 20.4005C157.074 20.5953 157.045 20.7938 156.963 20.9727C156.881 21.1515 156.75 21.3033 156.585 21.41L153.703 23.2738C153.558 23.3661 153.439 23.4933 153.355 23.6438C153.272 23.7944 153.228 23.9634 153.227 24.1355C153.226 24.3075 153.268 24.477 153.35 24.6284C153.432 24.7799 153.55 24.9084 153.694 25.0022C153.938 25.1531 154.194 25.2831 154.46 25.391C154.626 25.4532 154.772 25.557 154.886 25.6924C155 25.8277 155.077 25.9901 155.109 26.1639C155.142 26.3377 155.13 26.517 155.073 26.6845C155.016 26.8521 154.918 27.0022 154.786 27.1204C153.538 28.2321 152.581 29.6324 151.998 31.1992C151.038 33.6984 150.865 36.4202 150.703 39.0929C149.711 35.2365 145.505 32.9665 141.533 32.7358C137.56 32.5051 133.686 33.8169 129.82 34.7598C121.4 36.8077 112.655 37.1443 104.102 35.75C107.006 41.5828 104.557 48.5402 102.091 54.5721L94.1115 51.149C83.1522 44.9578 90.7861 71.1478 94.8833 74.7432C86.5175 77.6459 64.6213 45.4296 71.6653 33.2092C75.3295 26.8523 72.7284 18.6534 79.9323 12.6244C83.463 8.90466 90.5122 10.558 94.1115 7.4664C95.5282 6.24962 93.3689 2.99429 95.3158 2.28485C95.9624 2.04921 103.457 1.05094 103.564 1.28843Z" fill="#2F2E41" />
        <path d="M160.992 107.516C167.213 135.158 183.17 199.698 199.358 213.195C200.385 214.165 201.616 214.893 202.96 215.324C259.863 229.197 278.095 193.809 278.095 193.809C278.095 193.809 262.846 186.372 262.46 171.835L231.707 181.384L204.9 121.008L200.955 117.325L200.933 117.303L160.992 107.516Z" fill="#004C73" />
        <path d="M39.667 362.774C38.0376 361.206 36.7715 359.3 35.9578 357.19C35.1441 355.08 34.8026 352.816 34.9574 350.56C35.1122 348.303 35.7594 346.108 36.8536 344.129C37.9478 342.15 39.4623 340.435 41.2905 339.105L25.3386 286.49L52.7089 294.616L63.6608 343.334C65.6899 346.556 66.4676 350.41 65.8466 354.168C65.2256 357.925 63.249 361.323 60.2915 363.72C57.3339 366.116 53.6009 367.343 49.7996 367.169C45.9984 366.994 42.3931 365.431 39.667 362.774Z" fill="#FFB8B8" />
        <path d="M54.267 123.959L41.6237 126.902C41.6237 126.902 -10.6344 228.66 1.95835 248.049C14.5512 267.438 29.9784 331.63 29.9784 331.63C29.9784 331.63 52.7957 327.09 61.5138 315.457L45.5489 239.324L62.0164 175.34L54.267 123.959Z" fill="#004C73" />
        <path d="M294.131 275.065L293.677 111.179C293.664 107.862 292.337 104.685 289.987 102.346C287.637 100.007 284.456 98.6967 281.141 98.7022L157.469 99.0453C154.155 99.0583 150.981 100.386 148.644 102.738C146.307 105.09 144.997 108.274 145.003 111.591L145.457 275.478C145.47 278.795 146.797 281.971 149.147 284.31C151.497 286.649 154.678 287.96 157.993 287.955L281.665 287.611C284.979 287.598 288.153 286.27 290.49 283.918C292.827 281.566 294.137 278.382 294.131 275.065Z" fill="#E6E6E6" />
        <path d="M284.508 225.591L284.216 120.23C284.204 117.124 282.961 114.149 280.76 111.958C278.559 109.768 275.58 108.54 272.476 108.545L166.19 108.84C163.086 108.852 160.113 110.096 157.924 112.299C155.735 114.502 154.509 117.483 154.514 120.59L154.918 266.427C154.93 269.533 156.173 272.508 158.374 274.699C160.575 276.889 163.555 278.117 166.659 278.111L232.501 277.929C246.327 277.875 259.568 272.334 269.318 262.522C279.068 252.711 284.53 239.429 284.508 225.591Z" fill="white" />
        <path d="M178.368 154.826H261.244C262.563 154.826 263.828 154.302 264.76 153.368C265.693 152.435 266.217 151.169 266.217 149.85C266.217 148.53 265.693 147.264 264.76 146.331C263.828 145.397 262.563 144.873 261.244 144.873H178.368C177.05 144.873 175.785 145.397 174.852 146.331C173.92 147.264 173.396 148.53 173.396 149.85C173.396 151.169 173.92 152.435 174.852 153.368C175.785 154.302 177.05 154.826 178.368 154.826Z" fill="#FA1B00" />
        <path d="M243.011 131.879H261.244C262.563 131.879 263.828 131.354 264.76 130.421C265.693 129.488 266.217 128.222 266.217 126.902C266.217 125.582 265.693 124.317 264.76 123.383C263.828 122.45 262.563 121.926 261.244 121.926H243.011C241.693 121.926 240.428 122.45 239.495 123.383C238.563 124.317 238.039 125.582 238.039 126.902C238.039 128.222 238.563 129.488 239.495 130.421C240.428 131.354 241.693 131.879 243.011 131.879Z" fill="#FA1B00" />
        <path d="M178.368 177.773H261.244C262.563 177.773 263.828 177.249 264.76 176.316C265.693 175.382 266.217 174.117 266.217 172.797C266.217 171.477 265.693 170.211 264.76 169.278C263.828 168.345 262.563 167.82 261.244 167.82H178.368C177.05 167.82 175.785 168.345 174.852 169.278C173.92 170.211 173.396 171.477 173.396 172.797C173.396 174.117 173.92 175.382 174.852 176.316C175.785 177.249 177.05 177.773 178.368 177.773Z" fill="#FA1B00" />
        <path d="M178.368 200.721H261.244C262.563 200.721 263.828 200.196 264.76 199.263C265.693 198.33 266.217 197.064 266.217 195.744C266.217 194.424 265.693 193.158 264.76 192.225C263.828 191.292 262.563 190.768 261.244 190.768H178.368C177.05 190.768 175.785 191.292 174.852 192.225C173.92 193.158 173.396 194.424 173.396 195.744C173.396 197.064 173.92 198.33 174.852 199.263C175.785 200.196 177.05 200.721 178.368 200.721Z" fill="#FA1B00" />
        <path d="M185.074 261.186C196.002 261.186 204.86 252.32 204.86 241.384C204.86 230.448 196.002 221.583 185.074 221.583C174.147 221.583 165.289 230.448 165.289 241.384C165.289 252.32 174.147 261.186 185.074 261.186Z" fill="#FA1B00" />
        <path d="M182.375 251.69C182.033 251.69 181.696 251.61 181.391 251.457C181.085 251.304 180.819 251.082 180.614 250.809L175.217 243.606C175.043 243.374 174.916 243.111 174.844 242.831C174.772 242.55 174.756 242.258 174.797 241.972C174.838 241.685 174.934 241.409 175.082 241.16C175.229 240.911 175.424 240.694 175.655 240.52C175.887 240.346 176.15 240.22 176.43 240.148C176.71 240.076 177.002 240.061 177.288 240.102C177.575 240.143 177.85 240.24 178.099 240.388C178.348 240.535 178.565 240.731 178.738 240.963L182.27 245.675L191.34 232.059C191.5 231.817 191.705 231.609 191.946 231.448C192.186 231.286 192.456 231.173 192.74 231.116C193.024 231.058 193.316 231.058 193.6 231.114C193.884 231.17 194.155 231.281 194.396 231.442C194.637 231.603 194.843 231.81 195.004 232.051C195.165 232.292 195.277 232.562 195.334 232.846C195.39 233.131 195.39 233.423 195.333 233.707C195.276 233.992 195.164 234.262 195.002 234.503L184.206 250.709C184.011 251.002 183.749 251.244 183.441 251.415C183.133 251.585 182.788 251.679 182.436 251.689C182.416 251.69 182.395 251.69 182.375 251.69Z" fill="white" />
        <path d="M227.265 60.5491C222.078 60.5491 217.872 56.3406 217.872 51.1491C217.872 45.9576 222.078 41.749 227.265 41.749C232.452 41.749 236.658 45.9576 236.658 51.1491C236.658 56.3406 232.452 60.5491 227.265 60.5491Z" fill="#F2F2F2" />
        <path d="M261.52 330.939C256.333 330.939 252.128 326.731 252.128 321.539C252.128 316.348 256.333 312.139 261.52 312.139C266.708 312.139 270.913 316.348 270.913 321.539C270.913 326.731 266.708 330.939 261.52 330.939Z" fill="#F2F2F2" />
        <path d="M45.4912 97.0433C40.3038 97.0433 36.0987 92.8347 36.0987 87.6432C36.0987 82.4517 40.3038 78.2432 45.4912 78.2432C50.6786 78.2432 54.8838 82.4517 54.8838 87.6432C54.8838 92.8347 50.6786 97.0433 45.4912 97.0433Z" fill="#F2F2F2" />
    </svg>
);

export default CertificateSvg;
import { useState } from "react";
import UserDetailsForPayment from "./UserDetailsForPayment";
import PaymentInfoForm from "./PaymentInfoForm";

const PaymentForm = ({ formData, setFormData, handlePayment, isLogin, close, referral_code, payment_page_info }) => {

    const [selectedTab, setSelectedTab] = useState(isLogin ? "payment-info" : "user-details");

    // const [formValidity, setFormValidity] = useState({
    //     name: false,
    //     mobile: false,
    //     email: false,
    // });


    // const checkValidity = (name, value) => {
    //     switch (name) {
    //         case "name":
    //             return validate(value, [VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(50)]);
    //         case "mobile":
    //             return validate(value, [VALIDATOR_REQUIRE(), VALIDATOR_MOBILE()]);
    //         case "email":
    //             return validate(value, [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]);
    //         default:
    //             return false;
    //     }
    // };

    // const onInputChange = (name, value) => {
    //     const isValid = checkValidity(name, value);
    //     setFormValidity((prevValidity) => ({
    //         ...prevValidity,
    //         [name]: isValid,
    //     }));
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    // };



    // const onSubmit = (e) => {
    //     e.preventDefault();

    //     if (formValidity.name && formValidity.mobile && formValidity.email) {
    //         handlePayment(isLogin);
    //         close();
    //     }
    // };

    return (
        <>
            {selectedTab === "user-details" ? (
                <UserDetailsForPayment
                    formData={formData}
                    setFormData={setFormData}
                    handlePayment={handlePayment}
                    isLogin={isLogin}
                    close={close}
                    setSelectedTab={setSelectedTab}
                />
            ) : (
                <PaymentInfoForm
                    formData={formData}
                    setFormData={setFormData}
                    handlePayment={handlePayment}
                    isLogin={isLogin}
                    close={close}
                    referral_code={referral_code}
                    payment_page_info={payment_page_info}
                />
            )}
        </>
        // <>
        //     <div className="flex flex-row justify-center pb-2">
        //         <img src={logo} alt="Surge Classes" className="w-[70%]" />
        //     </div>
        //     <div className="font-inter text-2xl text-[#101828] font-semibold text-center py-2">
        //         Enroll
        //     </div>
        //     <div className="font-inter text-sm text-center text-[#667085]">
        //         Please enter your details.
        //     </div>
        //     <form className="flex flex-col py-4" onSubmit={onSubmit}>
        //         <label
        //             htmlFor="name"
        //             className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]"
        //         >
        //             Name
        //         </label>
        //         <input
        //             className={`hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4 ${formValidity.name ? "" : "border-red-500"
        //                 }`}
        //             placeholder="Enter your full name"
        //             type="text"
        //             required
        //             name="name"
        //             onChange={(e) => onInputChange("name", e.target.value)}
        //         />

        //         <label
        //             htmlFor="mobile"
        //             className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]"
        //         >
        //             Mobile
        //         </label>
        //         <input
        //             className={`hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4 ${formValidity.mobile ? "" : "border-red-500"
        //                 }`}
        //             placeholder="Format: +919876543210"
        //             type="text"
        //             required
        //             name="mobile"
        //             onChange={(e) => onInputChange("mobile", e.target.value)}
        //         />

        //         <label
        //             htmlFor="email"
        //             className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]"
        //         >
        //             Email
        //         </label>
        //         <input
        //             className={`hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4 ${formValidity.email ? "" : "border-red-500"
        //                 }`}
        //             placeholder="Enter your email"
        //             type="email"
        //             required
        //             name="email"
        //             onChange={(e) => onInputChange("email", e.target.value)}
        //         />
        //         {!referral_code && (
        //             <>
        //                 <label htmlFor="referral-code" className="flex flex-row justify-start font-inter text-base py-2 text-[#344054]">
        //                     Referral Code (Optional)
        //                 </label>
        //                 <input className="hover:border-gray-400 placeholder:text-slate-400 border h-7 border-[#D0D5DD] rounded-sm px-2 py-4"
        //                     placeholder="Enter your referral code"
        //                     type="text"
        //                     name="referral-code"
        //                     onChange={(e) => setFormData({ ...formData, referralCode: e.target.value })}
        //                 />
        //             </>
        //         )}

        //         <div className="flex flex-row justify-center font-inter text-base py-2 text-[#344054]">
        //             <button
        //                 className={`bg-[#F05454] text-white font-inter text-base font-semibold py-2 rounded-md w-[50%] mt-4 ${!formValidity.name || !formValidity.mobile || !formValidity.email
        //                     ? "opacity-50 cursor-not-allowed"
        //                     : "hover:bg-[#F05454]"
        //                     }`}
        //                 type="submit"
        //                 disabled={!formValidity.name || !formValidity.mobile || !formValidity.email}
        //             >
        //                 Enroll
        //             </button>
        //         </div>
        //     </form>
        // </>
    );
}
export default PaymentForm;

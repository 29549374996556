import { useEffect, useState } from "react";
import { useHttp } from "../../hooks/useHttp";
import Button from "../ui/Button";
import PaymentForm from "./PaymentForm";
import Popup2 from "../ui/popup/Popup2";
import Loader from "../ui/Loader";

const RazorpayPaymentModule = ({ isLogin, setIsLogin, course_id, isPaidEnrolled, setReloadCourseState, referral_code = "", isExtra = true, payment_page_info, toggleSignUpPopup, syllabus_download_link }) => {
    const { apiCall, response, isLoading } = useHttp();
    const { apiCall: apiCall2, response: response2, isLoading: isLoading2 } = useHttp();
    const { apiCall: apiCall3, response: response3, isLoading: isLoading3 } = useHttp();
    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        email: "",
        referralCode: "",
    });

    const [showPopup, setShowPopup] = useState(false);

    const handleTogglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handlePayment = async (isLogin) => {
        try {
            const userToken = (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).token) || localStorage.getItem("token")
            if (userToken && isLogin) {
                apiCall({
                    path: '/razorpay/create_order/logined',
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                    data: {
                        course_id: course_id,
                        referral_code: formData.referralCode ? formData.referralCode : referral_code,
                    },
                });
            }
            else {
                apiCall({
                    path: '/razorpay/create_order',
                    method: 'POST',
                    data: {
                        name: formData.name,
                        mobile: formData.mobile,
                        email: formData.email,
                        course_id: course_id,
                        referral_code: formData.referralCode ? formData.referralCode : referral_code,
                    },
                });
            }
        } catch (error) {
            console.error('Error making payment:', error);
        }
    };

    const goToSkillbox = async (isLogin) => {
        try {
            if (isLogin) {
                await apiCall3({
                    path: '/generate_custom_token',
                    method: 'GET',
                    body: null,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
            }
        }
        catch (error) {
            console.error('Error generating custom token:', error);
        }
    };


    
    useEffect(() => {
        const handleCallback = async (response) => {
            try {
                apiCall2({
                    path: '/payment/razorpay/callback',
                    method: 'POST',
                    data: {
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_signature: response.razorpay_signature,
                        course_id: course_id,
                    },
                });
                
            } catch (error) {
                console.error('Error making payment:', error);
            }
        };

        if (isLogin && response) {
            const orderId = response.order.id;
            const options = {
                key: 'rzp_live_a8JOxZG6OrOLZG',
                amount: response.order.amount,
                currency: 'INR',
                name: 'Surge Classes',
                description: 'Course Payment',
                order_id: orderId,
                handler: handleCallback,
                prefill: {
                    name: JSON.parse(localStorage.getItem("user")).name,
                    email: JSON.parse(localStorage.getItem("user")).email,
                    contact: JSON.parse(localStorage.getItem("user")).mobile,
                },
                modal: {
                    ondismiss: function () {
                        setReloadCourseState(true);
                    },
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        else if (!isLogin && response) {
            const orderId = response.order.id;
            localStorage.setItem("token", response.login_data.firebase_id_token)
            localStorage.setItem("user", JSON.stringify(response.login_data.data))
            const options = {
                key: 'rzp_live_a8JOxZG6OrOLZG',
                amount: response.order.amount,
                currency: 'INR',
                name: 'Surge Classes',
                description: 'Course Payment',
                order_id: orderId,
                handler: handleCallback,
                prefill: {
                    name: formData.name,
                    email: formData.email,
                    contact: formData.mobile,
                },
                modal: {
                    ondismiss: function () {
                        setReloadCourseState(true);
                    },
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
    }, [apiCall2, course_id, formData, isLogin, response, setReloadCourseState]);

    useEffect(() => {
        if (response2) {
            if(!isLogin){
            setIsLogin(true);
            }
            setReloadCourseState(true);
        }
    }
    , [isLogin, response2, setIsLogin, setReloadCourseState]);

    useEffect(() => {
        if (response3 && response3.token) {
            const customToken = response3.token;
            // window.open = `${process.env.REACT_APP_SKILLBOX_URL}?token=${customToken}`;
            // window.open(`http://localhost:3001?token=${customToken}`, '_blank');
            window.open(`${process.env.REACT_APP_SKILLBOX_URL}?token=${customToken}`, '_blank');
        }
    }
        , [response3]);

    return (
        <div>
            {isPaidEnrolled ? (
                <div className="flex flex-col gap-2 md:flex-row md:gap-5">
                    {isLoading3 ?
                        <Loader />
                        : (
                            <Button
                                active
                                onClick={() => { goToSkillbox(isLogin) }}
                            >Go to Course</Button>
                        )}
                    {/* {isExtra && (
                    <Button
                        width="w-btn2"
                    >Enrollment Status
                    </Button>
                    )} */}
                </div>
            ) : (
                <>
                    {isLoading || isLoading2 ? <Loader /> : (
                        <div className="flex flex-row items-center py-2 md:py-5">
                            {/* {isLogin ? (
                                <Button
                                    active
                                    onClick={() => { handlePayment(true) }}
                                >Buy Course</Button>) : ( */}
                                <Button
                                    active
                                    onClick={handleTogglePopup}
                                >Buy Course</Button>
                            {/* )
                            } */}
                            {isExtra && (
                                <>
                                    {isLogin && syllabus_download_link ? (
                                        <a
                                            href={syllabus_download_link}
                                            download
                                            className="my-3 lg:my-0"
                                        >
                                            <Button outline width="w-40">
                                            Download Syllabus
                                            </Button>
                                        </a>
                                    ) : (
                                        <Button
                                            outline
                                            width="w-40"
                                            onClick={toggleSignUpPopup}
                                        >
                                            Download Syllabus
                                        </Button>
                                    )}
                                    <div className="hidden lg:flex flex-col justify-center">
                                        <div className="font-inter text-xs mb-3 md:mb-0 lg:text-base text-black font-bold">
                                            Start learning at your own pace
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {showPopup && (
                        <Popup2
                            close={handleTogglePopup}
                        >
                            <PaymentForm
                                formData={formData}
                                setFormData={setFormData}
                                handlePayment={handlePayment}
                                isLogin={isLogin}
                                close={handleTogglePopup}
                                referral_code={referral_code}
                                payment_page_info={payment_page_info}
                            />
                        </Popup2>
                    )
                    }
                </>
            )}

        </div>
    );
};

export default RazorpayPaymentModule;

import linkedin from "../../../assets/companylogos/linkedin.svg";

const AboutCard = ({ Details }) => {
  return (
      <div
        key={Details.id}
      className="flex flex-col w-[270px] py-5 md:py-0"
      >
        <div className="flex flex-col">
        <div className="flex flex-row justify-center">
          <img src={Details.img} alt="xyz" className="w-[8rem] rounded-full" />
        </div>
        <div className="flex flex-row justify-center z-1 mt-[-40px] mr-[-100px]">
          <button className="rounded-[50%] p-2 border-2 bg-white">
            <a
              href={Details.linkedin}
              rel="noreferrer"
              target="_blank"
            >
              <img src={linkedin} alt="linkedin" className="h-5" />
            </a>
          </button>
        </div>
        <div className="px-2 sm:px-5 flex flex-col justify-start mt-2">
          <div className="font-inter md:text-xl text-lg font-bold text-primary text-center">
            {Details.name}
          </div>
          <div className="font-inter md:text-sm text-xs text-black text-center">
            {Details.designation}
          </div>
        </div>

        </div>
    </div>
  );
};

export default AboutCard;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as data from "../../../data/SurgeCourses";

const ExploreMobile = ({ onClose }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedData, setSelectedData] = useState([]);
    const navigate = useNavigate();


    const options = [
        { value: data.SurgeProgramsAll.selfpacedprogram, label: 'Self Paced Program', id: 2 },
        { value: data.SurgeProgramsAll.campusprogram, label: 'Campus Program', id: 0 },
        { value: data.SurgeProgramsAll.proprogram, label: 'Pro Program', id: 1 },
        { value: data.SurgeProgramsAll.livebatches, label: 'Live Batches', id: 3 }
    ];

    const handleOptionSelect = (option) => {
        if (selectedOption === option.id) {
            setSelectedOption(null);
            setSelectedData([]);
        } else {
            setSelectedOption(option.id);
            setSelectedData(option.value);
        }
    };
    const handleClose = () => {
        onClose();
    };

    const handleSubtopicSelect = (subtopic) => {
        const coursePath = subtopic.code;
        navigate(`/course/${coursePath}`);
        handleClose();
    };

    return (
        <div className="mobile-sidebar">
            <div className="font-inter text-base font-bold text-primary pb-3">Explore</div>
            <div className="flex flex-col justify-start space-y-2">
                <ul className="flex flex-col space-y-1.5">
                    {options.map((option, i) => (
                        <li
                            key={i}
                            onClick={() => handleOptionSelect(option)}
                            className={`relative inline-block hover:cursor-pointer`}
                        >
                            <span className={`group relative text-primary ${option.id === selectedOption ? 'brightness-125' : ''}`}>
                                <span className='hover:py-10'>
                                    {option.label}
                                </span>
                                <span className={`${option.id === selectedOption ? '' : 'group-hover:scale-x-100 h-[1px]'}  absolute inset-x-0 bottom-0 bg-primary transform scale-x-0 origin-left  transition-transform`}></span>
                            </span>
                            {option.id === selectedOption && (
                                <ul className="flex flex-col space-y-1.5 py-2">
                                    {selectedData.map((subtopic, j) => (
                                        <li
                                            key={j}
                                            className={`relative inline-block 
                                                bg-gray-200 rounded-md px-2 py-2 hover:bg-primary hover:text-white
                                                hover:cursor-pointer`}
                                            onClick={() => handleSubtopicSelect(subtopic)}
                                        >
                                            <div className="text-[10px]">{subtopic.title}</div>
                                        </li>
                                    ))}
                                </ul>
                            )}

                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ExploreMobile;

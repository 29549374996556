const HiringProcessTag = ({ step }) => {
  return (
    <div className="flex flex-row justify-between text-start border-2 shadow-md rounded-md px-3 md:px-5 py-3 md:py-5 w-[97%] lg:w-11/12">
      <div className="flex flex-row justify-start">
        <div className="hidden md:block md:border-2 md:rounded-full md:px-5 md:py-3 md:m-5 md:font-inter md:text-md md:font-bold md:text-primary">
          {step.id}
        </div>

        <div className="flex flex-col justify-center md:pl-3">
          <div className="font-inter text-xs md:text-md font-bold text-primary">
            {step.title}
          </div>
          <div className="font-inter text-xs md:text-sm text-[#6F6F6F]">
            {step.description}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center md:ml-3">
        <div className="w-6 h-6 md:w-14 md:h-14">
          <img src={step.icon} alt={step.id} className="w-full h-full object-contain" />
        </div>
      </div>
    </div>
  );
};

export default HiringProcessTag;
